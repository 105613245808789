import { cloneDeep } from '@/utils/helpers/methods';
// I think this is a dead module

const defaultPNR = {
  _embedded: {
    bookings: [],
  },
};

const state = {
  pnr: cloneDeep(defaultPNR),
};

const mutations = {
  SET_PNR(state, payload) {
    state.pnr = payload;
  },
};

const getters = {
  pnr: (state) => state.pnr, // used?
};

const actions = {
  async doGetPnrForBookings({ commit }, payload) { 
    // used?
    const { data } = await this._vm.$api.bookings.getPnr(payload)
    commit('SET_PNR', data.pnrs[0]);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
