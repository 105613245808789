import Vue from 'vue';
import LogRocket from 'logrocket';

export default () => {
  if (!Vue.prototype.$isProd && !Vue.prototype.$isStaging) return;
  Vue.prototype.$logRocket = LogRocket;
  LogRocket.init(process.env.VUE_APP_LOGROCKET_ID, {
    network: {
      requestSanitizer: request => {
        // To do: if we need to add more urls make this a bit more scalable
        if (request.url.toLowerCase().indexOf('/tokens') !== -1) {
          // ignore the request response pair
          request.body = null;
        }
        
        // otherwise log the request normally
        return request;
      },
    }
  });
};
