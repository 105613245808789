import { apiClient } from '../apiClient';

const DOMAIN = 'source';

export default {
  createBranch: ({ id, ...payload }) => apiClient.post(`/${DOMAIN}_connections/${id}/branches`, payload),
  updateBranch: ({ id, ...payload }) => apiClient.patch(`/${DOMAIN}_branches/${id}`, payload),
  getBranchWhiteList: (params) => apiClient.get(`/${DOMAIN}_branches/_whitelist`, { params }),
  getPublicBranches: (params) => apiClient.get(`/${DOMAIN}_branches_public`, { params }),

  createConnection: ({ id, ...payload }) => apiClient.post(`/companies/${id}/${DOMAIN}_connections`, payload),
  updateConnection: ({ id, ...payload }) => apiClient.patch(`/${DOMAIN}_connections/${id}`, payload),
  getConnection: ({ id, ...payload }) => apiClient.get(`/${DOMAIN}_connections/${id}`, payload),
  getConnections: ({ id, ...payload }) => apiClient.get(`/companies/${id}/${DOMAIN}_connections`, payload),
  deleteConnection: ({ id, ...payload }) => apiClient.delete(`/${DOMAIN}_connections/${id}`, payload),

  createIdentity: ({ id, ...payload }) => apiClient.post(`/employees/${id}/${DOMAIN}_identities`, payload),
  getIdentity: ({ id, ...payload }) => apiClient.get(`/${DOMAIN}_identities/${id}`, payload),
  updateIdentity: ({ id, ...payload }) => apiClient.patch(`/${DOMAIN}_identities/${id}`, payload),
  deleteIdentity: ({ id, ...payload }) => apiClient.delete(`/${DOMAIN}_identities/${id}`, payload),
};
