import Vue from 'vue';
import { dayjs } from '@/plugins/dayjs';
import { dateFormatYMD } from '@/utils/helpers/dates';
import { cloneDeep } from '@/utils/helpers/methods';
import { HTTP, SEARCH } from '@/utils/http';

const defaultAggs = {
  all: {
    value: 0,
  },
  incoming: {
    amount: {
      value: 0,
    },
    doc_count: 0,
  },
  outgoing: {
    amount: {
      value: 0,
    },
    doc_count: 0,
  },
  pending: {
    amount: {
      value: 0,
    },
    doc_count: 0,
  },
  received: {
    amount: {
      value: 0,
    },
  },
  sent: {
    amount: {
      value: 0,
    },
    doc_count: 0,
  },
};

const defaultSettings = {
  offset: {
    card: 0,
    input: 0,
  },
};

const defaultStatement = {
  bill_from: {},
  bill_to: {},
  commission_split: 0,
  _embedded: {
    adjustments: [],
    navigation: {
      next: {
        agency: null,
      },
      prev: {
        agency: null,
      },
    },
  },
};

const defaultFilters = {
  clients: [],
  contractors: [],
  dates: {
    from: dayjs().startOf('year').toDate(),
    month: 1,
    range: 'year',
    to: dayjs().endOf('year').toDate(),
    quarter: 1,
    year: new Date().getFullYear(),
  },
  employees: [],
  hosts: [],
  iatas: [],
  mode: 'incoming',
  suppliers: [],
  sort: 'date',
  sortDirection: 'desc',
  statuses: ['pending', 'sent', 'received'],
  type: 'agency',
  tags: [],
};

const state = {
  aggs: cloneDeep(defaultAggs),
  editing: cloneDeep(defaultStatement),
  filters: cloneDeep(defaultFilters),
  bulkStatementsProcessing: {
    status: false,
    count: 0,
    actionId: null
  },
  mode: 'contractors',
  pagination: {
    page: 0,
    per: 25,
    total: 0,
  },
  refresh: false,
  selected: [],
  settings: cloneDeep(defaultSettings),
  statement: cloneDeep(defaultStatement),
  statements: [],
  tab: 0,
};

const mutations = {
  RESET_STATEMENTS(state, payload) {
    switch (payload) {
      case 'editing':
        state.editing = cloneDeep(defaultStatement);
        break;
      case 'filters':
        state.filters = {...cloneDeep(defaultFilters), type: state.filters.type };
        break;
      case 'settings':
        state.settings = cloneDeep(defaultSettings);
        break;
      case 'statement':
        state.statement = cloneDeep(defaultStatement);
        break;
      default:
    }
  },
  SET_STATEMENTS_ATOMIC(state, field) {
    const nodes = field.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, field.val);
      } else {
        obj = obj[node];
      }
    });
  },
  SET_STATEMENTS_FILTERS(state, payload) {
    state.filters = payload;
  },
  SET_BULK_STATEMENTS_PROCESSING(state, payload) {
    state.bulkStatementsProcessing = payload;
  },
};

const getters = {
  isAgencyStatement: (state) => {
    return state.statement.type === 'agency';
  },
  isAgentStatement: (state) => {
    return state.statement.type === 'agent';
  },
  isBillTo: (state, getters, rootState, rootGetters) => {
    const billTo = state.statement.bill_to;
    if (billTo.is_managed) {
      return getters.isBillFrom;
    }
    return billTo.id === rootGetters['companies/company'].id;
  },
  isBillFrom(state, getters, rootState, rootGetters) {
    if (getters.isAgencyStatement) {
      return state.statement.bill_from.id === rootGetters['companies/company'].id;
    }
    if (getters.isAgentStatement) {
      return state.statement.bill_from.company_id === rootGetters['companies/company'].id;
    }
    return false;
  },
  bulkStatementsProcessingInfo: (state) => state.bulkStatementsProcessing,
  isStatementInvitePayable: (state) => {
    const { invite } = state.statement._embedded;
    if (invite) {
      return invite.ic.make_payments;
    }
    return false;
  },
  isStatementEditable: (state, getters, rootState, rootGetters) => {
    if (getters.isAgencyStatement) {
      return rootGetters['employees/currentEmployee'].access.ic.bookings.pay;
    }
    if (getters.isAgentStatement) {
      return rootGetters['employees/currentEmployee'].access.company.bookings.pay;
    }
    return false;
  },
  statement: (state) => state.statement,
  statementEditing: (state) => state.editing,
  statementSettings: (state) => state.settings,
  statements: (state) => state.statements,
  statementsAggregations: (state) => state.aggs,
  statementsFilters: (state) => state.filters,
  statementsMode: (state) => state.mode,
  statementsPagination: (state) => state.pagination,
  statementsRefresh: (state) => state.refresh,
  statementsTab: (state) => state.tab,
  statementsEsQuery(state, _getter, _rootState, rootGetters) {
    const { filters, pagination } = state;
    const company = rootGetters['companies/company'];
    const { id } = company;
    const from = pagination.per * (pagination.page - 1);

    const fromDate = dayjs(filters.dates.from, dateFormatYMD).format(dateFormatYMD);
    const toDate = dayjs(filters.dates.to, dateFormatYMD).format(dateFormatYMD);

    const sort = [
      { date: 'desc' },
      { 'bill_from.name.raw': 'asc' },
      { 'bill_to.name.raw': 'asc' },
      { payment_count: 'desc' },
      '_score',
    ];
    const userSort = {};
    const sortIndex = sort.findIndex((sortItem) => sortItem === filters.sort);
    if (sortIndex >= 0) {
      sort.splice(sortIndex, 1);
    }
    userSort[filters.sort] = filters.sortDirection;
    sort.unshift(userSort);

    const queries = {
      pending: {
        bool: {
          should: [
            {
              bool: {
                must: [{ match: { 'bill_to.id': id } }, { match: { is_sent: false } }],
              },
            },
            {
              bool: {
                must: [{ match: { is_received: false } }],
                must_not: [{ match: { 'bill_to.id': id } }],
              },
            },
          ],
        },
      },
      received: {
        bool: {
          must: [{ match: { is_received: true } }],
        },
      },
      sent: {
        bool: {
          must: [{ match: { is_sent: true } }],
        },
      },
    };

    const payload = {
      aggs: {
        all: {
          cardinality: {
            field: 'id',
          },
        },
        incoming: {
          filter: {
            bool: {
              must: [{ match: { 'bill_from.id': id } }],
            },
          },
          aggs: {
            amount: {
              sum: {
                field: 'amount_payable',
              },
            },
          },
        },
        max_amount: {
          max: { field: 'amount' },
        },
        min_amount: {
          min: { field: 'amount' },
        },
        outgoing: {
          filter: {
            bool: {
              must_not: [{ match: { 'bill_from.id': id } }],
            },
          },
          aggs: {
            amount: {
              sum: {
                field: 'amount_payable',
              },
            },
          },
        },
        pending: {
          filter: queries.pending,
          aggs: {
            amount: {
              sum: {
                field: 'amount_payable',
              },
            },
          },
        },
        received: {
          filter: queries.received,
          aggs: {
            amount: {
              sum: {
                field: 'amount_payable',
              },
            },
          },
        },
        sent: {
          filter: queries.sent,
          aggs: {
            amount: {
              sum: {
                field: 'amount_payable',
              },
            },
          },
        },
      },
      size: pagination.per,
      from: from >= 0 ? from : 0,
      sort,
      query: {
        constant_score: {
          filter: {
            bool: {
              must: [
                {
                  match: { type: filters.type },
                },
                {
                  bool: {
                    should: [
                      { range: { payment_count: { gt: 0 } } },
                      { match: { is_manual: true } },
                    ],
                  },
                },
              ],
              should: [],
            },
          },
        },
      },
    };
    if (filters.dates.year !== 'all') {
      if (fromDate && toDate) {
        const dateQuery = { range: { date: { gte: fromDate, lte: toDate } } };
        payload.query.constant_score.filter.bool.must.push(dateQuery);
      }
    }
    const partiesQuery = {
      bool: {
        must: [],
      },
    };
    if (filters.type === 'agency') {
      if (filters.mode === 'incoming') {
        partiesQuery.bool.must.push({ match: { 'bill_from.id': id } });
        if (filters.hosts.length > 0) {
          partiesQuery.bool.must.push({ terms: { 'bill_to.id': filters.hosts } });
        }
      } else if (filters.mode === 'outgoing') {
        partiesQuery.bool.must.push({ match: { 'bill_to.id': id } });
        if (filters.contractors.length > 0) {

          partiesQuery.bool.must.push({ terms: { 'bill_from.id': filters.contractors } });
        }
      }
    } else if (filters.type === 'agent') {
      partiesQuery.bool.must.push({ match: { 'bill_to.id': id } });
      if (filters.employees.length > 0) {
        partiesQuery.bool.must.push({ terms: { 'bill_from.id': filters.employees } });
      }
    }
    payload.query.constant_score.filter.bool.must.push(partiesQuery);

    const statusesQuery = {
      bool: {
        should: [],
      },
    };
    filters.statuses.forEach((status) => {
      statusesQuery.bool.should.push(queries[status]);
    });
    payload.query.constant_score.filter.bool.must.push(statusesQuery);
    return payload;
  },
  statementsSelected: (state) => state.selected,
};

const actions = {
  async doCreateStatement({ commit }, payload) {
    const { ignore, ...params } = payload;
    const { data } = await HTTP.post('/statements', params);
    if (!ignore) {
      const [statement] = data.data.statements;
      commit('SET_STATEMENTS_ATOMIC', { key: 'statement', val: statement });
    }
    return data;
  },
  async doGetStatement({ commit }, payload) {
    const { id, ...params } = payload;
    const { data } = await HTTP.get(`/statements/${id}`, { params });
    const [statement] = data.data.statements;
    commit('SET_STATEMENTS_ATOMIC', { key: 'statement', val: statement });
    return data;
  },
  async doSearchStatements({ commit }, payload) {
    const { data } = await SEARCH.post('/sion_statements/_search', payload);
    const statements = data.hits.hits.map((hit) => {
      const obj = hit._source;
      obj.loading = false;
      return obj;
    });
    commit('SET_STATEMENTS_ATOMIC', { key: 'statements', val: statements });
    return data;
  },
  async doUpdateStatement({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.patch(`/statements/${id}`, params);
    const [statement] = data.data.statements;
    if (!ignore) commit('SET_STATEMENTS_ATOMIC', { key: 'statement', val: statement });
    return data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
