import { apiClient } from '../apiClient';

const DOMAIN = 'imports';

export default {
  create: ({ id, payload }) => {
    const headers = { ...apiClient.defaults.headers };
    headers['Content-Type'] = 'multipart/form-data';
    return apiClient.post(`/companies/${id}/${DOMAIN}`, payload, { headers });
  },
  get: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}`, { params }),
  getAll: ({ id, ...params }) => apiClient.get(`/companies/${id}/${DOMAIN}`, { params }),
  update: ({ id, ...payload }) => apiClient.patch(`/${DOMAIN}/${id}`, payload),
  delete: ({ id, ...payload }) => apiClient.delete(`/${DOMAIN}/${id}`, payload),

  getRows: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}/rows`, { params }),
  updateRow: ({ id, ...payload }) => apiClient.patch(`/import_rows/${id}`, payload),

  createColumn: ({ id, ...payload }) => apiClient.post(`/companies/${id}/import_columns`, payload),
  getColumns: (payload) => apiClient.post('/import_columns', payload),
  createColumnMapping: ({ id, ...payload }) => apiClient.post(`/import_columns/${id}/mappings`, payload),

  createPreset: ({ id, ...payload }) => apiClient.post(`/companies/${id}/import_presets`, payload),
  updatePreset: ({ id, ...payload }) => apiClient.patch(`/import_presets/${id}`, payload),
  getPreset: ({ id, ...params }) => apiClient.get(`/import_presets/${id}`, { params }),
  getPresets: ({ id, ...params }) => apiClient.get(`/companies/${id}/import_presets`, { params }),
};
