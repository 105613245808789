import { apiClient } from '../apiClient';

const DOMAIN = 'pnrs';

export default {
  create: (payload) => apiClient.post(`/${DOMAIN}`, payload),
  get: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}`, { params }),
  update: ({ id, ...payload }) => apiClient.put(`/${DOMAIN}/${id}`, payload),
  createTrip: ({ id, ...payload }) => apiClient.post(`/${DOMAIN}/${id}/trip`, payload),
  itineraryChange: (payload) => apiClient.post(`/${DOMAIN}/_itinerary_change`, payload),
};
