import { dayjs } from '@/plugins/dayjs';

export const formatPayment = (payment) => {
  const dateFields = ['created_at', 'date', 'statement_date'];
  dateFields.forEach((field) => {
    const val = payment[field];
    if (val) {
      payment[field] = dayjs(val).toDate();
    }
  });
  return payment;
};

export const getAgentAmount = (opts) => {
  const { agent, booking, company, payment } = opts;
  if (booking.is_agent_commissionable) {
    let afterCost = payment.amount - (booking.supplier_cost || 0);
    let contractorBooking = booking.company_id !== company.id;
    if (!contractorBooking) {
      const { iata } = booking._embedded;
      if (iata) {
        contractorBooking = iata.is_managed || iata.company_id !== company.id;
      }
    }
    if (contractorBooking && agent && agent.commission_split_basis === 'net') {
      afterCost = getContractorAmount({ booking, payment });
    }
    const split = afterCost * ((100 - booking.agent_commission_rate) / 100.0);
    return afterCost - split;
  }
  return 0;
};

export const getContractorAmount = ({ booking, payment }) => {
  const afterCost = payment.amount - (booking.supplier_cost || 0);
  return afterCost * ((100 - booking.commission_split) / 100.0);
};
