import io from 'socket.io-client';
import { reportIssue } from '@/utils/helpers/tracking/tracking-methods';

const socket = io(process.env.VUE_APP_SOCKET_ENDPOINT, { autoConnect: false });

const reportDisconnection = (reason) => {
  // https://socket.io/docs/v2/client-api/#event-disconnect
  const ignoreReasons = [
    'transport close',
    'io client disconnect',
    'io server disconnect'
  ];
  if (ignoreReasons.includes(reason)) return;
  reportIssue(`Warning: Client socket disconnected. Reason: ${reason}`);
};

socket.on('disconnect', (reason) => {
  reportDisconnection(reason);
  const tryReconnecting = setInterval(() => {
    if (socket.connected) clearInterval(tryReconnecting);
    socket.open();
  }, 3000);
});

export default socket;
