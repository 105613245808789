import Vue from 'vue';
import { cloneDeep } from '@/utils/helpers/methods';
import { HTTP } from '@/utils/http';

const defaultSource = {
  source: 'sabre',
  _config: {
    agent: {
      primary: {
        location: 'sine',
      },
      secondary: {
        location: null,
      },
    },
    agreements: {
      downline: false,
      web_services: false,
    },
    credentials: {},
    settings: {
      agent_fallback: false,
    },
  },
  _embedded: {
    branches: [],
  },
};

// TODO: This should be replaced with an API endpoint, but it'll work here for now
const gds = [
  {
    key: 'sabre',
    label: 'Sabre',
    is_active: true,
    logo: 'https://sioncentral.s3.amazonaws.com/integrations/sabre.png',
    description: 'Send Sabre bookings to Sion in real-time',
  },
  {
    key: 'amadeus',
    label: 'Amadeus',
    is_active: true,
    logo: 'https://sioncentral.s3.amazonaws.com/integrations/amadeus.png',
    description: 'Send Amadeus bookings to Sion in real-time',
  },
  {
    key: 'travelport',
    label: 'Travelport',
    is_active: true,
    logo: 'https://sioncentral.s3.amazonaws.com/integrations/travelport.png',
    description: 'Send Travelport+, Galileo, and Apollo bookings to Sion in real-time',
  }
];

const state = {
  source: cloneDeep(defaultSource),
  sources: [],
  gds,
};

const mutations = {
  ADD_BRANCH_FOR_SOURCE_CONNECTION(state, payload) {
    if (payload.index === undefined) {
      state.source._embedded.branches.push(payload.val);
    } else {
      state.source._embedded.branches.splice(payload.index, 0, payload.val);
    }
  },
  REMOVE_BRANCH_FOR_SOURCE_CONNECTION(state, payload) {
    state.source._embedded.branches.splice(payload.index, 1);
  },
  RESET_SOURCE_CONNECTIONS(state, payload) {
    // used?
    switch (payload) {
      case 'sources':
        state.sources = [];
        break;
      default:
    }
  },
  SET_BRANCHES_ATOMIC_FOR_SOURCE_CONNECTION(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state.source._embedded.branches[payload.index];
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
  SET_SOURCE_CONNECTION_DEFAULT(state) {
    state.source = cloneDeep(defaultSource);
  },
  SET_SOURCE_CONNECTIONS_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
};

const getters = {
  sourceConnection: (state) => state.source,
  sourceConnectionBranches({ sources }) {
    let list = [];
    sources.forEach((connection) => {
      const { branches } = connection._embedded;
      if (branches) list = list.concat(branches);
    });
    return list;
  },
  sourceConnections: (state) => state.sources,
  // sourceConnectionGds: (source) => state.gds.find(({ key }) => key == source),
  sourceConnectionsGdsList: (state) => {
    if (process.env.VUE_APP_TRAVELPORT_ENABLED !== 'true') {
      return state.gds.filter((source) => source.key !== 'travelport');
    }
    return state.gds;
  },
  sourceConnectionsNoHost({ sources }, _getter, _rootState, rootGetters) {
    const agency = rootGetters['companies/company'];
    return sources.filter((source) => {
      const { company } = source._embedded;
      if (company) {
        return company.id === agency.id || company.is_managed;
      }
      return false;
    });
  },
};

const actions = {
  async doCreateSourceConnection({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.post(`/companies/${id}/source_connections`, params);
    const [source] = data.data.source_connections;
    if (!ignore) commit('SET_SOURCE_CONNECTIONS_ATOMIC', { key: 'source', val: source });
    return data;
  },
  async doDeleteSourceConnection({ commit }, payload) {
    // used?
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.delete(`/source_connections/${id}`, params);
    const [source] = data.data.source_connections;
    if (!ignore) commit('SET_SOURCE_CONNECTIONS_ATOMIC', { key: 'source', val: source });
    return data;
  },
  async doGetSourceConnection({ commit }, payload) {
    const { id, ...params } = payload;
    const { data } = await HTTP.get(`/source_connections/${id}`, { params });
    const [source] = data.data.source_connections;
    commit('SET_SOURCE_CONNECTIONS_ATOMIC', { key: 'source', val: source });
    return data;
  },
  async doGetSourceConnections({ commit }, payload) {
    const { id, ...params } = payload;
    const { data } = await HTTP.get(`/companies/${id}/source_connections`, { params });
    commit('SET_SOURCE_CONNECTIONS_ATOMIC', { key: 'sources', val: data.data.source_connections });
    return data;
  },
  async doUpdateSourceConnection({ commit }, payload) {
    // used?
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.patch(`/source_connections/${id}`, params);
    const [source] = data.data.source_connections;
    if (!ignore) commit('SET_SOURCE_CONNECTIONS_ATOMIC', { key: 'source', val: source });
    return data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
