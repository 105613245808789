<template>
  <b-tag
    class="badge-tag"
    :class="{ 'badge-tag--reversed': isReversed, 'badge-tag--wrap-text': allowWrap }"
    :type="`${type} is-light`"
    rounded
    :size="size"
    :icon="icon"
    :icon-pack="$defaultIconPack"
    :icon-type="iconType || type"
  >
    <StatusDotLabel
      v-if="statusDot"
      class="ml-0"
      :type="dotType || type"
    />
    <slot />
  </b-tag>
</template>

<script>
  import StatusDotLabel from '@/components/payments/StatusDotLabel.vue';

  export default {
    name: 'BadgeTag',
    components: { StatusDotLabel },
    props: {
      allowWrap: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: 'is-default'
      },
      dotType: {
        type: String,
        default: ''
      },
      statusDot: {
        type: Boolean,
        default: false
      },
      icon: {
        type: String,
        default: ''
      },
      isReversed: {
        type: Boolean,
        default: false
      },
      iconType: {
        type: String,
        default: ''
      },
      size: {
        type: String,
        validator: (val) => ['is-small', 'is-medium', 'is-large'].includes(val),
      }
    }
  }
</script>

<style lang="scss">
.badge-tag {
  .icon .is-size-6 {
    font-size: pxToRem(14) !important;
  }
  &--reversed {
    flex-direction: row-reverse;
    gap: $spacing-1;
    .icon {
      margin: 0 !important;
    }
  }
  &--wrap-text {
    white-space: break-spaces !important;
    height: auto !important;
  }
}
</style>
