import { apiClient } from '../apiClient';

const DOMAIN = 'trips';

export default {
  create: (payload) => apiClient.post(`/${DOMAIN}`, payload),
  get: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}`, { params }),
  update: ({ id, ...payload }) => apiClient.patch(`/${DOMAIN}/${id}`, payload),
  delete: ({ id, ...payload }) => apiClient.delete(`/${DOMAIN}/${id}`, payload),
  getAll: (params) => apiClient.get(`/${DOMAIN}`, { params }),
  search: (payload) => apiClient.post(`/sion_${DOMAIN}/_search`, payload),

  getAttachments: async ({ id }) => {
    // temp solution as trips is the only domain to not have a dedicated attachments endpoint.
    const res = await apiClient.get(`/${DOMAIN}/${id}`, { params: { zoom: 'attachments'} });
    return { data: res.data.trips[0]._embedded };
  },

  getInvoices: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}/invoices`, { params }),

  createTraveler: ({ id, ...payload }) => apiClient.post(`/${DOMAIN}/${id}/travelers`, payload),
  updateTraveler: ({ id, ...payload }) => apiClient.post(`/travelers/${id}`, payload),
  deleteTraveler: ({ id, ...payload }) => apiClient.delete(`/${DOMAIN}/${id}/travelers`, payload),

  createBooking: ({ id, ...payload }) => apiClient.post(`/${DOMAIN}/${id}/bookings`, payload),
  getBooking: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}/bookings`, { params }),
  deleteBooking: ({ id, ...payload }) => apiClient.delete(`/${DOMAIN}/${id}/bookings`, payload),
};
