const state = {
  allergens: [],
};

const mutations = {
  SET_ALLERGENS(state, payload) {
    state.allergens = payload;
  },
};

const getters = {
  allergens: (state) => state.allergens,
};

const actions = {
  async doGetAllergens({ commit }) {
    const { data } = await this._vm.$api.health.getAllergens();
    commit('SET_ALLERGENS', data.allergens);
    return data.allergens;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
