import alphanumericOptions from '@/data/alphanumeric-options';

export const generatePlaceholderId = (length = 10) => {
  let id = '';
  const alphanumeric = alphanumericOptions.alphanumericCharsString;
  const alphanumericLength = alphanumeric.length;
  for (let i = 0; i < length; i += 1) {
    id += alphanumeric.charAt(Math.floor(Math.random() * alphanumericLength));
  }
  return id;
};

export const isPhoneNumber = (str) => {
  if (str) {
    const stripped = str.replace(/\D/g, '');
    if (stripped) {
      return stripped.length >= 9;
    }
  }
  return false;
};

export const comparePhoneNumbers = (first, second) =>
  stripPhoneNumber(first) === stripPhoneNumber(second);

export const stripPhoneNumber = (str) => {
  if (str) {
    return str.replace(/\D/g, '');
  }
  return '';
};

export const arrayToSentence = (arr) => {
  if (arr.length === 0) return '';
  else if (arr.length === 1) return arr[0];
  else if (arr.length === 2) return `${arr[0]} and ${arr[1]}`;

  const lastWord = arr.pop();
  const sentence = arr.join(', ');

  return `${sentence}, and ${lastWord}`;
}
