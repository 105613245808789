import { range } from '@/utils/helpers/methods';

export const dateFormatYMD = 'YYYY-MM-DD';
export const dateFormatMDY = 'MM/DD/YYYY';
export const dateFormatShortMDY = 'M/D/YY';
export const dateFormatLongAbbrMDY = 'MMM D, YYYY'
export const dateFormatLongMDY = 'MMMM D, YYYY';
export const dateTimeFormatLongMDYT = 'MMMM D, YYYY h:mm A';
export const dateTimeFormatDT = 'dddd h:mm A';
export const dateFormatUTC = 'YYYY-MM-DDTHH:mm:ss.SSSZZ';

export const dateMonthDaysList = range(1, 32);

export const dateWeekdaysOneCharList = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

export const dateWeekdays = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};

export const dateFormatOptions = [
    { key: 'dmy', label: 'Day / Month / Year' },
    { key: 'mdy', label: 'Month / Day / Year' },
    { key: 'ymd', label: 'Year / Month / Day' },
];

export const datePresetIntervalsList = [
    { value: ['weekly'], label: 'Every' },
    { value: [2], label: 'Every Other' },
    { value: [1], label: 'First' },
    { value: [-1], label: 'Last' },
    { value: [1, 3], label: 'First & Third' },
    { value: [2, 4], label: 'Second & Fourth' },
    { value: ['monthly'], label: 'Fixed Dates' },
];

export const datePresetRangesList = [
    { key: 'year', label: 'Year' },
    { key: 'quarter', label: 'Quarter' },
    { key: 'month', label: 'Month' },
    { key: 'dates', label: 'Dates' },
    { key: 'date', label: 'Date' },
];

export const dateImportantList = [
    { key: 'anniversary', label: 'Anniversary' },
    { key: 'birthday', label: 'Birthday' },
    { key: 'other', label: 'Other' },
];
