import axios from 'axios';
// import awsAuth from '@/utils/aws-auth';

const HTTP = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
    Application: process.env.VUE_APP_TOKEN,
  },
});

// HTTP.interceptors.response.use(response => response, (error) => {
//   if (error.response.status === 401) {
//     const msg = error.response.data.data.error_message;
//     store.commit(mutations.SET_APP_PERM_MESSAGE, msg);
//     router.push({ name: 'login' });
//   }
//   return Promise.reject(error);
// });

const SEARCH = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
    Application: process.env.VUE_APP_TOKEN,
  },
});

// SEARCH.interceptors.request.use((config) => {
//   if (process.env.VUE_APP_API_ENDPOINT.match(/amazonaws.com/)) {
//     config.headers['Content-Type'] = 'application/json';
//     config.headers.Host = config.baseURL.replace(/http(s)?:\/{2}/, '');
//     config.headers['X-Amz-Date'] = new Date().toISOString().replace(/[:-]|\.\d{3}/g, '');
//     config.headers.Authorization = awsAuth.authorize(config);
//     const signedHeaders = config.headers;
//     delete signedHeaders.Host;
//     config.headers = signedHeaders;
//   } else {
//     config.headers.Application = process.env.VUE_APP_TOKEN;
//   }
//   return config;
// });

export { HTTP, SEARCH };
