<template>
  <RouterView :key="$route.fullPath" />
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { cloneDeep } from '@/utils/helpers/methods';
import { Validator } from 'vee-validate';
import installRules from '@/utils/validators';
import SuspendedModal from '@/components/users/SuspendedModal.vue';
import PasswordResetModal from '@/components/users/PasswordResetModal.vue';
import TermsModal from '@/components/onboarding/TermsModal.vue';
import { userTrackingIdentify, updateCompanyTrackingGroup, updateUserTrackingProfile, setTrackingEvent } from '@/utils/helpers/tracking/tracking-methods';

installRules(Validator);

export default {
  name: 'App',
  data() {
    return {
      defaultSnackbar: {
        position: 'is-bottom-left',
        queue: true,
      },
      settings: {
        modal: false,
      },
      query: '',
    };
  },
  computed: {
    ...mapGetters('companies', ['company', 'iatas']),
    ...mapGetters('app', ['appSearching', 'appSnackbar', 'appPublicRoutes']),
    ...mapGetters('users', ['userSelf']),
  },
  watch: {
    'appSnackbar.queued_at': function displaySnackbar(val) {
      if (val) {
        const options = Object.assign({...this.defaultSnackbar}, this.appSnackbar);
        this.$buefy.snackbar.open(options);
      }
    },
    $route: function checkSetup() {
      if (this.appPublicRoutes.includes(this.$route.name)) return;

      const userStatus = this.getUserStatus();
      switch (userStatus) {
        case 'PASSWORD_RESET':
          this.handlePasswordReset();
          break;
        case 'TERMS_AGREEMENT':
          this.handleTermsAgreement();
          break;
        case 'ACCOUNT_SUSPENDED':
          this.handleAccountSuspended();
          break;
        default:
          break;
      }
    },
    userSelf(user) {
      if (!user) return;
      userTrackingIdentify(user);
      updateUserTrackingProfile(user);
    },
    company(company) {
      updateCompanyTrackingGroup(company);
    }
  },
  mounted() {
    window.addEventListener('keyup', this.keyupListener);
    if (!this.$isProd) {
      // this.setDevIndicators();
    }
  },
  methods: {
    ...mapMutations('app', ['SET_APP_ATOMIC']),
    ...mapMutations('users', ['SET_USER']),
    handleAccountSuspended() {
       this.$buefy.modal.open({
        parent: this,
        canCancel: false,
        component: SuspendedModal,
        hasModalCard: true,
      });
      this.settings.modal = true;
    },
    handlePasswordReset() {
      // Assuming that the reason you need to reset password at this point was because you got in through an invite we could track an accepted invite but for now just tracking the modal opening
      setTrackingEvent('Password Reset Modal Opened');
      this.SET_USER(cloneDeep(this.userSelf));
      this.$buefy.modal.open({
        parent: this,
        canCancel: false,
        component: PasswordResetModal,
        hasModalCard: true,
      });
      this.settings.modal = true;
    },
    handleTermsAgreement() {
      this.SET_USER(cloneDeep(this.userSelf));
      this.$buefy.modal.open({
        parent: this,
        canCancel: false,
        component: TermsModal,
        hasModalCard: true,
        props: {
          mode: 'app',
        },
      });
      this.settings.modal = true;
    },
    getUserStatus() {
      let userStatus = 'NORMAL';
      const isRequiredPasswordReset = this.userSelf?.require_password_reset && !this.settings.modal;
      const isRequiredTermsAgreement = !this.userSelf?.id && !this.userSelf?.agreed_to_terms && !this.settings.modal;
      const isAccountSuspended =  !this.userSelf?.id && !this.company?.setup?.bookings && !this.iatas?.length && !this.settings.modal;
      if (isRequiredPasswordReset) userStatus = 'PASSWORD_RESET';
      else if (isRequiredTermsAgreement) userStatus = 'TERMS_AGREEMENT';
      else if (isAccountSuspended) userStatus = 'SUSPENDED';
      return userStatus
    },
    keyupListener(event) {
      const target = event.target || event.srcElement;
      const fromInput = /textarea|input|select/i.test(target.nodeName);
      if (event.keyCode === 191) {
        if (this.appSearching) {
          this.SET_APP_ATOMIC({ key: 'searching', val: false });
        } else if (!fromInput) {
          this.SET_APP_ATOMIC({ key: 'searching', val: true });
        }
      }
    },
    setDevIndicators() {
      document.title = 'SION DEV'
      document.getElementById('favicon').setAttribute('href', '/favicon-dev.ico')
    }
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';
</style>
