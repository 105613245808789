import Vue from 'vue';

export default () => {
    if (!Vue.prototype.$isProd && !Vue.prototype.$isStaging) return;

    const GA_ID = process.env.VUE_APP_GOOGLE_ANALYTICS_ID;

    const script = document.createElement('script'); 
    script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_ID}`; 
    document.head.append(script);

    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', `${GA_ID}`);
}