import Vue from 'vue';
import { HTTP } from '@/utils/http';
import tagsColumnOptions from '@/data/tags-column-options.json';

const state = {
  columns: tagsColumnOptions.columns,
  refresh: false,
  tag: {},
  tags: [],
};

const mutations = {
  SET_TAG(state, payload) {
    state.tag = payload;
  },
  SET_TAG_FIELD(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state.tag;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
  SET_TAGS(state, payload) {
    state.tags = payload;
  },
  SET_TAGS_REFRESH(state, payload) {
    state.refresh = payload;
  },
};

const getters = {
  tag: (state) => state.tag,
  tagColumns: (state) => state.columns,
  tags: (state) => state.tags,
  tagsRefresh: (state) => state.refresh,
};

const actions = {
  async doCreateTag({ commit }, payload) {
    const { id, ...params } = payload;
    const { data } = await HTTP.post(`/companies/${id}/tags`, params);
    commit('SET_TAG', data.data.tags[0]);
    return data;
  },
  async doDeleteTag({ commit }, payload) {
    const { id, ...params } = payload;
    const { data } = await HTTP.delete(`/tags/${id}`, params);
    commit('SET_TAG', data.data.tags[0]);
    return data;
  },
  async doDeleteTagForResource({ commit }, payload) {
    const { id, resource, ...params } = payload;
    const resourceId = params.resource_id;
    delete params.resource_id;
    const { data } = await HTTP.delete(`/tags/${id}/${resource}/${resourceId}`, params);
    commit('SET_TAG', data.data.tags[0]);
    return data;
  },
  async doGetTags({ commit }, payload) {
    const { id, ...params } = payload;
    const { data } = await HTTP.get(`/companies/${id}/tags`, { params });
    commit('SET_TAGS', data.data.tags);
    return data;
  },
  async doUpdateTag({ commit }, payload) {
    const { id, ...params } = payload;
    const { data } = await HTTP.patch(`/tags/${id}`, params);
    commit('SET_TAG', data.data.tags[0]);
    return data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
