import { apiClient } from '../apiClient';

const DOMAIN = 'addresses';

export default {
  create: (payload) => apiClient.post(`/${DOMAIN}`, payload),
  get: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}`, { params }),
  update: ({ id, ...payload }) => apiClient.patch(`/${DOMAIN}/${id}`, payload),
  override: ({ id, ...payload }) => apiClient.put(`/${DOMAIN}/${id}`, payload),
  delete: ({ id, ...payload }) => apiClient.delete(`/${DOMAIN}/${id}`, payload),

  getDestination: ({ id, ...params }) => apiClient.get(`/destinations/${id}`, { params }),
  getDestinations: (payload) => apiClient.get('/destinations', payload),
};
