import Vue from 'vue';
import { isEmpty, cloneDeep } from '@/utils/helpers/methods';
import { HTTP } from '@/utils/http';

const defaultImport = {
  currency_type: 'float',
  currency_separator: 'decimal',
  date_format: 'mdy',
  dedupe: true,
  default_commission_rate: 10,
  default_payment_term: 60,
  has_header_row: false,
  skip_supplier_normalization: false,
  strip_quotes: false,
  type: 'bookings',
  _embedded: {
    bookings: [],
    clients: [],
    supplier: {},
    suppliers: [],
  },
};

const state = {
  file: null,
  headers: [],
  import: cloneDeep(defaultImport),
  imports: [],
  mappings: [],
  next: false,
  preview: [],
  presetKeys: ['currency_separator', 'date_format', 'has_header_row', 'strip_quotes'],
  presetBookingKeys: [
    'dedupe',
    'default_agent_id',
    'default_booking_category_id',
    'default_company_id',
    'default_commission_rate',
    'default_iata_id',
    'default_payment_term',
    'skip_supplier_normalization',
  ],
  tab: 0,
  row: {},
  rows: [],
};

const mutations = {
  PUSH_IMPORTS_ATOMIC(state, payload) {
    // used?
    state[payload.key].unshift(payload.val);
  },
  SET_IMPORT_DEFAULT(state) {
    state.import = cloneDeep(defaultImport);
  },
  SET_IMPORT_PROGRESS(state, payload) {
    state.imports[payload.index].stats.progress = payload.val;
  },
  SET_IMPORT_MAPPING_ATOMIC(state, payload) {
    const { index, key, val } = payload;
    const mapping = state.mappings.find((mapItem) => mapItem.index === index);
    if (key === 'id') {
      // leaving the below three lines just in case, but i think that line 118 does what we need
      // const existingQuery = {};
      // existingQuery[key] = val;
      // const existing = filter(state.mappings, existingQuery);
      const existing = state.mappings.filter((mappingItem) => mappingItem.id === val);
      if (mapping) {
        Vue.set(mapping, key, val);
        existing.forEach((obj) => Vue.set(obj, key, null));
      }
    } else {
      Vue.set(mapping, key, val);
    }
  },
  SET_IMPORTS_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
  SPLICE_IMPORTS_ATOMIC(state, payload) {
    if (payload.val) {
      state[payload.key].splice(payload.index, payload.count, payload.val);
    } else {
      state[payload.key].splice(payload.index, payload.count);
    }
  },
};

const getters = {
  importState: (state) => state.import,
  importPresetKeys: (state) => state.presetKeys,
  importPresetBookingKeys: (state) => state.presetBookingKeys,
  importFile: (state) => state.file,
  importHeaders: (state) => state.headers,
  importMappings: (state) => state.mappings,
  importMappingsPayload({ mappings }) {
    const selected = mappings.filter((mapping) => !isEmpty(mapping.id));
    return selected.map(({ id, index }) => ({ column_id: id, index }));
  },
  importPreview: (state) => state.preview,
  imports: (state) => state.imports,
  importsNext: (state) => state.next,
  importTab: (state) => state.tab,
};

const actions = {
  async doCreateImport({ commit }, payload) {
    const body = new FormData();
    const { file, id, ...params } = payload;
    body.append('file', file);
    Object.keys(params).forEach((key) => {
      body.append(key, params[key]);
    });
    const { data } = await this._vm.$api.imports.create({ id, payload: body });
    commit('SET_IMPORTS_ATOMIC', { key: 'import', val: data.imports[0] });
  },
  async doDeleteImport({ commit }, payload) {
    const { id, ...params } = payload;
    const { data } = await HTTP.delete(`/imports/${id}`, params);
    commit('SET_IMPORTS_ATOMIC', { key: 'import', val: data.data.imports[0] });
    return data;
  },
  async doGetImport({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.get(`/imports/${id}`, { params });
    if (!ignore) {
      commit('SET_IMPORTS_ATOMIC', { key: 'import', val: data.data.imports[0] });
    }
    return data;
  },
  async doGetImportRows({ commit }, payload) {
    // used?
    const { id, ...params } = payload;
    const { data } = await HTTP.get(`/imports/${id}/rows`, { params });
    commit('SET_IMPORTS_ATOMIC', { key: 'rows', val: data.data.rows });
    return data;
  },
  async doGetImports({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.get(`/companies/${id}/imports`, { params });
    if (!ignore) commit('SET_IMPORTS_ATOMIC', { key: 'imports', val: data.data.imports });
    return data;
  },
  async doUpdateImport({ commit }, payload) {
    // used?
    const { id, ...params } = payload;
    const { data } = await HTTP.patch(`/imports/${id}`, params);
    commit('SET_IMPORTS_ATOMIC', { key: 'import', val: data.data.imports[0] });
    return data;
  },
  async doUpdateImportRow({ commit }, payload) {
    // used?
    const { id, ...params } = payload;
    const { data } = await HTTP.patch(`/import_rows/${id}`, params);
    commit('SET_IMPORTS_ATOMIC', { key: 'row', val: data.data.rows[0] });
    return data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
