import Vue from 'vue';

const state = {
  drawer: {
    id: null,
    type: 'booking',
    refresh: false,
    prev: null,
  },
  public: [
    'about',
    'home',
    'invite',
    'invoiceFollowUp',
    'invoicePayment',
    'login',
    'onboarding',
    'onboardingStepOne',
    'onboardingStepTwo',
    'onboardingStepThree',
    'onboardingStepFour',
    'onboardingStepFinal',
    'passwordReset',
    'passwordResetRequest',
    'terms',
  ],
  refresh: false,
  redirect: null,
  searching: false,
  setup: {
    commissions: false,
    drawer: false,
  },
  sidebar: window.innerWidth >= 1200,
  showFiltersRefresh: {
    contacts: false,
    commissions: false,
    bookings: false,
    statements: false,
    client: false,
    supplier: false,
    suppliers: false,
  },
  snackbar: null,
  checkBroadcastMessage: false
};

const mutations = {
  SET_APP_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
  SET_APP_REFRESH(state, refresh) {
    state.refresh = refresh;
  },
  SET_APP_SNACKBAR(state, snackbar) {
    snackbar.queued_at = new Date();
    state.snackbar = snackbar;
  },
  SET_APP_CHECK_BROADCAST_MESSAGE(state, isBroadcastMessageCheck ) {
    state.checkBroadcastMessage = isBroadcastMessageCheck;
  },
};

const getters = {
  appDrawer: (state) => state.drawer,
  appPublicRoutes: (state) => state.public,
  appRedirect: (state) => state.redirect,
  appRefresh: (state) => state.refresh,
  appSearching: (state) => state.searching,
  appSetup: (state) => state.setup,
  appSidebar: (state) => state.sidebar,
  appSnackbar: (state) => state.snackbar,
  appShowFiltersRefresh: (state) => state.showFiltersRefresh,
  appCheckBroadcastMessage: (state) => state.checkBroadcastMessage,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
