import { cloneDeep } from '@/utils/helpers/methods';
import Vue from 'vue';

const defaultClaim = {};

const state = {
  claim: cloneDeep(defaultClaim),
  claims: [],
};

const mutations = {
  REMOVE_CLAIM_FROM_CLAIMS(state, payload) {
    let { index } = payload;
    if (index === undefined) {
      index = state.claims.findIndex((claimsItem) => claimsItem.id === payload.id);
    }
    if (state.claims[index] !== undefined) {
      state.claims.splice(index, 1);
    }
  },
  SET_CLAIMS_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
};

const getters = {
  claim: (state) => state.claim,
  claims: (state) => state.claims,
};

const actions = {
  async doCreateClaim({ commit }, payload) {
    const { data } = await this._vm.$api.claims.create(payload);
    commit('SET_CLAIMS_ATOMIC', { key: 'claim', val: data.claims[0] });
  },
  async doDeleteClaim({ commit }, payload) {
    await this._vm.$api.claims.delete(payload);
    commit('REMOVE_CLAIM_FROM_CLAIMS', { id: payload.id });
  },
  async doGetClaims({ commit }, payload) {
    const { data } = await this._vm.$api.claims.getAll(payload);
    commit('SET_CLAIMS_ATOMIC', { key: 'claims', val: data.claims });
  },
  async doUpdateClaim({ commit }, payload) {
    const { data } = await this._vm.$api.claims.update(payload);
    commit('SET_CLAIMS_ATOMIC', { key: 'claim', val: data.claims[0] });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
