<template lang="html">
  <div :class="`is-${color}`">
    <svg
      id="sion-logo"
      :style="`width:${width}px;`"
      data-name="sion-logo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 360 173.23"
    >
      <g
        v-if="logo"
        id="logo"
        data-name="logo"
      >
        <path
          class="cls-1"
          d="M191.64,237.55v12.33h-.58c-4.17-4.47-11.55-7.09-20.11-7.09-8.15,0-13.4,3.5-13.4,8.94,0,4.57,3.4,6.89,14.27,8,17.21,1.75,23.51,7.68,23.51,19.52,0,12.33-9.32,19.63-25.25,19.63-10.59,0-18.94-2.13-24.19-6.12V280.28h.59c5.84,5.34,13.88,8.16,23.31,8.16,8.93,0,14-3.21,14-8.94,0-4.95-3.5-7.29-15-8.53C152,269.12,146,263.67,146,252.41c0-12.43,9.62-20,25.16-20C179.58,232.41,187.47,234.45,191.64,237.55Z"
          transform="translate(-4.73 -187.93)"
        />
        <path
          class="cls-1"
          d="M202.89,233.37h11.66v64.49H202.89Z"
          transform="translate(-4.73 -187.93)"
        />
        <path
          class="cls-1"
          d="M289.63,265.62c0,19.43-13.89,33.22-33.71,33.22s-33.71-13.8-33.71-33.22,13.89-33.23,33.71-33.23S289.63,246.2,289.63,265.62Zm-55.66,0c0,13.49,8.94,22.83,21.95,22.83s22-9.33,22-22.83-8.93-22.84-22-22.84S234,252.12,234,265.62Z"
          transform="translate(-4.73 -187.93)"
        />
        <path
          class="cls-1"
          d="M340.54,297.87,308.77,246.1h-.19v51.77H297.31V233.38h16l29.81,48.19h.19V233.38H354.6v64.49Z"
          transform="translate(-4.73 -187.93)"
        />
      </g>
      <g
        v-if="mark"
        id="mark"
        data-name="mark"
      >
        <path
          class="cls-1"
          d="M99.69,198.42a6.28,6.28,0,0,0-8.83,1l-22,27.47a6.19,6.19,0,0,0,1,8.68,6.09,6.09,0,0,0,3.75,1.35h.38a6.21,6.21,0,0,0,4.82-2.31l21.9-27.33A6.28,6.28,0,0,0,99.69,198.42Z"
          transform="translate(-4.73 -187.93)"
        />
        <path
          class="cls-1"
          d="M38,289.67a6.06,6.06,0,0,0-2.27-4.11,6.12,6.12,0,0,0-8.61,1L11,306.69a6,6,0,0,0,4.62,9.81h.31a6,6,0,0,0,4.72-2.26l16.08-20.07A6.16,6.16,0,0,0,38,289.67Z"
          transform="translate(-4.73 -187.93)"
        />
        <path
          id="the_lightening_shape"
          data-name="the lightening shape"
          class="cls-1"
          d="M106.11,257.69a6.23,6.23,0,0,0-5.54-3.53L24,253,67.87,198.2a6.28,6.28,0,0,0-9.71-8,.44.44,0,0,1-.11.12L6.11,255.14a6.29,6.29,0,0,0,1,8.83,6.39,6.39,0,0,0,3.84,1.38l77,1.17L64.48,297.4a5.39,5.39,0,0,0-.67,1.1L37.53,333.23a6.28,6.28,0,1,0,10,7.58L88.86,286.2a7.09,7.09,0,0,0,.45-.75l16.13-21.23A6.22,6.22,0,0,0,106.11,257.69Z"
          transform="translate(-4.73 -187.93)"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'SionLogo',
  props: {
    color: {
      type: String,
      default: 'green',
    },
    logo: {
      type: Boolean,
      default: true,
    },
    mark: {
      type: Boolean,
      default: true,
    },
    width: {
      type: Number,
      default: 100,
    },
  },
};
</script>

<style lang="scss" scoped>
#sion-logo {
  transition: fill 0.35s ease-in-out;
}
.is-blue {
  #sion-logo {
    fill: $blue;
  }
}
.is-green {
  #sion-logo {
    fill: $green;
  }
}
.is-sabre {
  #sion-logo {
    fill: #00ffff;
  }
}
.is-red {
  #sion-logo {
    fill: $red;
  }
}
.is-yellow {
  #sion-logo {
    fill: $yellow;
  }
}
.is-white {
  #sion-logo {
    fill: white;
  }
}
</style>
