import { sortBy, groupBy, isEqualArray, cloneDeep } from '@/utils/helpers/methods';
import Vue from 'vue';

const defaultDownload = {
  currency_convert: null,
  currency_multiple: true,
  email_copy: false,
  file_format: 'csv',
  group_by: null,
  group_by_order: 'desc',
  group_sort_by: '_key',
  group_sort_by_order: 'asc',
  skip_rows: false,
};

const defaultPreset = {};

const defaultSettings = {
  preset: {
    save: false,
    update: false,
  },
  recipient: {
    edited: false,
    new: false,
  },
  save: {
    download: false,
    version: false,
  },
};

const state = {
  columns: [],
  download: cloneDeep(defaultDownload),
  preset: cloneDeep(defaultPreset),
  presets: [],
  settings: cloneDeep(defaultSettings),
  sharedKeys: [
    'currency_convert',
    'currency_multiple',
    'date_format',
    'file_format',
    'group_by',
    'group_by_order',
    'header',
    'row_headers',
  ],
};

const mutations = {
  ADD_DOWNLOAD_PRESET_TO_PRESETS(state, payload) {
    const index = state.presets.findIndex((preset) => preset.id === payload.id);
    if (index < 0) state.presets.push(payload);
    else Vue.set(state.presets, index, payload);
  },
  MOVE_DOWNLOAD_COLUMN(state, payload) {
    const { columns } = state;

    const current = columns.find((columnItem) => columnItem.key === payload.current);
    const currentIndex = current.index;

    const target = columns.find((columnItem) => columnItem.key === payload.target);
    const targetIndex = target.index;

    Vue.set(current, 'index', targetIndex);
    Vue.set(target, 'index', currentIndex);
  },
  RESET_DOWNLOADS(state, payload) {
    switch (payload) {
      case 'download':
        state.download = cloneDeep(defaultDownload);
        break;
      case 'download_columns':
        state.columns.forEach((column, index) => {
          Vue.set(column, 'index', index);
          Vue.set(column, 'selected', false);
        });
        break;
      case 'preset':
        state.preset = cloneDeep(defaultPreset);
        state.settings = cloneDeep(defaultSettings);
        break;
      case 'settings':
        state.settings = cloneDeep(defaultSettings);
        break;
      default:
    }
  },
  SET_DOWNLOADS_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
  SET_DOWNLOAD_COLUMNS_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state.columns[payload.index];
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
};

const getters = {
  download: (state) => state.download,
  downloadColumns: (state) => state.columns,
  downloadColumnsGrouped: ({ columns }) => groupBy(columns, (column) => column.group_label),
  downloadColumnsSelected: ({ columns }) => columns.filter(({ selected }) => selected === true),
  downloadColumnsSorted(_state, { downloadColumnsSelected }) {
    return sortBy(downloadColumnsSelected, (column) => column.index);
  },
  downloadPreset: (state) => state.preset,
  downloadPresetEdited(_state, getter) {
    const { download, downloadSharedKeys, downloadPreset, downloadColumnsSorted } = getter;

    if (downloadPreset) {
      let downloadDifferences = 0;
      if (download) {
        downloadSharedKeys.forEach((key) => {
          if (key !== 'currency_convert') {
            const downloadVal = download[key];
            const presetVal = downloadPreset[key];
            if (downloadVal !== presetVal) {
              downloadDifferences += 1;
            }
          }
        });
      }

      if (downloadDifferences > 0) {
        return true;
      }

      if (downloadColumnsSorted) {
        if (downloadColumnsSorted.length !== downloadPreset._embedded.columns.length) {
          return true;
        }
        const columnKeys = downloadColumnsSorted.map((column) => column.key);
        const presetKeys = downloadPreset._embedded.columns.map((presetColumn) => presetColumn.key);
        if (!isEqualArray(columnKeys, presetKeys)) {
          return true;
        }
      }
    }
    return false;
  },
  downloadPresetStatementDefault(_state, { downloadPresets }) {
    // TODO company specific
    return downloadPresets.find(({ key }) => key === 'statement_pdf');
  },
  downloadPresets: (state) => state.presets,
  downloadSettings: (state) => state.settings,
  downloadSharedKeys: (state) => state.sharedKeys,
};

const actions = {
  async doCreateDownload({ commit }, payload) {
    const { data } = await this._vm.$api.downloads.create(payload);
    commit('SET_DOWNLOADS_ATOMIC', { key: 'download', val: data.downloads[0] });
  },
  async doUpdateDownload({ commit }, payload) {
    const { data } = await this._vm.$api.downloads.update(payload);
    commit('SET_DOWNLOADS_ATOMIC', { key: 'download', val: data.downloads[0] });
  },
  async doGetDownload({ commit }, payload) {
    const { data } = await this._vm.$api.downloads.get(payload);
    commit('SET_DOWNLOADS_ATOMIC', { key: 'download', val: data.downloads[0] });
  },
  async doDeleteDownload({ commit }, payload) {
    const { data } = await this._vm.$api.downloads.delete(payload);
    commit('SET_DOWNLOADS_ATOMIC', { key: 'download', val: data.downloads[0] });
  },

  async doCreateDownloadPreset({ commit }, payload) {
    const { data } = await this._vm.$api.downloads.createPreset(payload);
    const [preset] = data.download_presets;
    if (!preset) return;
    commit('SET_DOWNLOADS_ATOMIC', { key: 'preset', val: preset });
    commit('ADD_DOWNLOAD_PRESET_TO_PRESETS', preset)
  },
  async doUpdateDownloadPreset({ commit }, payload) {
    const { data } = await this._vm.$api.downloads.updatePreset(payload);
    const [preset] = data.download_presets;
    if (!preset) return;
    commit('SET_DOWNLOADS_ATOMIC', { key: 'preset', val: preset });
    commit('ADD_DOWNLOAD_PRESET_TO_PRESETS', preset)
  },
  async doGetDownloadPresets({ commit }, payload) {
    const { data } = await this._vm.$api.downloads.getPresets(payload);
    commit('SET_DOWNLOADS_ATOMIC', { key: 'presets', val: data.download_presets })
  },
  async doGetDownloadColumns({ commit }, payload) {
    const { data } = await this._vm.$api.downloads.getColumns(payload);
    const mapped = data.column_definitions.map((column, index) => {
      column.align = 'left';
      column.index = index;
      column.selected = false;
      column.style = 'light';
      return column;
    });
    commit('SET_DOWNLOADS_ATOMIC', { key: 'columns', val: mapped });
  },
  async doGetDownloadForInvoice({ commit }, payload) {
    const { data } = await this._vm.$api.invoices.getDownload(payload);
    commit('SET_DOWNLOADS_ATOMIC', { key: 'download', val: data.downloads[0] });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
