import Vue from 'vue';
import { HTTP } from '@/utils/http';

const state = {
  branch: {},
  whitelist: [],
};

const mutations = {
  SET_SOURCE_BRANCHES_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
};

const getters = {
  sourceBranch: (state) => state.branch, // used ?
  sourceBranchWhitelist: (state) => state.whitelist,
};

const actions = {
  async doCreateSourceBranch({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.post(`/source_connections/${id}/branches`, params);
    if (!ignore)
      commit('SET_SOURCE_BRANCHES_ATOMIC', { key: 'branch', val: data.data.branches[0] });
    return data;
  },
  async doGetSourceBranchWhitelist({ commit }, payload) {
    const { ignore, ...params } = payload;
    const { data } = await HTTP.get('/source_branches/_whitelist', { params });
    if (!ignore)
      commit('SET_SOURCE_BRANCHES_ATOMIC', { key: 'whitelist', val: data.data.whitelist });
  },
  async doUpdateSourceBranch({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.patch(`/source_branches/${id}`, params);
    const [branch] = data.data.source_branches;
    if (!ignore) commit('SET_SOURCE_BRANCHES_ATOMIC', { key: 'branch', val: branch });
    return data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
