import { HTTP } from '@/utils/http';

const state = {
  column: {},
  columns: [],
  mapping: {},
};

const mutations = {
  SET_IMPORT_COLUMN(state, payload) {
    state.column = payload;
  },
  SET_IMPORT_COLUMN_MAPPING(state, payload) {
    state.mapping = payload;
  },
  SET_IMPORT_COLUMNS(state, payload) {
    state.columns = payload;
  },
};

const getters = {
  importColumns: (state) => state.columns,
};

const actions = {
  async doCreateImportColumn({ commit }, payload) {
    // used?
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.post(`/companies/${id}/import_columns`, params);
    if (!ignore) commit('SET_IMPORT_COLUMN', data.data.import_columns[0]);
    return data;
  },
  async doCreateImportColumnMapping({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.post(`/import_columns/${id}/mappings`, params);
    if (!ignore) commit('SET_IMPORT_COLUMN_MAPPING', data.data.mappings[0]);
    return data;
  },
  async doGetImportColumns({ commit }, params) {
    const { data } = await HTTP.get('/import_columns', { params });
    commit('SET_IMPORT_COLUMNS', data.data.import_columns);
    return data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
