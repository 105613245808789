// Since cardinality is not precise, use a scripted metric to count docs with the provided field; see https://pratik-patil.medium.com/accurate-distinct-count-and-values-from-elasticsearch-dadce51ad4a7
export const preciseCardinality = (field) => {
  const query = {
    scripted_metric: {
      params: {
        field,
      },
      init_script: 'state.list = []',
      map_script: 'if(doc[params.field] != null) state.list.add(doc[params.field].value);',
      combine_script: 'return state.list;',
      reduce_script: `
        Map uniqueValueMap = new HashMap(); 
        int count = 0;
        for(shardList in states) {
          if(shardList != null) { 
            for(key in shardList) {
              if(!uniqueValueMap.containsKey(key)) {
                count +=1;
                uniqueValueMap.put(key, key);
              }
            }
          }
        } 
        return count;
      `,
    },
  };
  return query;
};
