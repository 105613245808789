import Vue from 'vue';
import { cloneDeep } from '@/utils/helpers/methods';
import { HTTP } from '@/utils/http';

const defaultInvoiceTemplate = {
  bcc_addresses: [],
  cc_addresses: [],
  client: {
    enabled: true,
    message: null,
    subject: null,
  },
  supplier: {
    enabled: true,
    message: null,
    subject: null,
  },
};

const state = {
  checked: false,
  invoiceTemplate: cloneDeep(defaultInvoiceTemplate),
  invoiceTemplateEditing: cloneDeep(defaultInvoiceTemplate),
};

const mutations = {
  ADD_INVOICE_TEMPLATE_EMAIL(state, payload) {
    state.invoiceTemplateEditing[payload.key].push(payload.val);
  },
  REMOVE_INVOICE_TEMPLATE_EMAIL(state, payload) {
    const index = state.invoiceTemplateEditing[payload.key].indexOf(payload.val);
    if (index >= 0) {
      state.invoiceTemplateEditing[payload.key].splice(index, 1);
    }
  },
  SET_INVOICE_TEMPLATES_ATOMIC(state, { key, val }) {
    const nodes = key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, val);
      } else {
        obj = obj[node];
      }
    });
  },
};

const getters = {
  invoiceTemplate: (state) => state.invoiceTemplate,
  invoiceTemplateChecked: (state) => state.checked,
  invoiceTemplateEditing: (state) => state.invoiceTemplateEditing,
};

const actions = {
  async doCreateInvoiceTemplate({ commit }, payload) {
    const { ignore, ...params } = payload;
    const { data } = await HTTP.post('/invoice_templates/', params);
    if (!ignore) {
      const [val] = data.data.invoice_templates;
      commit('SET_INVOICE_TEMPLATES_ATOMIC', { key: 'invoiceTemplate', val });
    }
    return data;
  },
  async doGetInvoiceTemplate({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.get(`/companies/${id}/invoice_template`, { params });
    if (!ignore) {
      const [val] = data.data.invoice_templates;
      if (val) {
        commit('SET_INVOICE_TEMPLATES_ATOMIC', { key: 'invoiceTemplate', val });
      }
    }
    return data;
  },
  async doUpdateInvoiceTemplate({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.patch(`/invoice_templates/${id}`, params);
    if (!ignore) {
      const [val] = data.data.invoice_templates;
      commit('SET_INVOICE_TEMPLATES_ATOMIC', { key: 'invoiceTemplate', val });
    }
    return data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
