const state = {
  relationships: [],
};

const mutations = {
  SET_CLIENT_RELATIONSHIPS(state, payload) {
    state.relationships = payload;
  },
};

const getters = {
  clientRelationships: (state) => state.relationships,
};

const actions = {
  async doGetClientRelationships({ commit }, payload) {
    const { data } = await this._vm.$api.clients.getRelationships(payload);
    commit('SET_CLIENT_RELATIONSHIPS', data.relationships);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
