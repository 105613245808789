import { apiClient } from '../apiClient';

const DOMAIN = 'users';

export default {
  create: (payload) => apiClient.post(`/${DOMAIN}`, payload),
  get: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}`, { params }),
  update: ({ id, ...payload }) => apiClient.patch(`/${DOMAIN}/${id}`, payload),
  getSelf: (params) => apiClient.get('/self', { params }),
  getAll: (payload) => apiClient.get(`/${DOMAIN}`, payload),

  getAllPublic: (params) => apiClient.get(`/${DOMAIN}_public`, { params }),
  resetPassword: (payload) => apiClient.post(`/${DOMAIN}_public/_reset_password`, payload),
  requestReset: (payload) => apiClient.post(`/${DOMAIN}_public/_request_reset`, payload),

  createToken: (payload) => apiClient.post('/tokens', payload)
};
