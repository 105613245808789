import Vue from 'vue';
import { cloneDeep } from '@/utils/helpers/methods';

const defaultImportantDate = {
  date: 1,
  month: 1,
  type: 'other',
};

const state = {
  date: cloneDeep(defaultImportantDate),
  dates: [],
};

const mutations = {
  ADD_IMPORTANT_DATE(state, payload) {
    state.dates.push(payload);
  },
  REMOVE_IMPORTANT_DATE_AT(state, index) {
    state.dates.splice(index, 1);
  },
  RESET_IMPORTANT_DATES(state, payload) {
    switch (payload) {
      case 'date':
        state.date = cloneDeep(defaultImportantDate);
        break;
      case 'dates':
        state.dates = [];
        break;
      default:
    }
  },
  SET_IMPORTANT_DATE_AT(state, { index, date }) {
    state.dates.splice(index, 1, date);
  },
  SET_IMPORTANT_DATES_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
};

const getters = {
  importantDate: (state) => state.date,
  importantDates: (state) => state.dates,
};

const actions = {
  async doCreateImportantDate({ commit }, payload) {
    const { data } = await this._vm.$api.dates.create(payload);
    const [date] = data.important_dates;
    commit('SET_IMPORTANT_DATES_ATOMIC', { key: 'date', val: date });
    commit('ADD_IMPORTANT_DATE', date)
  },
  // eslint-disable-next-line no-shadow
  async doUpdateImportantDate({ commit, getters }, payload) {
    const { data } = await this._vm.$api.dates.update(payload);
    const [date] = data.important_dates;
    commit('SET_IMPORTANT_DATES_ATOMIC', { key: 'date', val: date });
    const index = getters.importantDates.findIndex(({id}) => id === payload.id);
    if (index >= 0) commit('SET_IMPORTANT_DATE_AT', { index, date });
          
  },
  // eslint-disable-next-line no-shadow
  async doDeleteImportantDate({ commit, getters }, payload) {
    const { data } = await this._vm.$api.dates.delete(payload);
    commit('SET_IMPORTANT_DATES_ATOMIC', { key: 'date', val: data.important_dates[0] });
    const index = getters.importantDates.findIndex(({ id }) => id === payload.id);
    if (index >= 0) commit('REMOVE_IMPORTANT_DATE_AT', index);
    
  },
  async doGetImportantDate({ commit }, payload) {
    const { data } = await this._vm.$api.dates.get(payload);
    commit('SET_IMPORTANT_DATES_ATOMIC', { key: 'date', val: data.important_dates[0] });
  },
  async doGetImportantDates({ commit }, payload) {
    const { data } = await this._vm.$api.dates.getAll(payload);
    commit('SET_IMPORTANT_DATES_ATOMIC', { key: 'dates', val: data.important_dates });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
