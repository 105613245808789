import { apiClient } from '../apiClient';

const DOMAIN = 'employees';

export default {
  create: ({ id, ...payload }) => apiClient.post(`/companies/${id}/${DOMAIN}`, payload),
  get: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}`, { params }),
  update: ({ id, ...payload }) => apiClient.patch(`/${DOMAIN}/${id}`, payload),
  delete: ({ id, ...payload }) => apiClient.delete(`/${DOMAIN}/${id}`, payload),
  getAll: (params) => apiClient.get(`/${DOMAIN}`, { params }),
  search: (payload) => apiClient.post(`/sion_${DOMAIN}/_search`, payload),

  createSignature: ({ id, ...payload }) => apiClient.post(`/${DOMAIN}/${id}/signature`, payload),
  updateSignature: ({ id, ...payload }) => apiClient.patch(`/signatures/${id}`, payload)
};