import Vue from 'vue';
import { cloneDeep } from '@/utils/helpers/methods';
import { HTTP } from '@/utils/http';

const defaultAdjustment = {
  amount: 0,
  quantity: 1,
  type: 'credit',
};

const state = {
  adjustment: cloneDeep(defaultAdjustment),
  adjustments: [],
};

const mutations = {
  RESET_STATEMENT_ADJUSTMENTS(state, payload) {
    switch (payload) {
      case 'adjustment':
        state.adjustment = cloneDeep(defaultAdjustment);
        break;
      default:
    }
  },
  SET_STATEMENT_ADJUSTMENTS_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
};

const getters = {
  statementAdjustment: (state) => state.adjustment,
  statementAdjustments: (state) => state.adjustments, // used?
};

const actions = {
  async doCreateStatementAdjustment({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.post(`/statements/${id}/adjustments`, params);
    if (!ignore) {
      commit('SET_STATEMENT_ADJUSTMENTS_ATOMIC', {
        key: 'adjustments',
        val: data.data.adjustments,
      });
    }
    return data;
  },
  async doDeleteStatementAdjustment({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const adjustmentId = payload.adjustment_id;
    delete payload.adjustment_id;
    const { data } = await HTTP.delete(`/statements/${id}/adjustments/${adjustmentId}`, params);
    if (!ignore) {
      const [adjustment] = data.data.adjustments;
      commit('SET_STATEMENT_ADJUSTMENTS_ATOMIC', { key: 'adjustment', val: adjustment });
    }
    return data;
  },
  async doGetStatementAdjustment({ commit }, payload) {
    // used?
    const { id, ignore, ...params } = payload;
    const adjustmentId = payload.adjustment_id;
    delete payload.adjustment_id;
    const { data } = await HTTP.get(`/statements/${id}/adjustments/${adjustmentId}`, { params });
    if (!ignore) {
      const [adjustment] = data.data.adjustments;
      commit('SET_STATEMENT_ADJUSTMENTS_ATOMIC', { key: 'adjustment', val: adjustment });
    }
    return data;
  },
  async doUpdateStatementAdjustment({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const adjustmentId = payload.adjustment_id;
    delete payload.adjustment_id;
    const { data } = await HTTP.patch(`/statements/${id}/adjustments/${adjustmentId}`, params);
    if (!ignore) {
      const [adjustment] = data.data.adjustments;
      commit('SET_STATEMENT_ADJUSTMENTS_ATOMIC', { key: 'adjustment', val: adjustment });
    }
    return data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
