import Vue from 'vue';
import { cloneDeep } from '@/utils/helpers/methods';

const defaultIATAPlan = {
  iata: {
    type: 'iata',
  },
  plan: {},
  _embedded: {},
};

const state = {
  iataPlan: cloneDeep(defaultIATAPlan),
  iataPlans: [],
  pagination:{
    page: 1,
    per: 10,
    total: 0
  },
  refresh: false
};

const mutations = {
  SET_IATA_PLAN(state, payload) {
    state.iataPlan = payload;
  },
  SET_IATA_PLAN_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
  SET_IATA_PLAN_DEFAULT(state) {
    state.iataPlan = cloneDeep(defaultIATAPlan);
  },
  SET_IATA_PLAN_FIELD(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state.iataPlan;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
  SET_IATA_PLANS(state, payload) {
    state.iataPlans = payload;
  },
  SET_IATA_PLANS_PAGINATION(state, payload) {
    state.pagination  = payload;
  },
  SET_IATA_PLANS_REFRESH(state, payload) {
    state.refresh = payload;
  }
};

const getters = {
  iataPlan: (state) => state.iataPlan,
  iataPlans: (state) => state.iataPlans,
  pagination: (state) => state.pagination,
  refresh: (state) => state.refresh
};

const actions = {
  async doCreateIataPlan({ commit }, payload) {
    const { data } = await this._vm.$api.iatas.createPlan(payload);
    commit('SET_IATA_PLAN', data.iata_plans[0]);
  },
  async doGetIataPlan({ commit }, payload) {
    const { data } = await this._vm.$api.iatas.getPlan(payload);
    commit('SET_IATA_PLAN', data.iata_plans[0]);
  },
  async doGetIataPlans({ commit }, payload) {
    const { data, pagination, record_count: total } = await this._vm.$api.iatas.getPlans(payload);
    const { iata_plans: plans } = data;
    const { per_page: per, page } = pagination;
    commit('SET_IATA_PLANS', plans);
    commit('SET_IATA_PLANS_PAGINATION', { page, per, total });
  },
  async doUpdateIataPlan({ commit }, payload) {
    const { data } = await this._vm.$api.iatas.updatePlan(payload);
    commit('SET_IATA_PLAN', data.iata_plans[0]);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
