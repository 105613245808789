import { apiClient } from '../apiClient';

const DOMAIN = 'clients';

export default {
  create: (payload) => apiClient.post(`/${DOMAIN}`, payload),
  get: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}`, { params }),
  update: ({ id, ...payload }) => apiClient.patch(`/${DOMAIN}/${id}`, payload),
  delete: ({ id, ...payload }) => apiClient.delete(`/${DOMAIN}/${id}`, payload),
  getAll: (params) => apiClient.get(`/${DOMAIN}`, { params }),
  search: (payload) => apiClient.post(`/sion_${DOMAIN}/_search`, payload),

  createRelationship: ({ id, ...payload }) => apiClient.post(`/client_relationships/${id}`, payload),
  updateRelationship: ({ id, ...payload }) => apiClient.patch(`/client_relationships/${id}`, payload),
  getRelationship: ({ id, ...params }) => apiClient.get(`/client_relationships/${id}`, { params }),
  getRelationships: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}/relationships`, { params }),

  createBillingProfile: ({ id, ...payload }) => apiClient.post(`/${DOMAIN}/${id}/billing_profiles`, payload),
  getBillingProfile: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}/billing_profiles`, { params }),

  createAttachment: ({ id, ...payload }) => apiClient.post(`/${DOMAIN}/${id}/attachments`, payload),
  getAttachments: ({ id }) => apiClient.get(`/${DOMAIN}/${id}/attachments`),
};
