const state = {
  categories: [],
};

const mutations = {
  SET_BOOKING_CATEGORIES(state, payload) {
    state.categories = payload;
  },
};

const getters = {
  bookingCategories: (state) => state.categories,
};

const actions = {
  async doGetBookingCategories({ commit }) {
    const { data } = await this._vm.$api.bookings.getCategories();
    commit('SET_BOOKING_CATEGORIES', data.booking_categories);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
