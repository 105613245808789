import Vue from 'vue';
import { SEARCH } from '@/utils/http';

const state = {
  actions: [],
};

const mutations = {
  SET_ACCOUNT_ACTIONS_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
};

const getters = {
  accountActions: (state) => state.actions,
};

const actions = {
  async doGetAccountActions({ commit }, payload) {
    const { ignore, ...params } = payload;
    const { data } = await SEARCH.post('/sion_actions/_search', params);
    if (!ignore) {
      const { hits } = data.hits;
      const appActions = hits.map((hitsItem) => hitsItem._source);
      commit('SET_ACCOUNT_ACTIONS_ATOMIC', { key: 'actions', val: appActions });
    }
    return data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
