import { apiClient } from '../apiClient';

const DOMAIN = 'claims';

export default {
  create: ({ id, ...payload }) => apiClient.post(`/unmatched_payments/${id}/${DOMAIN}`, payload),
  get: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}`, { params }),
  update: ({ id, ...payload }) => apiClient.patch(`/${DOMAIN}/${id}`, payload),
  delete: ({ id }) => apiClient.delete(`/${DOMAIN}/${id}`),
  getAll: ({ id, ...params }) => apiClient.get(`/unmatched_payments/${id}/${DOMAIN}`, { params })
};
