import Vue from 'vue';
import { cloneDeep } from '@/utils/helpers/methods';
import { HTTP, SEARCH } from '@/utils/http';

const queryFields = [
  { key: '_embedded.client.full_name', label: 'Client Name' },
  { key: '_embedded.supplier.name', label: 'Supplier' },
];

const defaultAggs = {
  auto: {
    doc_count: 0,
    unmatched_amount: {
      value: 0,
    },
  },
  matched: {
    doc_count: 0,
    matched_amount: {
      value: 0,
    },
  },
  total_amount: {
    value: 0,
  },
  total_entries: {
    value: 0,
  },
  unmatched: {
    doc_count: 0,
    unmatched_amount: {
      value: 0,
    },
  },
};
const defaultBatchEntry = {
  skip: false,
};

const defaultFilters = {
  aggs: false,
  aggsOnly: false,
  amounts: {
    min: 0,
    max: 0,
  },
  dates: {
    min: null,
    max: null,
  },
  includeDates: false,
  ignoreZero: false,
  query: null,
  queryFields: queryFields.map((queryField) => queryField.key),
  sort: { sort_order: 'asc' },
  status: null,
};

const defaultSettings = {
  expanded: true,
  height: 0,
  search: {
    bookings: false,
    payments: false,
    settings: false,
    proximity: false,
  },
  map: false,
};

const state = {
  aggs: cloneDeep(defaultAggs),
  editing: {},
  entry: cloneDeep(defaultBatchEntry),
  entries: [],
  filters: cloneDeep(defaultFilters),
  matched: {},
  pagination: {
    page: 1,
    per: 40,
    total: 0,
  },
  refresh: false,
  settings: cloneDeep(defaultSettings),
  sync: null,
};

const mutations = {
  APPEND_BATCH_ENTRIES(state, payload) {
    state.entries = state.entries.concat(payload);
  },
  REMOVE_BATCH_ENTRY_AT_INDEX(state, payload) {
    state.entries.splice(payload.index, 1);
  },
  SET_BATCH_ENTRY(state, payload) {
    state.entry = payload;
  },
  SET_BATCH_ENTRY_AT_INDEX(state, payload) {
    Vue.set(state.entries, payload.index, payload.val);
  },
  SET_BATCH_ENTRIES(state, payload) {
    state.entries = payload;
  },
  SET_BATCH_ENTRIES_AGGREGATIONS(state, payload) {
    state.aggs = payload;
  },
  SET_BATCH_ENTRIES_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
  UNSET_BATCH_ENTRY(state) {
    state.entry = cloneDeep(defaultBatchEntry);
  },
  UNSET_BATCH_ENTRY_AGGREGATIONS(state) {
    state.aggs = cloneDeep(defaultAggs);
  },
  UNSET_BATCH_ENTRY_FILTERS(state) {
    state.filters = cloneDeep(defaultFilters);
  },
};

const getters = {
  batchEntry: (state) => state.entry,
  batchEntries: (state) => state.entries,
  batchEntriesAggregations: (state) => state.aggs,
  batchEntriesEsQuery(state, getter, rootState) {
    const { filters, pagination } = state;
    const { batch } = rootState.batches;
    const from = pagination.per * (pagination.page - 1);

    const [filterKey] = Object.keys(filters.sort);
    const sort = [];
    const field = filterKey.match(/name|confirmation_number/i) ? `${filterKey}.raw` : filterKey;
    const obj = {};
    obj[field] = { order: filters.sort[filterKey] };
    sort.push(obj);
    if (filterKey !== 'payment_amount') {
      sort.push({ payment_amount: { order: 'desc' } });
    }
    const payload = {
      aggs: {
        auto: {
          filter: {
            bool: {
              must: [{ match: { status: 'matched' } }, { match: { match_method: 'auto' } }],
            },
          },
          aggs: {
            unmatched_amount: {
              sum: {
                field: 'payment_amount',
              },
            },
          },
        },
        matched: {
          filter: {
            bool: {
              must: [{ match: { status: 'matched' } }],
            },
          },
          aggs: {
            matched_amount: {
              sum: {
                field: 'payment_amount',
              },
            },
          },
        },
        total_amount: {
          sum: {
            field: 'payment_amount',
          },
        },
        total_entries: {
          cardinality: {
            field: 'id',
          },
        },
        unmatched: {
          filter: {
            bool: {
              must: [{ match: { status: 'unmatched' } }],
            },
          },
          aggs: {
            unmatched_amount: {
              sum: {
                field: 'payment_amount',
              },
            },
          },
        },
      },
      from,
      query: {
        bool: {
          must: [{ match: { batch_id: batch.id } }],
        },
      },
      size: pagination.per,
      sort,
    };
    if (filters.aggsOnly) {
      payload.size = 0;
    } else {
      if (filters.amounts) {
        const { min, max } = filters.amounts;
        payload.query.bool.must.push({ range: { payment_amount: { gte: min, lte: max } } });
      }
      if (filters.dates && filters.includeDates) {
        const { min, max } = filters.dates;
        if (min && max) {
          payload.query.bool.must.push({ range: { check_in: { gte: min, lte: max } } });
        }
      }
      if (filters.ignoreZero) {
        payload.query.bool.must.push({ range: { payment_amount: { gt: 0 } } });
      }
      if (filters.status) {
        payload.query.bool.must.push({ match: { status: filters.status } });
      }
      if (filters.query && filters.aggs) {
        const query = {
          multi_match: {
            query: filters.query,
            fields: [
              'client_first_name',
              'client_last_name',
              'commission_est.text',
              'confirmation_number',
              'payment_amount.text',
              'revenue.text',
              'sort_order.text',
              'supplier_name',
            ],
          },
        };
        payload.query.bool.must.push(query);
      }
    }
    return payload;
  },
  batchEntriesFilters: (state) => state.filters,
  batchEntriesMatchedBooking: (state) => state.matched,
  batchEntriesPagination: (state) => state.pagination,
  batchEntriesRefresh: (state) => state.refresh,
  batchEntriesSettings: (state) => state.settings,
  batchEntriesSync: (state) => state.sync, // used?
  batchEntryEditing: (state) => state.editing,
};

const actions = {
  async doGetBatchEntry({ commit }, payload) {
    const { id, ...params } = payload;
    const entryId = params.entry_id;
    delete params.entry_id;
    const { data } = await HTTP.get(`/batches/${id}/entries/${entryId}`, { params });
    commit('SET_BATCH_ENTRY', data.data.entries[0]);
    return data;
  },
  async doGetBatchEntries({ commit }, payload) {
    // used?
    const { id, ...params } = payload;
    const { data } = await HTTP.get(`/batches/${id}/entries`, { params });
    commit('SET_BATCH_ENTRIES', data.data.entries);
    return data;
  },
  async doSearchBatchEntries({ commit }, payload) {
    const { ignore, method, ...params } = payload;
    const mutation = method === 'append' ? 'APPEND_BATCH_ENTRIES' : 'SET_BATCH_ENTRIES';
    const { data } = await SEARCH.post('/sion_batch_entries/_search', params);
    const batches = data.hits.hits.map((hit) => hit._source);
    if (!ignore) commit(mutation, batches);
    return data;
  },
  async doUpdateBatchEntry({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const entryId = params.entry_id;
    delete params.entry_id;
    const { data } = await HTTP.patch(`/batches/${id}/entries/${entryId}`, params);
    if (!ignore) commit('SET_BATCH_ENTRY', data.data.entries[0]);
    return data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
