import Vue from 'vue';
import { dayjs } from '@/plugins/dayjs';
import { dateFormatUTC, dateFormatYMD } from '@/utils/helpers/dates';
import { compact, cloneDeep } from '@/utils/helpers/methods';
import { formatPayment } from '@/utils/helpers/payment';
import { preciseCardinality } from '@/services/elastic';
import { HTTP, SEARCH } from '@/utils/http';
import { generatePlaceholderId } from '@/utils/tools';

const defaultAggs = {
  filtered: {
    doc_count: 0,
    amount_payable: {
      value: 0
    },
    cost: {
      value: 0
    },
    count: {
      value: 0
    },
    gross: {
      value: 0
    },
    net_fee: {
      value: 0
    },
    net_agent: {
      value: 0
    },
    net_contractor: {
      value: 0
    },
    net_supplier: {
      value: 0
    }
  }
};

const today = new Date();

const defaultPayment = {
  booking_id: null,
  created_at: today,
  date: today,
  fee_amount: 0,
  fee_percentage: 0,
  is_reversed: false,
  statement_date: today,
  type: 'check',
  _embedded: {
    booking: {},
    fees: [],
    statements: [],
    tags: {
      payment: []
    }
  }
};

const defaultSettings = {
  mode: 'BALANCE'
};

const state = {
  actions: [],
  aggs: cloneDeep(defaultAggs),
  bookingSplits: [],
  bookingSplitsQueue: null,
  dateFields: [
    { key: 'created_at', label: 'Created' },
    { key: 'date', label: 'Payment Date' },
    { key: '_embedded.statements.date', label: 'Statement Date' }
  ],
  deletedTags: [],
  editing: {},
  filters: {},
  min: 0,
  max: 0,
  refreshMinMax: false,
  pagination: {
    page: 0,
    per: 25,
    total: 0
  },
  payment: cloneDeep(defaultPayment),
  payments: [],
  queryFields: [
    { key: 'amount.text', label: 'Amount' },
    { key: '_embedded.booking.number.case_insensitive', label: 'Booking Sion Reference Number' },
    { key: '_embedded.client.full_name', label: 'Client Name' },
    { key: '_embedded.booking.commission_est.text', label: 'Commission Estimate' },
    { key: '_embedded.booking.confirmation_number', label: 'Confirmation Number' },
    { key: 'ref_number', label: 'Reference Number' },
    { key: '_embedded.booking.revenue.text', label: 'Revenue' },
    { key: 'number.case_insensitive', label: 'Sion Reference Number' },
    { key: '_embedded.supplier.name', label: 'Supplier' }
  ],
  refresh: false,
  sort: { created_at: 'desc' },
  settings: cloneDeep(defaultSettings),
  sync: false,
  types: [
    { key: 'onyx', label: 'Onyx' },
    { key: 'ihg', label: 'IHG' },
    { key: 'tacs', label: 'TACS' },
    { key: 'paymode', label: 'Paymode' },
    { key: 'wire', label: 'Wire' },
    { key: 'check', label: 'Check' },
    { key: 'credit', label: 'Credit Card' },
    { key: 'other', label: 'Other' }
  ]
};

const mutations = {
  ADD_PAYMENT_BOOKING_SPLIT(state, payload) {
    payload.key = generatePlaceholderId();
    state.bookingSplits.unshift(payload);
  },
  ADD_PAYMENT_FEE_TO_PAYMENT(state, payload) {
    state.editing._embedded.fees.push(payload);
  },
  REMOVE_PAYMENT_BOOKING_SPLIT(state, { index }) {
    state.bookingSplits.splice(index, 1);
  },
  REMOVE_PAYMENT_FEE_FROM_PAYMENT(state, index) {
    if (state.editing._embedded.fees[index] !== undefined) {
      state.editing._embedded.fees.splice(index, 1);
    }
  },
  RESET_PAYMENTS(state, payload) {
    switch (payload) {
      case 'aggs':
        state.aggs = cloneDeep(defaultAggs);
        break;
      case 'editing':
        state.editing = cloneDeep(defaultPayment);
        break;
      case 'payment':
        state.payment = cloneDeep(defaultPayment);
        break;
      case 'settings':
        state.settings = cloneDeep(defaultSettings);
        break;
      case 'splits':
        state.bookingSplits = [];
        break;
      default:
    }
  },
  SET_PAYMENT_FEE_ATOMIC_FOR_PAYMENT(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state.editing._embedded.fees[payload.index];
    let i = 0;
    nodes.forEach(node => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
  SET_PAYMENTS_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach(node => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
  SET_PAYMENT_BOOKING_SPLIT_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state.bookingSplits[payload.index];
    let i = 0;
    nodes.forEach(node => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
  SET_PAYMENTS(state, payments) {
    state.payments = payments;
  },
  TOGGLE_PAYMENT_TAG(state, { key, val }) {
    const { id } = val;
    const index = state[key]._embedded.tags.payment.findIndex(paymentTag => paymentTag.id === id);
    const deletedIndex = state.deletedTags.findIndex(deletedTag => deletedTag.id === id);
    if (index < 0) {
      state[key]._embedded.tags.payment.push(val);
      if (deletedIndex >= 0) {
        state.deletedTags.splice(deletedIndex, 1);
      }
    } else {
      state[key]._embedded.tags.payment.splice(index, 1);
      if (deletedIndex < 0) {
        state.deletedTags.push(val);
      }
    }
  }
};

const getters = {
  payment: state => state.payment,
  paymentEditing: state => state.editing,
  paymentActions: state => state.actions,
  paymentBookingSplits: state => state.bookingSplits,
  paymentBookingSplitsReconciled({ bookingSplits }) {
    // does this mean ids can be null?
    const reconciled = bookingSplits.filter(
      split => split.booking.id !== undefined
    );
    return reconciled;
  },
  paymentBookingSplitsQueue: state => state.bookingSplitsQueue,
  paymentBookingSplitsUnreconciled({ bookingSplits }) {
    // what if null?
    const unreconciled = bookingSplits.filter(
      split => split.booking.id === undefined
    );
    return unreconciled;
  },
  paymentDeletedTags: state => state.deletedTags,
  paymentSettings: state => state.settings,
  paymentSync: state => state.sync,
  paymentTypes: state => state.types,
  payments: state => state.payments,
  paymentsAggregations: state => state.aggs,
  paymentsAmountMax: state => state.max, // used?
  paymentsMin: state => state.min, // used?
  paymentsDateFields: state => state.dateFields,
  paymentsPagination: state => state.pagination,
  paymentsEsQuery: (state, getters, _rootState, rootGetters) => ({ allowAgentFilters = true }) => {
    const company = rootGetters['companies/company'];
    const companyTimeZoneOffset = company.time_zone.offset;
    const contractors = rootGetters['companies/companyContractorsNoAgent'];
    const filters = rootGetters['commissions/commissionsFilters'];
    const employee = rootGetters['employees/currentEmployee'];
    const { paymentsSort, paymentsQueryFields } = getters;
    const reporting = rootGetters['commissions/commissionsReporting'];
    const from = state.pagination.per * (state.pagination.page - 1);

    let dateFormat = dateFormatYMD;
    let toDate = null;
    let fromDate = null;
    if (filters.fields.date === 'created_at') {
      dateFormat = dateFormatUTC;
      fromDate = dayjs(filters.dates.from, dateFormat)
      .utcOffset(companyTimeZoneOffset)
      .startOf('day')
      .format(dateFormat);
      toDate = dayjs(filters.dates.to, dateFormat)
      .utcOffset(companyTimeZoneOffset)
      .endOf('day')
      .format(dateFormat);
    } else {
      fromDate = dayjs(filters.dates.from, dateFormat).format(dateFormat);
      toDate = dayjs(filters.dates.to, dateFormat).format(dateFormat);
    }

    const sort = [];
    let filterKey = null;
    if (paymentsSort) {
      [filterKey] = Object.keys(paymentsSort);
      const field = filterKey.match(/name|last_first/i)
        ? `${filterKey}.raw`
        : filterKey;
      const obj = {};
      obj[field] = { order: paymentsSort[filterKey] };
      sort.push(obj);
    }
    sort.push({ '_embedded.batch_entry.sort_order': { order: 'asc' } });
    if (filterKey !== 'amount') {
      sort.push({ amount: { order: 'desc' } });
    }
    // To do: More descriptive name for this object
    const filterObject = {
      bool: {
        must: [{ exists: { field: '_embedded.booking' } }],
        must_not: [],
        should: []
      }
    };
    const embeddedTerms = [{ key: 'iata', val: filters.iatas }];
    if (!filters.statement) {
      embeddedTerms.push({ key: 'company', val: filters.companies });
      let dateQuery = null;
      const dateField = filters.fields.date;
      if (filters.dates.year === 'all') {
        dateQuery = { exists: { field: dateField } };
      } else if (fromDate && toDate && !filters.invoice_id) {
        dateQuery = { range: {} };
        dateQuery.range[dateField] = { gte: fromDate, lte: toDate };
      }
      if (dateQuery) {
        filterObject.bool.must.push(dateQuery);
      }
    }
    if (filters.sources.length > 0) {
      filterObject.bool.must.push({
        bool: {
          should: [
            {
              bool: {
                must_not: [{ exists: { field: 'source' } }]
              }
            },
            {
              bool: {
                must: [
                  { exists: { field: 'source' } },
                  { terms: { source: filters.sources } }
                ]
              }
            }
          ]
        }
      });
    }
    if (filters.statuses.payments.length > 0) {
      const terms = { status: filters.statuses.payments };
      filterObject.bool.must.push({ terms });
    }
    embeddedTerms.forEach(term => {
      if (term.val && term.val.length > 0) {
        const terms = {};
        terms[`_embedded.${term.key}.id`] = term.val;
        filterObject.bool.must.push({ terms });
      }
    });
    if (filters.clients.length > 0) {
      const ids = filters.clients.map(client => client.id);
      const terms = { '_embedded.client.id': ids };
      filterObject.bool.must.push({ terms });
    }
    if (filters.employees.length > 0 && allowAgentFilters) {
      const contractorIds = contractors.map(contractor => contractor.id);
      const altContractorIds = filters.companies.filter(id =>
        contractorIds.includes(id)
      );
      const agentIds = employee.access.company.bookings.read
        ? filters.employees
        : [employee.id];
      const employeeFilter = {
        bool: {
          should: [
            {
              bool: {
                must: [{ terms: { '_embedded.agent.id': agentIds } }]
              }
            }
          ]
        }
      };
      if (altContractorIds.length > 0) {
        employeeFilter.bool.should.push({
          bool: {
            must: [{ terms: { '_embedded.company.id': altContractorIds } }]
          }
        });
      }
      filterObject.bool.must.push(employeeFilter);
    }
    if (filters.invoices.length > 0) {
      const invoiceTypeFilter = {
        bool: {
          should: [
            {
              bool: {
                must: [
                  { exists: { field: '_embedded.booking.invoice_type' } },
                  {
                    terms: {
                      '_embedded.booking.invoice_type': filters.invoices
                    }
                  }
                ]
              }
            },
            {
              bool: {
                must_not: [
                  { exists: { field: '_embedded.booking.invoice_type' } }
                ]
              }
            }
          ]
        }
      };
      filterObject.bool.must.push(invoiceTypeFilter);
    }
    if (filters.suppliers.length > 0) {
      const ids = compact(filters.suppliers.map(supplier => supplier.id));
      const names = compact(filters.suppliers.map(supplier => supplier.name));
      const placeIds = compact(
        filters.suppliers.map(supplier => supplier.google_place_id)
      );
      const supplierQuery = {
        bool: {
          should: [{ terms: { '_embedded.supplier.id': ids } }]
        }
      };
      if (placeIds.length > 0) {
        supplierQuery.bool.should.push({
          terms: { '_embedded.supplier.google_place_id': placeIds }
        });
      }
      if (names.length > 0) {
        supplierQuery.bool.should.push({
          terms: { '_embedded.supplier.name.raw': names }
        });
      }
      filterObject.bool.must.push(supplierQuery);
    }
    if (filters.query) {
      filterObject.bool.must.push({
        multi_match: {
          query: filters.query,
          fields: paymentsQueryFields.map(({ key }) => key),
          type: 'cross_fields',
          operator: 'and'
        }
      });
    }
    if (filters.queryStrings && filters.queryStrings.length > 0) {
      const queryStringsQuery = {
        bool: {
          should: []
        }
      };
      filters.queryStrings.forEach(query => {
        if (query) {
          queryStringsQuery.bool.should.push({
            multi_match: {
              query: query.toUpperCase(),
              fields: filters.fields.query,
              type: 'cross_fields',
              operator: 'and',
              analyzer: 'standard'
            }
          });
        }
      });
      filterObject.bool.must.push(queryStringsQuery);
    }
    if (filters.tags.length > 0) {
      filters.tags.forEach(tag => {
        filterObject.bool.must.push({
          term: { tags: tag }
        });
      });
    }
    if (filters.types && filters.types.length > 0) {
      const terms = { type: filters.types };
      filterObject.bool.must.push({ terms });
    }
    if (filters.batches && filters.batches.length > 0) {
      const ids = filters.batches.map(batch => batch.id);
      const terms = { batch_id: ids };
      filterObject.bool.must.push({ terms });
    }
    const mapParams = {
      company: {
        id: company.id
      },
      view: reporting.view
    };

    const grossScript = `double reversedMultiplier = doc['is_reversed'].value ? -1 : 1;
      double gross = doc['amount'].value;
      state.payments.add(gross * reversedMultiplier)`;

    const netFeeScript = `double reversedMultiplier = doc['is_reversed'].value ? -1 : 1;
      double gross = doc['amount'].value;
      double fees = doc['fee_total'].size() > 0 ? doc['fee_total'].value : 0;
      state.payments.add(gross - fees);`;

    const netSupplierScript = `double reversedMultiplier = doc['is_reversed'].value ? -1 : 1;
      double gross = doc['amount'].value;
      double fees = doc['fee_total'].size() > 0 ? doc['fee_total'].value : 0;
      double supplierCost = doc['_embedded.booking.supplier_cost'].size() > 0 ? doc['_embedded.booking.supplier_cost'].value : 0;
      double netSupplier = gross - (fees + supplierCost);
      state.payments.add(netSupplier * reversedMultiplier);`;

    const netContractorScript = `double reversedMultiplier = doc['is_reversed'].value ? -1 : 1;
      def agency = doc['_embedded.iata._embedded.company.id'].value;
      double agencyCommissionRate = 100.0;
      if (agency != doc['_embedded.company.id'].value) {
        boolean isContractor = agency != params.company.id;
        double agencySplit = doc['commission_split'].value;
        agencyCommissionRate = isContractor ? 100.0 - agencySplit : agencySplit;
      }
      double gross = doc['amount'].value;
      double fees = doc['fee_total'].size() > 0 ? doc['fee_total'].value : 0;
      double supplierCost = doc['_embedded.booking.supplier_cost'].size() > 0 ? doc['_embedded.booking.supplier_cost'].value : 0;
      double netSupplier = gross - (fees + supplierCost);
      double netContractor = netSupplier * (agencyCommissionRate / 100.0);
      state.payments.add(netContractor * reversedMultiplier);`;

    const netAgentScript = `double reversedMultiplier = doc['is_reversed'].value ? -1 : 1;
      def agency = doc['_embedded.iata._embedded.company.id'].value;
      double agencyCommissionRate = 100.0;
      if (agency != doc['_embedded.company.id'].value) {
        boolean isContractor = agency != params.company.id;
        double agencySplit = doc['commission_split'].value;
        agencyCommissionRate = isContractor ? 100.0 - agencySplit : agencySplit;
      }
      double agentCommissionRate = 100.0;
      boolean isAgentCommissionable = doc['_embedded.booking.is_agent_commissionable'].size() > 0 ? doc['_embedded.booking.is_agent_commissionable'].value : false;
      boolean isAgentCompany = doc['_embedded.agent.company_id'].size() > 0 ? doc['_embedded.agent.company_id'].value == params.company.id : false;
      if (isAgentCompany) {
        if (isAgentCommissionable) {
          double rate = doc['_embedded.booking.agent_commission_rate'].size() > 0 ? doc['_embedded.booking.agent_commission_rate'].value : 0;
          agentCommissionRate = params.view == 'agent' ? rate : 100.0 - rate;
        } else if (params.view == 'agent') {
          agentCommissionRate = 0;
        }
      }
      double gross = doc['amount'].value;
      double fees = doc['fee_total'].size() > 0 ? doc['fee_total'].value : 0;
      double supplierCost = doc['_embedded.booking.supplier_cost'].size() > 0 ? doc['_embedded.booking.supplier_cost'].value : 0;
      def commissionBasis = doc['_embedded.agent.commission_split_basis'].size() > 0 ? doc['_embedded.agent.commission_split_basis'].value : 'net';
      double netSupplier = gross - (fees + supplierCost);
      double netContractor = netSupplier * (agencyCommissionRate / 100.0);
      double netAgent = netContractor * (agentCommissionRate / 100.0);
      if (isAgentCompany && isAgentCommissionable) {
        if (commissionBasis == 'gross') {
          netAgent = netSupplier * (agentCommissionRate / 100.0);
          if (params.view == 'agency') {
            double difference = netSupplier - netContractor;
            netAgent = netAgent - difference;
          }
        } else if (commissionBasis == 'net') {
          netAgent = netContractor * (agentCommissionRate / 100.0);
        }
      }
      state.payments.add(netAgent * reversedMultiplier);`;
    const aggs = {
      count: preciseCardinality('id'),
      filtered: {
        filter: { ...filterObject },
        aggs: {
          cost: {
            sum: { field: '_embedded.booking.supplier_cost' }
          },
          gross: {
            scripted_metric: {
              params: mapParams,
              init_script: 'state.payments = []',
              map_script: grossScript,
              combine_script: 'double sum = 0; for (t in state.payments) { sum += t } return sum',
              reduce_script: 'double sum = 0; for (a in states) { sum += a } return sum',
            },
          },
          net_agent: {
            scripted_metric: {
              params: mapParams,
              init_script: 'state.payments = []',
              map_script: netAgentScript,
              combine_script:
                'double sum = 0; for (t in state.payments) { sum += t } return sum',
              reduce_script:
                'double sum = 0; for (a in states) { sum += a } return sum'
            }
          },
          net_contractor: {
            scripted_metric: {
              params: mapParams,
              init_script: 'state.payments = []',
              map_script: netContractorScript,
              combine_script:
                'double sum = 0; for (t in state.payments) { sum += t } return sum',
              reduce_script:
                'double sum = 0; for (a in states) { sum += a } return sum'
            }
          },
          net_fee: {
            scripted_metric: {
              params: mapParams,
              init_script: 'state.payments = []',
              map_script: netFeeScript,
              combine_script:
                'double sum = 0; for (t in state.payments) { sum += t } return sum',
              reduce_script:
                'double sum = 0; for (a in states) { sum += a } return sum'
            }
          },
          net_supplier: {
            scripted_metric: {
              params: mapParams,
              init_script: 'state.payments = []',
              map_script: netSupplierScript,
              combine_script:
                'double sum = 0; for (t in state.payments) { sum += t } return sum',
              reduce_script:
                'double sum = 0; for (a in states) { sum += a } return sum'
            }
          },
          payment_types: {
            terms: {
              field: 'type',
              min_doc_count: 0,
              order: { _key: 'asc' }
            }
          }
        }
      },
    }

    if (filters.statement) {
      aggs['payments'] = {
        filter: {
          bool: {
            must: [
              { terms: { statement_ids: [filters.statement] } }
            ]
          }
        },
        aggs: {
          agents: {
            terms: {
              field: '_embedded.agent.id',
              size: 10
            },
            aggs: {
              count: {
                cardinality: {
                  field: 'id'
                }
              },
              gross: {
                sum: {
                  field: 'amount'
                }
              },
              net_ic: {
                sum: {
                  field: 'amount_payable_to.ic'
                }
              },
              net_agent: {
                sum: {
                  field: 'amount_payable_to.agent',
                }
              },
            }
          }
        }
      }
    }

    const payload = {
      aggs,
      size: state.pagination.per,
      sort,
      from: from >= 0 ? from : 0,
      query: {
        constant_score: {
          filter: { ...filterObject }
        }
      }
    };

    if (filters.statement) {
      const statement = rootGetters['statements/statement'];
      payload.aggs.filtered.aggs = Object.assign(payload.aggs.filtered.aggs, {
        amount_payable: {
          sum: {
            field:
              statement.type === 'agent'
                ? 'amount_payable_to.agent'
                : 'amount_payable_to.ic'
          }
        }
      });
      filterObject.bool.must.push({
        terms: { statement_ids: [filters.statement] }
      });
    }
    return payload;
  },
  paymentsFilters(state, getters, _rootState, rootGetters) {
    const filters = rootGetters['commissions/commissionsFilters'];
    return {
      ...filters,
      sort: getters.paymentsSort
    }
  },
  paymentsQueryFields: state => state.queryFields,
  paymentsRefresh: state => state.refresh,
  paymentsRefreshMinMax: state => state.refreshMinMax, // used?
  paymentsSort: state => state.sort
};

const actions = {
  async doCreatePayment({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.post(`/bookings/${id}/payments`, params);
    const [payment] = data.data.payments;
    if (!ignore)
      commit('SET_PAYMENTS_ATOMIC', {
        key: 'payment',
        val: formatPayment(payment)
      });
    return data;
  },
  async doDeletePayment({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.delete(`/payments/${id}`, { params });
    const [payment] = data.data.payments;
    if (!ignore)
      commit('SET_PAYMENTS_ATOMIC', { key: 'payment', val: payment });
    return data;
  },
  async doGetPayment({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.get(`/payments/${id}`, { params });
    const [payment] = data.data.payments;
    if (!ignore)
      commit('SET_PAYMENTS_ATOMIC', {
        key: 'payment',
        val: formatPayment(payment)
      });
    return data;
  },
  async doGetPaymentActions({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.get(`/payments/${id}/actions`, { params });
    if (!ignore)
      commit('SET_PAYMENTS_ATOMIC', { key: 'actions', val: data.data.actions });
    return data;
  },
  async doSearchPayments({ commit }, params) {
    const { data } = await SEARCH.post('/sion_payments/_search', params);
    commit('SET_PAYMENTS_ATOMIC', { key: 'aggs', val: data.aggregations });
    const payments = data.hits.hits.map(hit => hit._source);
    commit('SET_PAYMENTS_ATOMIC', { key: 'payments', val: payments });
    return data;
  },
  async doUpdatePayment({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.patch(`/payments/${id}`, params);
    const [payment] = data.data.payments;
    if (!ignore)
      commit('SET_PAYMENTS_ATOMIC', {
        key: 'payment',
        val: formatPayment(payment)
      });
    return data;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
