<template lang="html">
  <div
    id="terms"
    class="content"
  >
    <div>
      <h3>SION SAAS SUBSCRIPTION AGREEMENT</h3>
      <p>
        This SaaS Subscription Agreement (“Agreement”) is a legal agreement between you (the
        “Customer”) and Sion Inc., a Delaware corporation, with offices at 108 Main Street, Suite 1,
        Oceanport, NJ 07757 (“Sion”) and governs the use of the Service. Capitalized terms have the
        meaning given them in Section 1 below or as otherwise defined in the Agreement.
      </p>
      <p>
        By clicking “I Agree” or by otherwise accessing, accepting or using the Service, Customer
        agrees to be legally bound by this Agreement. If Customer does not agree to the terms of
        this Agreement, then Customer may not access or use the Service.
      </p>
    </div>
    <div>
      <h4>1. Definitions</h4>
      <p>
        “Client Data” means data or information submitted by Customer or an Employee User to the
        Service regarding a client of Customer, including booking data and any personally
        identifiable information of a client.
      </p>
      <p>
        “Customer Data” means Client Data and any other data or information provided by Customer for
        use in connection with the Service.
      </p>
      <p>
        “Employee Users” means Customer’s employees authorized under this Agreement to use the
        Service that have been supplied user identifications and passwords by Customer (or by Sion
        at Customer’s request).
      </p>
      <p>
        “Plans and Fees Page” means the online page located at
        <a :href="pricingLink">{{ pricingLink }}</a> setting forth the different Service plans and
        associated fees.
      </p>
      <p>
        “Service” means the online, web-based travel agent commission management service provided by
        Sion via <a :href="`https://${hostname}`">{{ hostname }}</a>
        or other URLs and applications designated by Sion.
      </p>
      <p>
        “Trial Period” means the period, if any, that Customer registers for a free trial of the
        Service, commencing on the Effective Date and continuing (i) for sixty (60) days or (ii)
        until Customer elects to begin a paid subscription plan and pays the applicable fees for the
        Service set forth in the Plans and Fees Page and the “Billing” tab in Customer’s account,
        whichever is sooner.
      </p>
      <h4>2. Provision of Service</h4>
      <p>
        Sion shall provide the Service subject to the terms and conditions of this Agreement and the
        relevant plan selected on the Plans and Fees Page. This Agreement includes the Plans and
        Fees Page and all exhibits attached hereto, each of which is incorporated herein by
        reference. Sion shall: (i) provide Customer with the authentication credentials Employee
        Users will need to access the Service; and (ii) provide telephone and online standard
        support to Customer as set forth on the Service website.
      </p>
      <h4>3. Use of Service</h4>
      <h5>3.1 Employee Users</h5>
      <p>
        Customer may grant its Employee Users access to the Service only for the subscription term
        applicable to Customer’s selected plan on the Plans and Fees Page.
      </p>
      <h5>3.2 Third Party Users</h5>
      <p>
        Customer may invite third parties to join the Service. Should an invited third party elect
        to join the Service, it must create its own account and enter into a separate agreement with
        Sion. Customer may share IATA numbers with such invited third parties that accepts an
        invitation, or existing third parties already using the Service. If Customer elects to
        provide IATA numbers to such third party (a “Third Party User”), then Customer is
        responsible for fees incurred by such Third Party User under Customer’s provided IATA
        numbers pursuant to the Plans and Fees Page.
      </p>
      <h5>3.3 Customer Responsibilities</h5>
      <p>
        Customer is responsible for all activities that occur under Employee User accounts. Customer
        shall: (i) have sole responsibility for the accuracy, quality, integrity, legality,
        reliability, and appropriateness of all Customer Data, including Client Data; (ii) prevent
        unauthorized access to, or use of, the Service (including any access or use by individuals
        other than Employee Users), and notify Sion promptly of any such unauthorized use; and (iii)
        comply with all applicable laws in using the Service. For the avoidance of doubt, Customer
        is not responsible for any activities that occur under Third Party User accounts, except for
        the Third Party User fees described in the Plans and Fees Page.
      </p>
      <h5>3.4 Restrictions</h5>
      <p>
        Customer shall use the Service solely for its internal business purposes in accordance with
        this Agreement. Customer shall not, and shall ensure its Employee Users do not: (i) license,
        sublicense, sell, resell, rent, lease, transfer, assign, distribute, time share or otherwise
        commercially exploit or make the Service available to any third party; (ii) send spam or
        otherwise duplicative or unsolicited messages via the Service; (iii) send or store
        infringing, obscene, threatening, libelous, or otherwise unlawful or tortious material,
        including material harmful to children or violative of third party privacy rights; (iv) send
        or store material containing software viruses, worms, Trojan horses or other harmful
        computer code, files, scripts, agents or programs; (v) interfere with or disrupt the
        integrity or performance of the Service or the data contained therein; or (vi) attempt to
        gain unauthorized access to the Service or its related systems or networks.
      </p>
      <h4>4. Fees &amp; Payment</h4>
      <h5>4.1 Fees</h5>
      <p>
        Except as otherwise expressly set forth on the Plans and Fees Page and the “Billing” tab in
        Customer’s account, there will be no charge to Customer for the Service during the Trial
        Period. Should Customer elect to continue use of the Service after the Trial Period and
        begin a paid subscription plan, Customer will be prompted to submit payment card details as
        a part of the process. Thereafter, Customer shall pay the fees for the Service as specified
        on the Plans and Fees Page and the “Billing” tab in Customer’s account. If Sion does not
        receive a payment in full when due, Sion may charge interest on the amount of the late
        payment at the lesser of 1.5% per month or the maximum amount permitted under applicable
        law. If Customer’s account is fifteen (15) days or more overdue, Sion may suspend the
        Service until such amounts are paid.
      </p>
      <h5>4.2 Taxes</h5>
      <p>
        Sion’s fees do not include any local, state, federal or foreign taxes, levies or duties of
        any nature (“Taxes”). Customer is responsible for paying all Taxes, excluding only taxes
        based on Sion’s income. If Sion has the legal obligation to pay or collect Taxes for which
        Customer is responsible under this section, the appropriate amount shall be invoiced to and
        paid by Customer unless Customer provides Sion with a valid tax exemption certificate
        authorized by the appropriate taxing authority.
      </p>
      <h4>5. Proprietary Rights</h4>
      <h5>5.1 Sion Technology</h5>
      <p>
        The Service includes (i) the Sion name, the Sion logo, the
        <a :href="`https://${hostname}`">{{ hostname }}</a> domain name and all subdomains and
        content thereon, the product and service names associated with the Service, and other
        trademarks and service marks; (ii) audio and visual information, documents, software and
        other works of authorship provided by Sion to Customer under this Agreement; and (iii) other
        technology, including graphical user interfaces, workflows, products, processes, algorithms,
        know-how and other trade secrets, techniques, designs, inventions and other tangible or
        intangible technical material or information used by Sion to provide the services under this
        Agreement (collectively, “Sion Technology”). Other than as expressly set forth herein, no
        license or other rights in or to the Sion Technology or related intellectual property rights
        are granted to Customer or Employee Users, and all such licenses and rights are hereby
        expressly reserved to Sion.
      </p>
      <h5>5.2 Use Restrictions</h5>
      <p>
        Customer shall not (i) modify, copy or create derivative works based on the Service or Sion
        Technology; (ii) create Internet “links” to or from the Service, or “frame” or “mirror” any
        content forming part of the Service, other than on Customer’s own intranets; or (iii)
        disassemble, reverse engineer, or decompile the Service or Sion Technology, or (iv) access
        the Service in order to copy any ideas, features, functions or graphics of the Service.
      </p>
      <h5>5.3 Customer Data</h5>
      <p>
        Sion may access, reproduce, and use Customer’s Employee User accounts, including Customer
        Data associated therewith, to respond to service or technical problems, at Customer’s or the
        applicable Employee User’s request, or otherwise in connection with the Service, and
        Customer is responsible for obtaining all consents necessary for Sion to do the foregoing.
        Sion may use during and after the term of this Agreement Customer Data that is anonymized
        such that it does not identify any individual or third party for the purpose of optimizing
        and improving Sion’s products and services.
      </p>
      <h5>5.4 Suggestions</h5>
      <p>
        Customer hereby grants to Sion a royalty-free, worldwide, irrevocable, perpetual,
        sublicensable, transferable license to use or incorporate into the Service any suggestions,
        ideas, enhancement requests, feedback, recommendations or other information provided by
        Customer or its Employee Users relating to the operation of the Service or other potential
        products and services.
      </p>
      <h4>6. Confidentiality</h4>
      <h5>6.1 Definition of Confidential Information</h5>
      <p>
        The term “Confidential Information” means all confidential and proprietary information of a
        party (“Disclosing Party”) disclosed to the other party (“Receiving Party”), whether orally
        or in writing, that is designated as confidential or that reasonably should be understood to
        be confidential given the nature of the information and the circumstances of disclosure,
        including the terms and conditions of this Agreement, the Customer Data, the Service, the
        Sion Technology, technology and technical information, and product designs. Confidential
        Information shall not include any information that: (i) is or becomes publicly available
        without breach of any obligation owed to the Disclosing Party; (ii) was known to the
        Receiving Party prior to its disclosure by the Disclosing Party without breach of any
        obligation owed to the Disclosing Party; (iii) was independently developed by the Receiving
        Party without breach of any obligation owed to the Disclosing Party; or (iv) is received
        from a third party without breach of any obligation owed to the Disclosing Party.
      </p>
      <h5>6.2 Confidentiality</h5>
      <p>
        The Receiving Party shall not disclose or use any Confidential Information of the Disclosing
        Party except to perform its obligations or exercise its rights under this Agreement, except
        with the Disclosing Party’s prior written permission. Each party agrees to protect the
        confidentiality of the Confidential Information of the other party in the same manner that
        it protects the confidentiality of its own proprietary and confidential information of like
        kind, but in no event with less than reasonable care. If the Receiving Party is compelled by
        law or a government authority to disclose Confidential Information of the Disclosing Party,
        it shall provide the Disclosing Party with prior notice of such compelled disclosure (to the
        extent practicable and legally permitted) and reasonable assistance, at Disclosing Party’s
        cost, if the Disclosing Party wishes to contest the disclosure.
      </p>
      <h5>6.3 Remedies</h5>
      <p>
        If the Receiving Party discloses or uses (or threatens to disclose or use) any Confidential
        Information of the Disclosing Party in breach of this Section 6, the Disclosing Party shall
        have the right, in addition to any other remedies available to it, to seek injunctive relief
        to enjoin such acts, it being specifically acknowledged by the parties that such
        unauthorized disclosure or use may cause irreparable harm to the Disclosing Party for which
        any other available remedies are inadequate.
      </p>
      <h4>7. Warranties &amp; Disclaimers</h4>
      <h5>7.1 Warranties</h5>
      <p>
        Each party represents and warrants that it has the legal power and authority to enter into
        this Agreement. Sion represents and warrants that it will provide the Service in a manner
        consistent with general industry standards applicable to services similar to the Services.
      </p>
      <h5>7.2 Exceptions</h5>
      <p>
        Sion will not be responsible for any unavailability, suspension or termination of Sion
        products or services, or any other Sion performance issues: (i) caused by factors outside of
        Sion’s reasonable control, including any force majeure event or Internet access or related
        problems beyond the demarcation point of Sion; (ii) that result from any actions or
        inactions of Customer or any third party; (iii) that result from Customer’s equipment,
        software or other technology and/or third party equipment, software or other technology
        (other than third party equipment within Sion’s direct control); (iv) that result from the
        unavailability of third party services such as third party technology providers or data
        sources; or (v) arising from Sion’s suspension or termination of Customer’s right to use
        Sion in accordance with this Agreement.
      </p>
      <h5>7.3 Disclaimer</h5>
      <p>
        CUSTOMER ACKNOWLEDGES AND AGREES THAT THE SERVICES MAY CHANGE OVER TIME. EXCEPT AS EXPRESSLY
        PROVIDED IN SECTION 7 ABOVE, SION MAKES NO WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED,
        STATUTORY, OR OTHERWISE. SION HEREBY SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES,
        INCLUDING ANY WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR
        NON-INFRINGEMENT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.
      </p>
      <h4>8. Indemnification</h4>
      <h5>8.1 Indemnification by Sion</h5>
      <p>
        Subject to this Agreement, Sion shall at its expense defend Customer and its officers,
        directors and employees (“Customer Indemnified Parties”) against any claim made or brought
        against any Customer Indemnified Party by a third party alleging that the Service as
        provided to Customer hereunder infringes the intellectual property rights of a third party
        (each, a “Customer Claim”), and shall pay any damages finally awarded by a court or agreed
        to by Sion in a settlement with respect to such Customer Claim; <u>provided</u>, that
        Customer (a) promptly gives written notice of the Customer Claim to Sion; (b) gives Sion
        sole control of the defense and settlement of the Customer Claim (provided that Sion may not
        agree to any settlement that imposes any liability or obligation on Customer); and (c)
        provides to Sion, at Sion’s cost, all reasonable assistance. Sion shall have no obligation
        under this Section 8.1 or otherwise regarding claims that arise from or relate to (i)
        Customer’s use of the Service other than as contemplated by this Agreement, (ii) any
        modifications to the Service made by any entity other than Sion, (iii) any combination of
        the Service with services or technologies not provided or approved by Sion, or (iv)
        Customer’s use of the Service or portion thereof after Sion has terminated this Agreement or
        such portion of the Service in accordance with this Section 8.1. If in Sion’s opinion a
        Customer Claim is likely to be made, or if an existing Customer Claim may cause Sion
        liability, Sion may in its discretion (x) obtain a license to enable Customer to continue to
        use the potentially infringing portion of the Service, (y) modify the Service to avoid the
        potential infringement, or (z) if the foregoing cannot be achieved after using reasonable
        commercial efforts, terminate the Agreement or the license to the infringing portion of the
        Service and refund the amount of any pre-paid fees applicable to the portion of the
        terminated Services to be provided after the termination date. This indemnity shall be
        Customer’s sole and exclusive remedy with respect to any intellectual property infringement
        claims.
      </p>
      <h5>8.2 Indemnification by Customer</h5>
      <p>
        Subject to this Agreement, Customer shall at its expense defend Sion and its officers,
        directors and employees (“Sion Indemnified Parties”) against any claims made or brought
        against any Sion Indemnified Party arising from or related to (i) Customer’s or any Employee
        User’s use of the Service, or (ii) Sion’s use of any Customer Data in accordance with this
        Agreement or Customer’s use of the Service in violation of any law or regulation or in a
        manner not contemplated in this Agreement (each, a “Sion Claim”) and shall pay any damages
        finally awarded by a court or agreed to by Customer in a settlement with respect to such
        Sion Claim; <u>provided</u>, that Sion (a) promptly gives written notice of the Sion Claim
        to Customer; (b) gives Customer sole control of the defense and settlement of the Sion Claim
        (provided that Customer may not agree to any settlement that imposes any liability or
        obligation on Sion); and (c) provides to Customer, at Customer’s cost, reasonable assistance
        in connection therewith.
      </p>
      <h4>9. Limitation of Liability</h4>
      <h5>9.1 Limitation of Liability</h5>
      <p>
        IN NO EVENT SHALL SION’S AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT,
        WHETHER IN CONTRACT, TORT OR UNDER ANY OTHER THEORY OF LIABILITY, EXCEED THE AMOUNTS
        ACTUALLY PAID BY CUSTOMER TO SION HEREUNDER IN THE THEN-PRIOR TWELVE (12) MONTH PERIOD
        PRECEDING THE INITIAL CLAIM GIVING RISE TO LIABILITY HEREUNDER.
      </p>
      <h5>9.2 Waiver</h5>
      <p>
        EXCEPT FOR DAMAGES ARISING FROM A PARTY’S BREACH OF SECTION 6, OR CUSTOMER’S BREACH OF
        SECTIONS 3.3 OR 5, IN NO EVENT SHALL EITHER PARTY HAVE ANY LIABILITY TO THE OTHER PARTY FOR
        ANY LOST PROFITS, LOSS OF USE, LOSS OF DATA OR DATA BREACHES, COSTS OF PROCUREMENT OF
        SUBSTITUTE GOODS OR SERVICES, OR FOR ANY INDIRECT, SPECIAL, INCIDENTAL, MULTIPLE, EXEMPLARY,
        PUNITIVE, OR CONSEQUENTIAL DAMAGES HOWEVER CAUSED AND, WHETHER IN CONTRACT, TORT OR UNDER
        ANY OTHER THEORY OF LIABILITY, WHETHER OR NOT THE PARTY HAS BEEN ADVISED OF THE POSSIBILITY
        OF SUCH DAMAGE.
      </p>
      <h4>10. Term &amp; Termination</h4>
      <h5>10.1 Term of Agreement</h5>
      <p>
        This Agreement commences on the Effective Date and continues for so long as Customer has an
        ongoing subscription to one of the Service plans or until otherwise terminated in accordance
        with this Section 10. After (i) the Trial Period or (ii) the initial term if no Trial Period
        is applicable, this Agreement will automatically renew for the additional periods as
        described in Customer’s selected plan on the Plans and Fees Page, if any, or if no such
        periods are specified, additional one (1) month periods unless a party provides written
        notice of non-renewal at least thirty (30) days prior to the end of the then-current term.
      </p>
      <h5>10.2 Termination for Cause</h5>
      <p>
        A party may terminate this Agreement for cause: (i) upon thirty (30) days written notice of
        a material breach to the other party if such breach remains uncured at the expiration of
        such period; or (ii) if the other party becomes the subject of a petition in bankruptcy or
        any other proceeding relating to insolvency, receivership, liquidation or assignment for the
        benefit of creditors that is not dismissed or stayed within ninety (90) days. Termination
        shall not relieve Customer of the obligation to pay any fees accrued or payable to Sion
        prior to the effective date of termination.
      </p>
      <h5>10.3 Customer Data Following Expiration or Termination</h5>
      <p>
        Upon expiration or termination of this Agreement (or of the applicable subscription), Sion
        shall have no obligation to maintain or provide any Customer Data and shall thereafter,
        unless legally prohibited, delete all Customer Data in its systems or otherwise in its
        possession or under its control. Notwithstanding the foregoing or any other provision of
        this Agreement, Sion may use in perpetuity any anonymized data which is derived from
        Customer Data but does not identify Customer or any specific Employee User.
      </p>
      <h5>10.4 Survival</h5>
      <p>
        The following provisions shall survive termination or expiration of this Agreement: Sections
        1, 4, 5, 6, 7.3, 8, 9, 10.3, 10.4 and 11.
      </p>
      <h4>11 General Provisions</h4>
      <h5>11.1 Relationship of the Parties</h5>
      <p>
        This Agreement does not create a partnership, franchise, joint venture, agency, fiduciary or
        employment relationship between the parties. There are no third-party beneficiaries to this
        Agreement.
      </p>
      <h5>11.2 Logo</h5>
      <p>
        Sion may use Customer’s name and logo in Sion’s lists of customers provided that such use
        will comply with Customer’s standard trademark guidelines provided by Customer to Sion.
      </p>
      <h5>11.3 Force Majeure</h5>
      <p>
        Neither party shall be liable to the other for any failure or delay in performance of its
        obligations under this Agreement (other than obligations to make payment) arising out of any
        event or circumstance beyond the reasonable control of such party.
      </p>
      <h5>11.4 Notices</h5>
      <p>
        All notices under this Agreement shall be in writing and shall be deemed to have been given
        upon: (i) personal delivery; (ii) the second business day after mailing; (iii) the second
        business day after sending by confirmed facsimile; or (iv) the second business day after
        sending by email.
      </p>
      <h5>11.5 Waiver</h5>
      <p>
        No failure or delay by either party in exercising any right under this Agreement shall
        constitute a waiver of that right. If any provision of this Agreement is held by a court of
        competent jurisdiction to be contrary to law, the provision shall be modified by the court
        and interpreted so as best to accomplish the objectives of the original provision to the
        fullest extent permitted by law, and the remaining provisions of this Agreement shall remain
        in effect.
      </p>
      <h5>11.6 Assignment</h5>
      <p>
        Neither party may assign any of its rights or obligations hereunder, whether by operation of
        law or otherwise, without the prior express written consent of the other party.
        Notwithstanding the foregoing, either party may assign this Agreement together with all
        rights and obligations hereunder, without consent of the other party, in connection with a
        merger, acquisition, corporate reorganization, or sale of all or substantially all of its
        stock or assets that relate to this Agreement. Any attempt by a party to assign its rights
        or obligations under this Agreement in breach of this section shall be void and of no
        effect. Subject to the foregoing, this Agreement shall bind and inure to the benefit of the
        parties, their respective successors and permitted assigns.
      </p>
      <h5>11.7 Governing Law</h5>
      <p>
        This Agreement shall be governed by the laws of Delaware. The state and federal courts
        located in New York City, New York shall have exclusive jurisdiction to adjudicate any
        dispute arising out of or relating to this Agreement. Each party hereby consents to the
        exclusive jurisdiction of such courts provided that nothing in this Section 11.7 prohibits
        either party from seeking or obtaining in any jurisdiction injunctive or similar relief in
        connection with the enforcement of this Agreement.
      </p>
      <h5>11.8 Entire Agreement</h5>
      <p>
        This Agreement, including all exhibits and addenda hereto, constitutes the entire agreement
        between the parties, and supersedes all prior and contemporaneous agreements, proposals or
        representations, written or oral, concerning its subject matter. No modification, amendment,
        or waiver of any provision of this Agreement shall be effective unless in writing and signed
        by the party against whom the modification, amendment or waiver is to be asserted. In the
        event of any conflict between the provisions in the main body of this Agreement and any
        exhibit hereto, the terms of the main body of this Agreement shall prevail to the extent of
        such conflict, except and to the extent that the Data Processing Addendum and any other
        exhibit hereto expressly identifies by Section any specific provision of the main body of
        this Agreement that the parties intend to override. Notwithstanding any language to the
        contrary therein, no terms or conditions stated in a Customer purchase order or in any other
        Customer order documentation shall be incorporated into or form any part of this Agreement,
        and all such terms or conditions are hereby rejected and shall be null and void.
      </p>
    </div>
    <h3>Data Processing Addendum</h3>
    <p>
      This DPA will remain in force until the date on which the Agreement expires or terminates in
      accordance with its terms. Any capitalized term used but not defined in this DPA shall have
      the meaning set forth in the Agreement.
    </p>
    <h4>1. Definitions</h4>
    <p>
      “Business,” “Business Purpose,” “Commercial Purpose,” “Consumer,” “Household,” have the
      meaning as set out in the CCPA (as defined below).
    </p>
    <p>
      “CCPA” means the California Consumer Privacy Act of 2018 (as amended), Cal. Civ. Code §§
      1798.100-1798.199 as applicable to either party and as amended, repealed, consolidated or
      replaced from time to time.
    </p>
    <p>“Controller” has the meaning as set out in EU Data Protection Laws (as defined below).</p>
    <p>
      “Data Protection Laws” mean any applicable law, statute or regulation or other enactment
      pertaining to data protection, in each case as amended, consolidated, re-enacted or replaced
      from time to time.
    </p>
    <p>
      “EU Data Protection Laws” means, as applicable, (i) the EU General Data Protection Regulation
      (Regulation 2016/679) as may be amended, superseded or replaced) (“GDPR”) and all other
      supplemental or implementing laws relating to data privacy in any European member state; and
      (ii) legally binding guidance and codes of practice issued by the relevant supervisory
      authority.
    </p>
    <p>
      “Personal Data” has the meaning as set out in EU Data Protection Laws (as defined below), as
      being any data or information relating to an individual or identifiable natural person, being
      one who can be identified, directly or indirectly, in particular by reference to an identifier
      such as a name, an identification number, location data, an online identifier or to one or
      more factors specific to the physical, physiological, genetic, mental, economic, cultural or
      social identity of that person.
    </p>
    <p>
      “Personal Information” has the meaning as set out in the CCPA (as defined above), as being any
      data or information that identifies, relates to, describes, is reasonably capable of being
      associated with, or could reasonably be linked, directly or indirectly, with a particular
      Consumer or Household.
    </p>
    <p>“Processing” has the meaning as set out in EU Data Protection Laws (as defined above).</p>
    <p>“Processor” has the meaning as set out in EU Data Protection Laws (as defined above).</p>
    <h4>2. General Commitments</h4>
    <h5>2.1</h5>
    <p>
      The Parties understand, acknowledge and agree that in connection with Sion’s performance on
      behalf of Customer of the services contemplated to be provided in connection with the
      Agreement (“Services”), Customer will share Client Data and Customer Data with Sion necessary
      to perform a Business Purpose and that Client Data or Customer Data may include Personal Data
      or Personal Information.
    </p>
    <h5>2.2</h5>
    <p>
      Customer shall collect, use and disclose to Sion Personal Data and Personal Information at all
      times in compliance with Data Protection Laws, including, without limitation, providing notice
      and obtaining all consents and rights for Sion to receive and use Personal Data and Personal
      Information, including as provided to or otherwise collected by Sion when Employee Users
      interact with, or use, the Services, for all purposes set out in the Agreement.
    </p>
    <h4>3. CCPA</h4>
    <h5>3.1</h5>
    <p>
      To the extent that Customer is a Business for the purposes of the CCPA, Sion agrees that it
      will not collect (except at the direction of Customer), retain, use or disclose Personal
      Information of Customers or Employee Users except as necessary to perform the Business
      Purpose(s) unless otherwise permitted under the CCPA, including retaining, using, or
      disclosing any Personal Information for a Commercial Purpose other than providing the Services
      to Customer.
    </p>
    <h4>4. GDPR</h4>
    <p>
      To the extent that Customer is a Controller under EU Data Protection Laws and for the purpose
      of assisting Customer meet its obligations under EU Data Protection Laws, Sion agrees:
    </p>
    <h5>4.1</h5>
    <p>
      Sion shall only process Personal Data in accordance with and for the purposes and duration of
      the Agreement, and on the documented instructions received from Customer from time to time,
      unless Sion is required to otherwise process Personal Data by European Union or member state
      law to which Sion is subject.
    </p>
    <h5>4.2</h5>
    <p>
      Sion shall ensure that all employees, agents, officers, consultants, sub processors and any
      third parties authorized to process the Personal Data have committed themselves to
      confidentiality or are under an appropriate statutory obligation of confidentiality.
    </p>
    <h5>4.3</h5>
    <p>
      Sion shall implement appropriate technical and organizational security measures as agreed to
      by the Parties in the main body of the Agreement, and the Customer acknowledges and agrees
      that those measures are appropriate and sufficient under EU Data Protection Laws.
    </p>
    <h5>4.4</h5>
    <p>
      Taking into account the nature of the Processing, Sion shall assist Customer by appropriate
      technical and organizational measures, insofar as this is possible, for the fulfilment of the
      Controller’s obligation to respond to requests for exercising the Data Subject’s rights.
    </p>
    <h5>4.5</h5>
    <p>
      Sion shall assist Customer in ensuring Customer’s compliance with the obligations under
      Articles 32 to 36 of the GDPR to (i) implement appropriate technical and organizational
      security measures; (ii) notify (if required) Personal Data Breaches to Supervisory Authorities
      and/or individuals; and (iii) conduct data protection impact assessments and, if required,
      prior consultation with Supervisory Authorities. Upon expiration or termination of the
      Agreement, Sion shall delete all Personal Data in accordance with Section 10.3 of the
      Agreement, unless European Union or member state law to which Sion is subject requires storage
      of the Personal Data.
    </p>
    <h5>4.6</h5>
    <p>
      Sion shall make available to Customer all information necessary to demonstrate compliance with
      EU Data Protection Laws and allow for and contribute to audits, including inspections,
      conducted by Customer or another auditor mandated by Customer.
    </p>
    <h5>4.7</h5>
    <p>
      Sion shall promptly inform Customer if it believes that any instruction infringes EU Data
      Protection Laws.
    </p>
    <h5>4.8</h5>
    <p>
      Sion shall promptly notify Customer about any legally binding request for disclosure of
      Customer’s Personal Data by a Supervisory Authority, government agency, or law enforcement
      authority unless otherwise prohibited, under criminal law to preserve the confidentiality of a
      law enforcement investigation, and shall provide assistance to Customer in responding to these
      requests.
    </p>
    <h5>4.9</h5>
    <p>
      Customer consents to use of Sion’s existing sub processors. Prior to engaging or replacing any
      sub processor, Sion shall provide Customer with twenty (20) business days’ prior written
      notice, during which Customer can object against the appointment or replacement. Sion will
      enter into an agreement with sub processors that has the same data protection obligations set
      out in this Addendum.
    </p>
  </div>
</template>

<script>
export default {
  name: 'Terms',
  computed: {
    hostname() {
      return window.location.hostname.replace(/app\./, '');
    },
    pricingLink() {
      return `https://${this.hostname}/pricing`;
    },
  },
};
</script>

<style lang="scss" scoped>
#terms {
  h3 {
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
  }
  h4 {
    margin-top: pxToRem(28);
    font-weight: 500;
  }
  h5 {
    font-weight: normal;
    font-size: pxToRem(16);
    margin-bottom: pxToRem(5);
    color: $grey;
  }
}
</style>
