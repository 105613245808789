import { apiClient } from '../apiClient';

const DOMAIN = 'notifications';

export default {
  get: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}`, { params }),
  update: ({ id, ...payload }) => apiClient.patch(`/${DOMAIN}/${id}`, payload),
  getAll: (params) => apiClient.get(`/sion_${DOMAIN}/_search`, { params }),
  getAllFromUser: ({ id, ...params }) => apiClient.get(`/users/${id}/${DOMAIN}`, { params }),
};
