import Vue from 'vue';
import router from '@/router';
import * as Sentry from '@sentry/vue';
import LogRocket from 'logrocket';
// Import specific modules needed
import { setUser, captureException, captureMessage } from '@sentry/browser';

export default () => {
  if (!Vue.prototype.$isProd && !Vue.prototype.$isStaging) return;
  Vue.prototype.$sentry = { setUser, captureException, captureMessage };
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ['localhost', /sioncentral.com.(?!.*socket\.io.*)/, /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  });

  // Adds Logrocket Session Url to Sentry
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra('sessionURL', sessionURL);
    });
  });
};
