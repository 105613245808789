<template>
  <div class="sion-full-logo">
    <b-icon
      icon="sion-logo"
      :pack="$defaultIconPack"
      custom-size="is-size-3"
    />
    <b-icon
      icon="sion-name"
      :pack="$defaultIconPack"
      size="is-large"
      style="font-size:80px;"
    />
  </div>
</template>

<script>
  export default {
    name: 'SionFullLogo',
  }
</script>

<style lang="scss">
.sion-full-logo {
  display: flex;
  align-items: center;
  .icon.is-large {
    width: fit-content;
    margin-left: $sion-icon-to-text-gap;
    i.heroicons.icon-sion-name.is-size-1 {
      font-size: inherit !important;
      color: $grey-darker;
    }
  }
}
</style>