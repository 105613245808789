import Vue from 'vue';
import { HTTP } from '@/utils/http';

const state = {
  self: {},
  user: {},
  users: [],
};

const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },
  SET_USER_SELF(state, user) {
    state.self = user;
  },
  UNSET_USER_SELF(state) {
    state.self = {};
  },
  SET_USERS(state, users) {
    state.users = users;
  },
  SET_USERS_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
};

const getters = {
  user: (state) => state.user,
  userSelf: (state) => state.self,
  users: (state) => state.users, // used?
};

const actions = {
  async doCreateUser({ commit }, payload) {
    const { ignore, ...params } = payload;
    const { data } = await HTTP.post('/users', params);
    if (!ignore) commit('SET_USER', data.data.users[0]);
    return data;
  },
  async doGetUser({ commit }, payload) {
    // used?
    const { id, ...params } = payload;
    const { data } = await HTTP.get(`/users/${id}`, { params });
    commit('SET_USER', data.data.users[0]);
    return data;
  },
  async doGetUserSelf({ commit }, payload) {
    const { ignore, ...params } = payload;
    const { data } = await HTTP.get('/self', { params });
    if (!ignore) {
      commit('SET_USER_SELF', data.data.users[0]);
    }
    return data;
  },
  async doGetUsers({ commit }, params) {
    // used?
    const { data } = await HTTP.get('/users', { params });
    commit('SET_USERS', data.data.users);
    return data;
  },
  async doResetUserPassword({ commit }, payload) {
    const { ignore, ...params } = payload;
    const { data } = await HTTP.post('/users_public/_reset_password', params);
    if (!ignore) commit('SET_USER', data.data.users[0]);
    return data;
  },
  async doUpdateUser({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.patch(`/users/${id}`, params);
    if (!ignore) commit('SET_USER', data.data.users[0]);
    return data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
