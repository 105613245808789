import { apiClient } from '../apiClient';

const DOMAIN = 'broadcast_messages';

export default {
  create: (params) => apiClient.post(`/${DOMAIN}`, params),
  listMessagesSender: () => apiClient.get(`/${DOMAIN}`),
  listMessagesRecipient: (params) => apiClient.get(`/${DOMAIN}/unread`, { params } ),
  update: ({id, ...params}) => apiClient.patch(`/${DOMAIN}/${id}`, params),
  setMessageAsRead: (id) => apiClient.post(`/${DOMAIN}/${id}/reads`)
};