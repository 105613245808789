import { apiClient } from '../apiClient';

const DOMAIN = 'tags';

export default {
  create: ({ id, ...payload }) => apiClient.post(`/companies/${id}/${DOMAIN}`, payload),
  getAll: ({ id, ...params }) => apiClient.get(`/companies/${id}/${DOMAIN}`, { params }),
  update: ({ id, ...payload }) => apiClient.patch(`/${DOMAIN}/${id}`, payload),
  delete: ({ id, ...payload }) => apiClient.delete(`/${DOMAIN}/${id}`, payload),
  deleteFromResource: ({ id, resource, resource_id, ...payload }) => {
    return apiClient.delete(`/${DOMAIN}/${id}/${resource}/${resource_id}`, payload)
  },
};
