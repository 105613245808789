export const filter = (
  companies = [],
  iatas = [],
  // ie: reportingDates = { field: '', startDate: '2024-01-01', endDate: '2024-12-31' }
  reportingDates = {}
) => {
  const { field, startDate, endDate } = reportingDates;
  const esFilter = {
    'bool': {
      'must': [
        {
          'terms': {
            '_embedded.production_reports.company_id': companies
          }
        },
        {
          'match': {
            '_embedded.production_reports.dates.field': field
          }
        },
        {
          'range': {
            '_embedded.production_reports.dates.start': {
              'gte': startDate
            }
          }
        },
        {
          'range': {
            '_embedded.production_reports.dates.end': {
              'lte': endDate
            }
          }
        },
      ]
    }
  }
  if (iatas.length >= 1) {
    esFilter.bool.must.push({
      'terms': {
        '_embedded.production_reports.iata_id': iatas
      }
    })
  }
  return esFilter;
}

export const typesAndSubTypes = (types) => {
  const typesShould = [];
  const typesWithoutSubtypes = [];
  types.forEach(type => {
    if (type.sub_types.length > 0) {
      typesShould.push({
        bool: {
          must: [
            { term: { type: type.key } },
            { terms: { sub_type: type.sub_types } }
          ]
        }
      })
    } else {
      typesWithoutSubtypes.push(type.key);
    }
  })
  if (typesWithoutSubtypes.length > 0) {
    typesShould.push({ terms: { type: typesWithoutSubtypes } });
  }
  return {
    bool: {
      should: typesShould,
    }
  };
}

export const productionAggs = (
  companies = [],
  iatas = [],
  // ie: reportingDates = { field: '', startDate: '2024-01-01', endDate: '2024-12-31' }
  reportingDates = {}
) => {
  const { startDate, endDate } = reportingDates;
  return {
    'filter': filter(companies, iatas, reportingDates),
    'aggs': {
      'total_revenue': {
        'sum': { 'field': '_embedded.production_reports.revenue' }
      },
      'total_commission': {
        'sum': { 'field': '_embedded.production_reports.commission' }
      },
      'total_booking_count': {
        'sum': { 'field': '_embedded.production_reports.booking_count' }
      },
      'by_quarter': {
        'date_histogram': {
          'field': '_embedded.production_reports.dates.start',
          'interval': 'quarter',
          'format': 'yyyy-MM-dd',
          'min_doc_count': 0,
          'extended_bounds': {
            'min': startDate,
            'max': endDate,
          }
        },
        'aggs': {
          'month_a': {
            'date_histogram': {
              'field': '_embedded.production_reports.months.a.dates.start',
              'interval': 'month',
              'format': 'yyyy-MM-dd',
              'min_doc_count': 0
            },
            'aggs': {
              'booking_count': {
                'sum': { 'field': '_embedded.production_reports.months.a.booking_count' }
              },
              'revenue': {
                'sum': { 'field': '_embedded.production_reports.months.a.revenue' }
              },
              'commission': {
                'sum': { 'field': '_embedded.production_reports.months.a.commission' }
              }
            }
          },
          'month_b': {
            'date_histogram': {
              'field': '_embedded.production_reports.months.b.dates.start',
              'interval': 'month',
              'format': 'yyyy-MM-dd',
              'min_doc_count': 0
            },
            'aggs': {
              'booking_count': {
                'sum': { 'field': '_embedded.production_reports.months.b.booking_count' }
              },
              'revenue': {
                'sum': { 'field': '_embedded.production_reports.months.b.revenue' }
              },
              'commission': {
                'sum': { 'field': '_embedded.production_reports.months.b.commission' }
              }
            }
          },
          'month_c': {
            'date_histogram': {
              'field': '_embedded.production_reports.months.c.dates.start',
              'interval': 'month',
              'format': 'yyyy-MM-dd',
              'min_doc_count': 0
            },
            'aggs': {
              'booking_count': {
                'sum': { 'field': '_embedded.production_reports.months.c.booking_count' }
              },
              'revenue': {
                'sum': { 'field': '_embedded.production_reports.months.c.revenue' }
              },
              'commission': {
                'sum': { 'field': '_embedded.production_reports.months.c.commission' }
              }
            }
          }
        }
      }
    }
  }
}

export const sortMethod = (
  sortedBy,
  companies = [],
  iatas = [],
  // ie: reportingDates = { field: '', startDate: '2024-01-01', endDate: '2024-12-31' }
  reportingDates = {},
  isSuppliersGroups = false
) => {
  const sort = [];

  const sortKey = Object.keys(sortedBy)[0];
  const sortOrder = sortedBy[sortKey];
  const dynamicSortKeys = {
    'total_commission': 'commission',
    'total_revenue': 'revenue',
    'total_booking_count': 'booking_count',
  };
  if (Object.keys(dynamicSortKeys).includes(sortKey)) {
    const dynamicSortKey = `_embedded.production_reports.${dynamicSortKeys[sortKey]}`
    const dynamicSort = {}
    dynamicSort[dynamicSortKey] = {
      'mode': 'sum',
      'order': sortOrder,
      'nested': {
        'path': '_embedded.production_reports',
        'filter': filter(companies, iatas, reportingDates),
      }
    }
    sort.push(dynamicSort);
    const nameKey = isSuppliersGroups ? 'case_insensitive' : 'raw';
    sort.push({ [`name.${nameKey}`]: { 'order': 'asc' } });
  } else {
    const obj = {};
    obj[sortKey === 'name' ? 'name.raw' : sortKey] = { 'order': sortOrder };
    sort.push(obj);
  }
  return sort;
};

export const productionReportingFields = (
  fieldName = '',
  companies = [],
  iatas = [],
  // ie: reportingDates = { field: '', startDate: '2024-01-01', endDate: '2024-12-31' }
  reportingDates = {}
) => {
  const { field, startDate, endDate } = reportingDates;
  return {
    script: {
      lang: 'painless',
      source: `
        double total = 0.0;
        def reports = params._source?._embedded?.production_reports ?: [];
        for (report in reports) {
          boolean companyMatch = params.company_ids.contains(report.company_id);
          boolean iataMatch = params.iata_ids.contains(report.iata_id);
          boolean fieldMatch = params.dates.field == report.dates.field;

          DateTimeFormatter formatter = DateTimeFormatter.ofPattern('yyyy-MM-dd');

          LocalDate startDate = LocalDate.parse(report.dates.start, formatter);
          LocalDate minDate = LocalDate.parse(params.dates.start, formatter);

          LocalDate endDate = LocalDate.parse(report.dates.end, formatter);
          LocalDate maxDate = LocalDate.parse(params.dates.end, formatter);

          boolean dateMatch = startDate.compareTo(minDate) >= 0 && endDate.compareTo(maxDate) <= 0;

          if (companyMatch && iataMatch && fieldMatch && dateMatch) {
            total += report.${fieldName};
          }
        }
        return total;
      `,
      params: {
        company_ids: companies,
        iata_ids: iatas,
        dates: {
          field: field,
          start: startDate,
          end: endDate,
        }
      }
    }
  }
};

export const advancedAggs = (companies = [], iatas = [], dates = {}) => {
  return {
    advanced: {
      filter: {
        bool: {
          should: [
            { terms: { '_embedded.company.id': companies } },
            { match: { source: 'sion' } }
          ]
        }
      },
      aggs: {
        open_suggestions: {
          filter: {
            term: {
              'suggestion_stats.status': 'open'
            }
          },
          aggs: {
            count: {
              cardinality: {
                field: 'id'
              }
            }
          }
        },
        bookings: {
          filter: getAdvanceSupplierWithBookingsQuery(true, companies, iatas, dates),
          aggs: {
            count: {
              cardinality: {
                field: 'id'
              }
            }
          }
        }
      }
    }
  }
};

const getAdvanceSupplierWithBookingsQuery = (
  bookings = false,
  companies = [],
  iatas = [],
  dates = {}
) => {
  if (!bookings) { return }

  const queries = [
    { range: { '_embedded.production_reports.booking_count': { 'gte': 1 } } }
  ];

  if (companies?.length > 0) {
    queries.push({ terms: { '_embedded.production_reports.company_id': companies } });
  }
  if (iatas?.length > 0) {
    queries.push({ terms: { '_embedded.production_reports.iata_id': iatas } });
  }
  const { startDate, endDate, field } = dates;
  if (startDate) {
    queries.push({ range: { '_embedded.production_reports.dates.start': { 'gte': startDate } } });
  }
  if (endDate) {
    queries.push({ range: { '_embedded.production_reports.dates.end': { 'lte': endDate } } });
  }
  if (field) {
    queries.push({ match: { '_embedded.production_reports.dates.field': field } });
  }

  return {
    nested: {
      path: '_embedded.production_reports',
      query: {
        bool: {
          must: queries
        }
      }
    }
  }
}

const getWithSuggestionsQuery = (suggestionTypes) => {
  const suggestionQueries = [];


  if (suggestionTypes.includes('confirmed')) {
    suggestionQueries.push({
      terms: { 'suggestion_stats.status': ['confirmed'] }
    });
  }

  if (suggestionTypes.includes('dismissed')) {
    suggestionQueries.push({
      terms: { 'suggestion_stats.status': ['dismissed'] }
    });
  }

  if (suggestionTypes.includes('open')) {
    const openQuery = {
      bool: {
        must: [
          {
            terms: { 'suggestion_stats.status': ['open'] }
          }
        ]
      }
    };

    const confidenceQueries = [];
    // confidence levels
    if (suggestionTypes.includes('open_high')) {
      confidenceQueries.push({
        match: { 'suggestion_stats.confidence': 'high' }
      });
    }

    if (suggestionTypes.includes('open_medium')) {
      confidenceQueries.push({
        match: { 'suggestion_stats.confidence': 'medium' }
      });
    }

    if (suggestionTypes.includes('open_low')) {
      confidenceQueries.push({
        match: { 'suggestion_stats.confidence': 'low' }
      });
    }
    if (confidenceQueries.length) {
      openQuery.bool.must.push({
        bool: {
          should: confidenceQueries
        }
      })
    }
    suggestionQueries.push(openQuery);
  }

  return suggestionQueries;
};

const getAdvancedSupplierSourceQuery = (filters, suggestionTypes) => {
  const isVerified = filters.includes('verified');
  const isManual = filters.includes('manual');
  const isNoSuggestions = filters.includes('no_suggestions');
  const isWithSuggestions = filters.includes('with_suggestions');

  if (!isVerified && !isManual) return;

  const sources = [];
  if (isVerified) {
    sources.push('sion');
  }
  if (isManual) {
    sources.push('manual');
  }

  const termsQuery = {
    bool: {
      must: [
        { terms: { source: sources } }
      ],
    }
  };

  if (isManual) {
    const shouldClauses = [];

    if (isNoSuggestions) {
      shouldClauses.push({
        terms: { 'suggestion_stats.status': ['none'] }
      });
    }

    if (isWithSuggestions && suggestionTypes.length) {
      const withSuggestionsQueries = getWithSuggestionsQuery(suggestionTypes);
      withSuggestionsQueries.forEach(query => {
        shouldClauses.push(query);
      });
    }

    if (shouldClauses.length > 0) {
      termsQuery.bool.should = shouldClauses;
      termsQuery.bool.minimum_should_match = 1; // Ensures at least one should clause matches
    }
  }

  return termsQuery;
};

export const getAdvancedSupplierQuery = (
  advancedFilters = [],
  companies = [],
  iatas = [],
  dates,
  suggestionTypes = [],
  isWithBookings = false
) => {

  const query = [
    getAdvancedSupplierSourceQuery(advancedFilters, suggestionTypes),
    getAdvanceSupplierWithBookingsQuery(isWithBookings, companies, iatas, dates)
  ];

  return query.filter((q) => q !== undefined);
}

export const getLocationAirportsQuery = (airports, prefix = '') => {
  if (airports?.length === 0) return;

  const airportCodes = airports.map(({ code }) => code);
  return {
    nested: {
      path: prefix + 'location.nearby_places',
      query: {
        bool: {
          must: [
            { match: { [prefix + 'location.nearby_places.place.type']: 'airport' } },
            { terms: { [prefix + 'location.nearby_places.place.data.code']: airportCodes } }
          ]
        }
      }
    }
  };
}

export const getLocationCitiesQuery = (cities, prefix = '') => {
  if (cities?.length === 0) return;

  const cityIds = cities.map(({ id }) => id);
  const cityCodes = cities.map(({ code }) => code);
  const cityNameQueries = cities.map(({ name }) => {
    return {
      match: {
        [prefix + 'location.address.components.city']: name
      }
    }
  });
  return {
    bool: {
      should: [
        { terms: { [prefix + 'location.base_city.code']: cityCodes } },
        {
          bool: {
            should: cityNameQueries
          }
        },
        {
          nested: {
            path: prefix + 'location.nearby_places',
            query: {
              bool: {
                must: [
                  { match: { [prefix + 'location.nearby_places.place.type']: 'city' } },
                  { terms: { [prefix + 'location.nearby_places.place.data.id']: cityIds } }
                ]
              }
            }
          }
        }
      ]
    }
  };
}

export const getLocationCountriesQuery = (countries, prefix = '') => {
  if (countries?.length === 0) return;

  const iso2Codes = countries.map(({ iso2 }) => iso2);
  return {
    bool: {
      should: [
        { terms: { [prefix + 'location.address.components.country_iso2']: iso2Codes } },
        { terms: { [prefix + 'location.address.country.iso2']: iso2Codes } },
      ]
    }
  };
}

export const getLocationNeighborhoodsQuery = (neighborhoods, prefix = '') => {
  if (neighborhoods?.length === 0) return;

  const neighborhoodIds = neighborhoods.map(({ id }) => id);
  return {
    bool: {
      must: [
        { terms: { [prefix + 'location.neighborhood.id']: neighborhoodIds } }
      ]
    }
  }
}

export const getLocationGeographicRegionsQuery = (geographicRegions, prefix = '') => {
  if (geographicRegions.length === 0) return;

  const regionIds = geographicRegions.map(({ id }) => id);
  return {
    bool: {
      must: [
        { terms: { [prefix + 'location.address.region_ids']: regionIds } }
      ]
    }
  }
}

export const getLocationQuery = (locationFilters = [], prefix = '') => {
  const airports = locationFilters.filter(({ type }) => type === 'sion_airports');
  const cities = locationFilters.filter(({ type }) => type === 'sion_cities');
  const countries = locationFilters.filter(({ type }) => type === 'sion_countries');
  const neighborhoods = locationFilters.filter(({ type }) => type === 'sion_neighborhoods');
  const geographicRegions = locationFilters.filter(({ type }) => type === 'sion_geographic_regions');

  const query = [
    getLocationAirportsQuery(airports, prefix),
    getLocationCitiesQuery(cities, prefix),
    getLocationCountriesQuery(countries, prefix),
    getLocationNeighborhoodsQuery(neighborhoods, prefix),
    getLocationGeographicRegionsQuery(geographicRegions, prefix)
  ];
  return query.filter((q) => q !== undefined);
}
