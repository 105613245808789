import Vue from 'vue';
import Router from 'vue-router';
import socket from '@/plugins/socket';
import store from '@/store';
import { reportError } from '@/utils/helpers/tracking/tracking-methods';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.name === from.name && to.query.page === from.query.page) {
      return false;
    }
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: () => ({ name: 'login' }),
    },
    {
      path: '/agents',
      name: 'agents',
      component: () => import(/* webpackChunkName: "agents" */ '@/views/Agents.vue'),
    },
    {
      path: '/batches/:id',
      name: 'batch',
      component: () => import(/* webpackChunkName: "batch" */ '@/views/Batch.vue'),
      props: (route) => ({
        id: route.params.id,
        entryId: route.query.entry,
      }),
    },
    {
      path: '/bookings',
      name: 'bookings',
      component: () => import(/* webpackChunkName: "bookings" */ '@/views/Bookings.vue'),
      props: (route) => ({
        followUp: route.query.followUp || route.query.follow_up,
      }),
    },
    {
      path: '/clients/:id',
      name: 'client',
      component: () => import(/* webpackChunkName: "client" */ '@/views/contacts/client/Client.vue'),
      props: (route) => ({
        id: route.params.id,
      }),
    },
    {
      path: '/clients',
      name: 'clients',
      component: () => import(/* webpackChunkName: "contacts" */ '@/views/contacts/Clients.vue'),
      props: () => ({
        mode: 'clients',
      }),
    },
    {
      path: '/suppliers/brands-chains/:id',
      name: 'supplierGroup',
      component: () => import(/* webpackChunkName: "supplier" */ '@/views/contacts/supplier-group/SupplierGroup.vue'),
      props: (route) => ({
        id: route.params.id,
      }),
    },
    {
      path: '/suppliers/:id',
      name: 'supplier',
      component: () => import(/* webpackChunkName: "supplier" */ '@/views/contacts/supplier/Supplier.vue'),
      props: (route) => ({
        id: route.params.id,
        defaultTab: route.params.defaultTab || null
      }),
    },
    {
      path: '/suppliers',
      name: 'suppliers',
      component: () => import(/* webpackChunkName: "contacts" */ '@/views/contacts/Suppliers.vue'),
      props: () => ({
        mode: 'suppliers',
        defaultTab: 0,
      })
    },
    {
      path: '/commissions',
      name: 'commissions',
      component: () => import(/* webpackChunkName: "commissions" */ '@/views/Commissions.vue'),
    },
    {
      path: '/import',
      name: 'import',
      component: () => import(/* webpackChunkName: "import" */ '@/views/Import.vue'),
    },
    {
      path: '/invoices/:id',
      name: 'invoiceFollowUp',
      component: () => import(/* webpackChunkName: "invoiceFollowUp" */ '@/views/InvoiceFollowUp.vue'),
      props: (route) => ({
        id: route.params.id,
      }),
    },
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
      props: (route) => ({
        email: route.query.email,
      }),
    },
    {
      path: '/onboarding',
      name: 'onboardingStepOne',
      component: () => import(/* webpackChunkName: "onboarding" */ '@/views/onboarding/OnboardingStepOne.vue'),
      props: (route) => ({
        inviteId: route.query.invite_id,
        modeKey: route.query.mode_key,
      }),
    },
    {
      path: '/onboarding/step-two',
      name: 'onboardingStepTwo',
      component: () => import(/* webpackChunkName: "onboardingStepTwo" */ '@/views/onboarding/OnboardingStepTwo.vue'),
    },
    {
      path: '/onboarding/step-three',
      name: 'onboardingStepThree',
      component: () => import(/* webpackChunkName: "onboardingStepThree" */ '@/views/onboarding/OnboardingStepThree.vue'),
    },
    {
      path: '/onboarding/step-four',
      name: 'onboardingStepFour',
      component: () => import(/* webpackChunkName: "onboardingStepFour" */ '@/views/onboarding/OnboardingStepFour.vue'),
    },
    {
      path: '/onboarding/step-final',
      name: 'onboardingStepFinal',
      component: () => import(/* webpackChunkName: "onboardingStepFinal" */ '@/views/onboarding/OnboardingStepFinal.vue'),
    },
    {
      path: '/reset_request',
      name: 'passwordResetRequest',
      component: () =>
        import(/* webpackChunkName: "passwordResetRequest" */ '@/views/password-reset-request/PasswordResetRequest.vue'),
    },
    {
      path: '/reset/:resetKey',
      name: 'passwordReset',
      component: () => import(/* webpackChunkName: "passwordReset" */ '@/views/PasswordReset.vue'),
      props: true,
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import(/* webpackChunkName: "settings" */ '@/views/account-settings/index.vue'),
      redirect: { name: 'accountSettings' },
      children: [
        {
          path: 'account',
          name: 'accountSettings',
          component: () => import(/* webpackChunkName: "account settings" */ '@/views/account-settings/AccountTab.vue'),
        },
        {
          path: 'batches',
          name: 'batchSettings',
          component: () => import(/* webpackChunkName: "account settings" */ '@/views/account-settings/BatchesTab.vue'),
        },
        // Hidden until we make it truly functional
        // {
        //   path: 'billing',
        //   name: 'billingSettings',
        //   component: () => import(/* webpackChunkName: "account settings" */ '@/views/account-settings/BillingTab.vue'),
        // },
        {
          path: 'contractors',
          name: 'contractorSettings',
          component: () => import(/* webpackChunkName: "account settings" */ '@/views/account-settings/AgencyTab.vue'),
          props: (route) => ({
            mode: 'ic',
            query: route.query?.query || ''
          }),
        },
        {
          path: 'employees',
          name: 'employeeSettings',
          component: () => import(/* webpackChunkName: "account settings" */ '@/views/account-settings/EmployeesTab.vue'),
          props: (route) => ({
            companyIds: route.query.company_ids ? route.query.company_ids.split(',') : null,
          }),
        },
        {
          path: 'host-agencies',
          name: 'hostAgencySettings',
          component: () => import(/* webpackChunkName: "account settings" */ '@/views/account-settings/AgencyTab.vue'),
          props: {
            mode: 'ha',
          },
        },
        {
          path: 'iatas',
          name: 'iataSettings',
          component: () => import(/* webpackChunkName: "account settings" */ '@/views/account-settings/IatasTab.vue'),
        },
        {
          path: 'integrations',
          name: 'integrationSettings',
          component: () => import(/* webpackChunkName: "account settings" */ '@/views/account-settings/IntegrationsTab.vue'),
        },
        {
          path: 'invoices',
          name: 'invoiceSettings',
          component: () => import(/* webpackChunkName: "account settings" */ '@/views/account-settings/CommissionChasingTab.vue'),
        },
        {
          path: 'tags',
          name: 'tagSettings',
          component: () => import(/* webpackChunkName: "account settings" */ '@/views/account-settings/TagsTab.vue'),
        },
      ],
    },
    {
      path: '/statements/:id',
      name: 'statement',
      component: () => import(/* webpackChunkName: "statement" */ '@/views/Statement.vue'),
      props: true,
    },
    {
      path: '/statements',
      name: 'statements',
      component: () => import(/* webpackChunkName: "statements" */ '@/views/Statements.vue'),
    },
    {
      path: '/source_connections/:id',
      name: 'sourceConnection',
      component: () =>
        import(/* webpackChunkName: "sourceConnection" */ '@/views/SourceConnection.vue'),
      props: (route) => ({
        id: route.params.id,
      })
    },
  ],
});

const bootstrapApp = async (employee) => {
  await Promise.all([
    getCategories(),
    getCurrencies(),
    getCompany(employee),
    getDownloadPresets(),
    getIntegrations(employee),
    getNotifications(),
    getSourceConnections(employee),
    getPreferredPrograms()
  ]);
};

const bootstrapEmployee = async (token) => {
  if (store.getters['users/userSelf'].id === undefined) {
    store.commit('tokens/SET_TOKEN', token);

    try {
      const { data } = await store.dispatch('users/doGetUserSelf', {
        zoom: 'default_employer,employers',
      });
      const [user] = data.users;
      bootIntercom(user.full_name, user.email);
      websocketAuth(token);
      return Promise.resolve(user._embedded.default_employer);
    } catch {
      return Promise.reject();
    }
  }

  const employee = store.getters['employees/currentEmployee'];

  // TODO expired doesn't work
  const isExpired = store.getters['employees/currentEmployeeExpired'];
  if (!isExpired) return employee;

  try {
    const id = store.getters['employees/currentEmployee'].id;
    await store.dispatch('employees/doGetEmployee', { id });
    return store.getters['employees/employee'];
  } catch (error) {
    reportError(error);
    return Promise.reject();
  }
};

const checkPermissions = (to, employee) => {
  const permissionedRoutes = ['settings', 'statement'];
  if (!permissionedRoutes.includes(to.name)) return true;
  switch (to.name) {
    case 'settings':
      return employee.access.company.settings.read;
    case 'statement':
      return employee.access.personal.bookings.read;
    default:
      return true;
  }
};

const bootIntercom = (userName, userEmail) => {
  window.Intercom('boot', {
    app_id: process.env.VUE_APP_INTERCOM_APP_ID,
    custom_launcher_selector: '.intercom-launcher',
    hide_default_launcher: true,
    name: userName || null,
    email: userEmail || null,
  });
};

const getCategories = () => {
  if (store.getters['bookingCategories/bookingCategories'].length === 0) {
    return store.dispatch('bookingCategories/doGetBookingCategories');
  }
  return Promise.resolve();
};

const getCurrencies = () => {
  if (store.getters['currencies/currencies'].length === 0) {
    return store.dispatch('currencies/doGetCurrencies', {
      zoom: 'exchange_rates',
    });
  }
  return Promise.resolve();
};

const getCompany = (employee) => {
  if (store.getters['companies/company'].id) return Promise.resolve();
  return store.dispatch('companies/doGetCompany', {
    id: employee.company_id,
    zoom: 'address,booking_ranges,contractor_configuration,created_by,employees,host_agencies,iata_plans,iatas,logo,customer',
  });
};

const getContractors = (employee) => {
  if (employee.access.ic.bookings.read && !store.getters['companies/companyContractorsChecked']) {
    store.commit('companies/SET_COMPANIES_ATOMIC', { key: 'contractorsChecked', val: true });
    const companyId = employee.company_id;
    return store.dispatch('companies/doGetCompanyContractors', companyId);
  }
  return Promise.resolve();
};

const getDownloadPresets = () => {
  if (store.getters['downloads/downloadPresets'].length) {
    return Promise.resolve();
  }
  return store.dispatch('downloads/doGetDownloadPresets', {
    host: true,
    public: true,
    zoom: 'columns,integration_destination',
  });
};

const getIntegrations = (employee) => {
  if (employee.access.company.settings.read && !store.getters['integrations/integrationsChecked']) {
    return store.dispatch('integrations/doGetIntegrations', {
      id: employee.company_id,
      host: true,
      zoom: 'identity_fields'
    });
  }
  return Promise.resolve();
};

const getNotifications = () => {
  if (!store.getters['notifications/notificationsChecked']) {
    store.commit('notifications/RESET_NOTIFICATIONS', 'filters');
    store.commit('notifications/SET_NOTIFICATIONS_ATOMIC', { key: 'checked', val: true });
    const payload = store.getters['notifications/notificationsEsQuery'];
    try {
      return store.dispatch('notifications/doGetNotifications', payload);
    } catch (error) {
      reportError(error);
    }
  }
  return Promise.resolve();
};

const getSourceConnections = (employee) => {
  const sourceConnections = store.getters['sourceConnections/sourceConnections'];
  const hasReadAccess = employee.access.company.settings.read;
  if (hasReadAccess && sourceConnections.length === 0) {
    return store.dispatch('sourceConnections/doGetSourceConnections', {
      id: employee.company_id,
      host: true,
      zoom: 'branches,company',
    });
  }
  return Promise.resolve();
};

const getPreferredPrograms = () => {
  return store.dispatch('preferredPrograms/doGetPreferredPrograms', {});
};

const noAccess = (to, next, message) => {
  store.commit('app/SET_APP_ATOMIC', {
    key: 'redirect',
    val: {
      name: to.name,
      params: to.params,
      query: to.query,
    },
  });
  store.commit('tokens/UNSET_TOKEN');
  store.commit('app/SET_APP_SNACKBAR', { type: 'is-danger', message });
  next({ name: 'login' });
};

const websocketAuth = (token) => {
  if (token) {
    socket.io.opts.query = { token };
    socket.open();
  }
};

router.beforeEach(async (to, from, next) => {
  store.commit('app/SET_APP_ATOMIC', { key: 'searching', val: false });
  if (to.name !== from.name) {
    store.commit('app/SET_APP_ATOMIC', { key: 'drawer.id', val: null });
  }
  const token = store.getters['tokens/token'];
  if (store.getters['app/appPublicRoutes'].includes(to.name)) {
    next();
  } else if (token) {
    try {
      const employee = await bootstrapEmployee(token);
      store.commit('employees/SET_CURRENT_EMPLOYEE', employee);
      await bootstrapApp(employee);
      if (checkPermissions(to, employee)) {
        next();
        getContractors(employee);
      }
      else noAccess(to, next, 'Insufficient permissions');
    } catch {
      noAccess(to, next, 'Insufficient permissions');
    }
  } else {
    noAccess(to, next, 'Insufficient permissions');
  }
});

router.afterEach(async(to, from) => {
  if(from.name === 'login' ) {
    store.commit('app/SET_APP_CHECK_BROADCAST_MESSAGE', true);
  }
  if (to.params.openNotifications) {
    Vue.nextTick(() => {
      store.commit('app/SET_APP_ATOMIC', { key: 'drawer.prev', val: null });
      store.commit('app/SET_APP_ATOMIC', { key: 'drawer.type', val: 'notification' });
      store.commit('app/SET_APP_ATOMIC', { key: 'drawer.id', val: 'notification' });
    });
  }
});

export default router;
