import { apiClient } from '../apiClient';

const DOMAIN = 'payments';

export default {
  create: ({ id, ...payload }) => apiClient.post(`/bookings/${id}/${DOMAIN}`, payload),
  get: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}`, { params }),
  update: ({ id, ...payload }) => apiClient.patch(`/${DOMAIN}/${id}`, payload),
  delete: ({ id, ...payload }) => apiClient.delete(`/${DOMAIN}/${id}`, payload),
  getAll: ({ id, ...params }) => apiClient.get(`/bookings/${id}/${DOMAIN}`, { params }),
  search: (payload) => apiClient.post(`/sion_${DOMAIN}/_search`, payload),
  getActions: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}/actions`, { params }),
  getAttachments: async ({ id }) => {
    // temp solution as payments attachments endpoint returns a 401
    const { data } = await apiClient.get(`/${DOMAIN}/${id}`, { params: { zoom: 'attachments'} });
    const response = { data: data.payments[0]._embedded };
    return response;
  },
};
