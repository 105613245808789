import dayjs from 'dayjs';

export const cleanProductionData = (aggregations, reportingCategory) => {
  const isAllTime = reportingCategory.category === 'ALL';
  // We'll store the production in an object so we don't lose sort order
  const productionForMonth = ({ booking_count: bookingCount, revenue, commission}) => {
    return {
      booking_count: bookingCount.value,
      revenue: revenue.value,
      commission: commission.value,
    }
  };

  const currentPeriod = aggregations.production_reports?.current_period;
  const currentPeriodQuarters = currentPeriod?.by_quarter?.buckets;
  const previousPeriod = aggregations.production_reports?.previous_period;
  const previousPeriodQuarters = previousPeriod?.by_quarter?.buckets;


  // Each quarter contains three months: A, B, and C. They may return from ES in any order, so we need to set the order explicitly.
  const monthKeys = [
    'month_a',
    'month_b',
    'month_c',
  ];

  const compileMonthsPerPeriod = (quarters = []) => {
    // Iterate over the quarters and add the results to the production object.
    const productionReports = {};
    quarters.forEach((quarter) => {
      monthKeys.forEach((monthKey) => {
        const [month] = quarter[monthKey].buckets;
        if (!isAllTime && quarter[monthKey].buckets.length === 0) {
          let monthKeyAsString = '';
          switch(monthKey) {
            case 'month_a':
            default:
              monthKeyAsString = quarter.key_as_string;
              break;
            case 'month_b':
              monthKeyAsString = dayjs(quarter.key_as_string).add(1, 'month').format('YYYY-MM-DD');
              break;
            case 'month_c':
              monthKeyAsString = dayjs(quarter.key_as_string).add(2, 'month').format('YYYY-MM-DD');
              break;
          }
          productionReports[monthKeyAsString] = {
            booking_count: 0,
            revenue: 0,
            commission: 0,
          };
        } else if (month?.key_as_string) {
          productionReports[month.key_as_string] = productionForMonth(month);
        }
      });
    });
    return productionReports;
  }

  const currentPeriodProduction = compileMonthsPerPeriod(currentPeriodQuarters);
  const previousPeriodProduction = compileMonthsPerPeriod(previousPeriodQuarters);

  return {
    current_period: currentPeriodProduction || {},
    previous_period: previousPeriodProduction || {},
  }
}
