import { apiClient } from '../apiClient';

const DOMAIN = 'batches';

export default {
  create: ({ id, ...payload }) => apiClient.post(`/companies/${id}/${DOMAIN}`, payload),
  get: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}`, { params }),
  update: ({ id, ...payload }) => apiClient.patch(`/${DOMAIN}/${id}`, payload),
  delete: ({ id, ...payload }) => apiClient.delete(`/${DOMAIN}/${id}`, payload),
  getAll: (params) => apiClient.get(`/${DOMAIN}`, { params }),
  search: (payload) => apiClient.post(`/sion_${DOMAIN}/_search`, payload),

  getEntry: ({ id, entry_id,  ...params }) => {
    return apiClient.get(`/${DOMAIN}/${id}/entries/${entry_id}`, { params })
  },
  updateEntry: ({ id, entry_id,  ...payload }) => {
    return apiClient.patch(`/${DOMAIN}/${id}/entries/${entry_id}`, payload)
  },
  getEntries: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}/entries`, { params }),
  searchEntries: (payload) => apiClient.post('/sion_batch_entries/_search', payload),
};
