import { cloneDeep } from '@/utils/helpers/methods';
import { HTTP } from '@/utils/http';

const defaultSubscription = {
  _embedded: {
    stripe_subscription: {},
  },
};

const state = {
  subscription: cloneDeep(defaultSubscription),
};

const mutations = {
  SET_SUBSCRIPTION(state, payload) {
    state.subscription = payload;
  },
};

const getters = {
  subscription: (state) => state.subscription,
};

const actions = {
  async doCancelSubscription({ commit }, payload) {
    // used?
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.delete(`/subscriptions/${id}`, params);
    if (!ignore) commit('SET_SUBSCRIPTION', data.data.subscriptions[0]);
    return data;
  },
  async doCreateSubscription({ commit }, payload) {
    const { data } = await this._vm.$api.companies.createSubscription(payload);
    commit('SET_SUBSCRIPTION', data.subscriptions[0]);
  },
  async doGetSubscription({ commit }, payload) {
    // used?
    const { data } = await this._vm.$api.companies.getSubscription(payload);
    const [subscription] = data.subscriptions;
    if (subscription) commit('SET_SUBSCRIPTION', subscription);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
