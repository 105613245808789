import Vue from 'vue';
import { dayjs } from '@/plugins/dayjs';
import { cloneDeep } from '@/utils/helpers/methods';

const defaultInvoiceSchedule = {
  dates: [1, 15],
  rule: 'monthly',
  interval: ['monthly'],
}

const defaultEmployee = {
  type: 'agent',
  access: {
    personal: {
      bookings: {
        read: true,
        write: true,
      },
      clients: {
        read: true,
        write: true,
      },
    },
    company: {
      batches: {
        read: false,
        write: false,
      },
      bookings: {
        read: false,
        write: false,
      },
      clients: {
        read: false,
        write: false,
      },
      imports: {
        read: false,
        write: false,
      },
      invoices: {
        send: false,
        send_config: false,
      },
      settings: {
        read: false,
        write: false,
      },
    },
    ic: {
      bookings: {
        read: false,
        write: false,
      },
      clients: {
        read: false,
        write: false,
      },
    },
  },
  commission_default: false,
  commission_split: 50,
  commission_split_basis: 'net',
  company_id: null,
  is_commissionable: false,
  _embedded: {
    address: {},
    integration_identities: [],
    invoice_schedule: cloneDeep(defaultInvoiceSchedule),
    source_identities: [],
    user: {
      is_enabled: true,
    },
  },
};

const defaultFilters = {
  companies: [],
  company: 'custom',
  login: 'all',
  status: 'enabled',
  type: 'all',
  query: null,
};

const state = {
  current: {},
  employee: {},
  employees: [],
  expires: null,
  filters: cloneDeep(defaultFilters),
  refresh: false,
  pagination: {
    page: 1,
    per: 10,
    total: 0,
  },
};

const mutations = {
  ADD_EMPLOYEE_TO_EMPLOYEES(state, payload) {
    // used ?
    state.employees.unshift(payload);
  },
  ADD_INTEGRATION_IDENTITY_TO_EMPLOYEE(state, payload) {
    state.employee._embedded.integration_identities.splice(payload.index, 0, payload.val);
  },
  ADD_SOURCE_IDENTITY_TO_EMPLOYEE(state, payload) {
    // used ?
    state.employee._embedded.source_identities.splice(payload.index, 0, payload.val);
  },
  REMOVE_INTEGRATION_IDENTIY_FROM_EMPLOYEE(state, index) {
    // used ?
    state.employee._embedded.integration_identities.splice(index, 1);
  },
  REMOVE_SOURCE_IDENTIY_FROM_EMPLOYEE(state, index) {
    state.employee._embedded.source_identities.splice(index, 1);
  },
  SET_CURRENT_EMPLOYEE(state, payload) {
    state.expires = dayjs().add(5, 'minutes');
    state.current = payload;
  },
  SET_EMPLOYEE(state, employee) {
    if (!employee._embedded.invoice_schedule) {
      employee = {
        ...employee,
        _embedded: {
          ...employee._embedded,
          invoice_schedule: cloneDeep(defaultInvoiceSchedule)
        }
      }
    }
    state.employee = employee;
  },
  SET_EMPLOYEE_DEFAULT(state) {
    state.employee = cloneDeep(defaultEmployee);
  },
  SET_EMPLOYEES_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
  SET_EMPLOYEE_FOR_EMPLOYEES(state, payload) {
    const index = state.employees.findIndex((employee) => employee.id === payload.id);
    if (index >= 0) {
      Vue.set(state.employees, index, payload);
    }
  },
  SET_EMPLOYEES_REFRESH(state, payload) {
    state.refresh = payload;
  },
  SET_EMPLOYEES(state, payload) {
    state.employees = payload;
  },
  SET_INTEGRATION_IDENTITY_FIELD_FOR_EMPLOYEE(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state.employee._embedded.integration_identities[payload.index];
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
  SET_SOURCE_IDENTITY_FIELD_FOR_EMPLOYEE(state, payload) {
    // used ?
    const nodes = payload.key.split('.');
    let obj = state.employee._embedded.source_identities[payload.index];
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
  TOGGLE_EMPLOYEE_FIELD(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state.employee;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, !obj[node]);
      } else {
        obj = obj[node];
      }
    });
  },
};

const getters = {
  currentEmployee: (state) => state.current,
  currentEmployeeExpired: ({ expires }) => (!expires ? true : dayjs().isAfter(expires)),
  employee: (state) => state.employee,
  employeesRefresh: (state) => state.refresh,
  employees: (state) => state.employees,
  employeesFilters: (state) => state.filters,
  employeesPagination: (state) => state.pagination,
  employeesEsQuery({ filters }) {
    // not used ?
    const filter = {
      bool: {
        must: [],
        should: [],
      },
    };
    if (filters.companies.length > 0) {
      // filter.bool.must();
    }
    return {
      query: {
        constant_score: {
          filter,
        },
      },
    };
  },
};

const actions = {
  async doCreateEmployee({ commit }, payload) {
    const { data } = await this._vm.$api.employees.create(payload);
    commit('SET_EMPLOYEE', data.employees[0]);
  },
  async doUpdateEmployee({ commit }, payload) {
    const { data } = await this._vm.$api.employees.update(payload);
    commit('SET_EMPLOYEE', data.employees[0]);
  },
  async doDeleteEmployee({ commit }, payload) {
    const { data } = await this._vm.$api.employees.delete(payload);
    commit('SET_EMPLOYEE', data.employees[0]);
  },
  async doGetEmployee({ commit }, payload) {
    const { data } = await this._vm.$api.employees.get(payload);
    commit('SET_EMPLOYEE', data.employees[0]);
  },
  async doGetEmployees({ commit }, payload) {
    const { data, record_count } = await this._vm.$api.employees.getAll(payload);
    commit('SET_EMPLOYEES', data.employees);
    commit('SET_EMPLOYEES_ATOMIC', { key: 'pagination.total', val: record_count });
  },
  async doSearchEmployees({ commit }, payload) {
    // used ?
    const { data } = await this._vm.$api.$api.employees.search(payload);
    const employees = data.hits.hits.map((hit) => hit._source);
    commit('SET_EMPLOYEES', employees);
  },
  async doCreateSignature({ commit }, payload) {
    const { data } = await this._vm.$api.employees.createSignature(payload);
    commit('SET_EMPLOYEES_ATOMIC', { key: 'current._embedded.signature', val: data.signatures[0] });
  },
  async doUpdateSignature({ commit }, payload) {
    const { data } = await this._vm.$api.employees.updateSignature(payload);
    commit('SET_EMPLOYEES_ATOMIC', { key: 'current._embedded.signature', val: data.signatures[0] });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
