import Vue from 'vue';
import { dayjs } from '@/plugins/dayjs';
import { dateFormatUTC, dateFormatYMD } from '@/utils/helpers/dates';
import { cloneDeep } from '@/utils/helpers/methods';
import { HTTP, SEARCH } from '@/utils/http';

const queryFields = [
  { key: 'number', label: 'Sion Reference Number' },
  { key: 'name', label: 'Trip Name' },
];

const defaultTraveler = {};

const defaultAggs = {
  count: {
    value: 0,
  },
};

const defaultTrip = {
  type: 'leisure',
  currency: {},
  dates: {
    end: null,
    start: null,
  },
  _embedded: {
    bookings: [],
    tags: {
      trip: [],
    },
    travelers: [],
  },
};

const defaultPagination = {
  page: 1,
  per: 25,
  total: 0,
};

const state = {
  aggs: cloneDeep(defaultAggs),
  dateFields: [
    { key: 'created_at', label: 'Created' },
    { key: 'dates.start', label: 'Start Date' },
    { key: 'dates.end', label: 'End Date' },
  ],
  deletedTags: [],
  editing: cloneDeep(defaultTrip),
  editingTraveler: cloneDeep(defaultTraveler),
  pagination: cloneDeep(defaultPagination),
  traveler: cloneDeep(defaultTraveler),
  trip: cloneDeep(defaultTrip),
  refresh: false,
  selected: [],
  sync: false,
  queryFields: cloneDeep(queryFields),
};

const mutations = {
  ADD_BOOKING_FOR_TRIP(state, payload) {
    state.editing._embedded.bookings.push(payload);
  },
  ADD_TRAVELER_FOR_TRIP(state, payload) {
    state.editing._embedded.travelers.push(payload);
  },
  REMOVE_BOOKING_FOR_TRIP(state, payload) {
    const { index } = payload;
    state.editing._embedded.bookings.splice(index, 1);
  },
  REMOVE_TRAVELER_FOR_TRIP(state, payload) {
    const { index } = payload;
    state.editing._embedded.travelers.splice(index, 1);
  },
  RESET_TRIPS(state, payload) {
    switch (payload) {
      case 'editing':
        state.editing = cloneDeep(defaultTrip);
        break;
      case 'selected':
        state.selected = [];
        break;
      case 'trip':
        state.trip = cloneDeep(defaultTrip);
        break;
      case 'pagination':
        state.pagination = cloneDeep();
        break;
      default:
    }
  },
  SET_TRIPS_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
  SET_TRAVELER_FOR_TRIP_ATOMIC(state, payload) {
    // used?
    const nodes = payload.key.split('.');
    const { index } = payload;
    let obj = state.editing._embedded.travelers[index];
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
  TOGGLE_SELECTED_TRIPS(state, trip) {
    const index = state.selected.findIndex((selectedItem) => selectedItem.id === trip.id);
    if (index >= 0) {
      state.selected.splice(index, 1);
    } else {
      state.selected.push(trip);
    }
  },
  TOGGLE_TRIP_TAG(state, payload) {
    const { key, val } = payload;
    const { id } = val;
    const index = state[key]._embedded.tags.trip.findIndex((tripTag) => tripTag.id === id);
    const deletedIndex = state.deletedTags.findIndex((deletedTag) => deletedTag.id === id);
    if (index < 0) {
      state[key]._embedded.tags.trip.push(val);
      if (deletedIndex >= 0) {
        state.deletedTags.splice(deletedIndex, 1);
      }
    } else {
      state[key]._embedded.tags.trip.splice(index, 1);
      if (deletedIndex < 0) {
        state.deletedTags.push(val);
      }
    }
  },
};

const getters = {
  traveler: (state) => state.traveler, // used?
  travelerEditing: (state) => state.editingTraveler,
  trip: (state) => state.trip,
  tripEditing: (state) => state.editing,
  tripDeletedTags: (state) => state.deletedTags,
  trips: (state) => state.trips,
  tripsSelected: (state) => state.selected,
  tripsRefresh: (state) => state.refresh,
  tripsSync: (state) => state.sync,
  tripsAggregations: (state) => state.aggs, // used?
  tripsDateFields: (state) => state.dateFields,
  tripsEsQuery(state, _getter, _rootState, rootGetters) {
    const { pagination } = state;
    const { page, per } = pagination;
    const company = rootGetters['companies/company'];
    const companyTimeZoneOffset = company.time_zone.offset;
    const bookingFilters = rootGetters['bookings/bookingsFilters'];
    const from = per * (page - 1);
    const must = [];

    const agencyIds = bookingFilters.companies;
    if (agencyIds.length > 0) {
      must.push(rootGetters['quickSearchFilter/getCompaniesFilter'](agencyIds));
    }
    must.push(rootGetters['quickSearchFilter/getCompaniesFilter']([company.id], 'company_ids'));

    const { dates, clients, fields, iatas, suppliers, tags, trips } = bookingFilters;

    if (clients.length > 0) {
      const clientIds = clients.map((client) => client.id);
      must.push({
        terms: { client_ids: clientIds },
      });
    }
    //Todo(master-data): replace with locations
    // if (destinations.length > 0) {
    //   const ids = destinations.map((destination) => destination.source_id);
    //   const terms = { destination_ids: ids };
    //   must.push({ terms });
    // }
    if (iatas.length > 0) {
      must.push({
        terms: { iata_ids: iatas },
      });
    }
    if (suppliers.length > 0) {
      const supplierIds = suppliers.map((supplier) => supplier.id);
      must.push({
        terms: { supplier_ids: supplierIds },
      });
    }
    if (tags.length > 0) {
      tags.forEach((tag) => {
        must.push({
          term: { tags: tag },
        });
      });
    }
    if (trips.length > 0) {
      const tripIds = trips.map((trip) => trip.id);
      must.push({
        terms: { id: tripIds },
      });
    }

    const [filterKey] = Object.keys(bookingFilters.sort);
    const sortField = filterKey.match(/name|last_first/i) ? `${filterKey}.raw` : filterKey;
    const sortObj = {};
    sortObj[sortField] = { order: bookingFilters.sort[filterKey] };
    const sort = [sortObj];

    let dateFormat = dateFormatYMD;
    let toDate = null;
    let fromDate = null;
    const { date } = fields;
    if (date === 'created_at') {
      dateFormat = dateFormatUTC;
      fromDate = dayjs(dates.from, dateFormat)
        .utcOffset(companyTimeZoneOffset)
        .startOf('day')
        .format(dateFormat);
      toDate = dayjs(dates.to, dateFormat)
        .utcOffset(companyTimeZoneOffset)
        .endOf('day')
        .format(dateFormat);
    } else {
      fromDate = dayjs(dates.from, dateFormat).format(dateFormat);
      toDate = dayjs(dates.to, dateFormat).format(dateFormat);
    }
    if (dates.year !== 'all') {
      const dateQuery = { range: {} };
      dateQuery.range[date] = {
        gte: fromDate,
        lte: toDate,
      };
      must.push(dateQuery);
    }

    const { queryStrings } = bookingFilters;
    if (queryStrings && queryStrings.length > 0) {
      const queryStringsQuery = {
        bool: {
          should: [],
        },
      };
      queryStrings.forEach((query) => {
        if (query) {
          queryStringsQuery.bool.should.push({
            multi_match: {
              query: query.toUpperCase(),
              fields: fields.query,
              type: 'cross_fields',
              operator: 'and',
            },
          });
        }
      });
      must.push(queryStringsQuery);
    }

    const statuses = bookingFilters.statuses.trips;
    if (statuses) {
      const statusQueries = {
        bool: {
          should: [],
        },
      };
      statuses.forEach((status) => {
        let statusQuery = {};
        switch (status) {
          case 'past':
            statusQuery = {
              bool: {
                must: [{ range: { 'dates.end': { lt: 'now/d' } } }],
              },
            };
            break;
          case 'current':
            statusQuery = {
              bool: {
                must: [
                  { range: { 'dates.start': { lte: 'now/d' } } },
                  { range: { 'dates.end': { gte: 'now/d' } } },
                ],
              },
            };
            break;
          case 'upcoming':
            statusQuery = {
              bool: {
                must: [{ range: { 'dates.start': { gt: 'now/d' } } }],
              },
            };
            break;
          default:
        }
        statusQueries.bool.should.push(statusQuery);
      });
      must.push(statusQueries);
    }

    const payload = {
      aggs: {
        count: {
          cardinality: { field: 'id' },
        },
      },
      from: from >= 0 ? from : 0,
      size: per,
      sort,
      query: {
        constant_score: {
          filter: {
            bool: {
              must,
            },
          },
        },
      },
    };
    return payload;
  },
  tripsPagination: (state) => state.pagination,
  tripsQueryFields: (state) => state.queryFields,
};

const actions = {
  async doCreateTraveler({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.post(`/trips/${id}/travelers`, params);
    if (!ignore) {
      const [val] = data.data.travelers;
      if (val) commit('SET_TRIPS_ATOMIC', { key: 'traveler', val });
    }
    return data;
  },
  async doCreateTrip({ commit }, payload) {
    const { ignore, ...params } = payload;
    const { data } = await HTTP.post('/trips', params);
    if (!ignore) {
      const [val] = data.data.trips;
      if (val) commit('SET_TRIPS_ATOMIC', { key: 'trip', val });
    }
    return data;
  },
  async doCreateTripForPnr({ commit }, payload) {
    const { id, ...params } = payload;
    const { data } = await HTTP.post(`/pnrs/${id}/trip`, params);
    const [val] = data.data.trips;
    if (val) commit('SET_TRIPS_ATOMIC', { key: 'trip', val });
    return data;
  },
  async doDeleteTraveler({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.delete(`/travelers/${id}`, params);
    const [val] = data.data.travelers;
    if (val && !ignore) commit('SET_TRIPS_ATOMIC', { key: 'traveler', val });
    return data;
  },
  async doDeleteTrip({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.delete(`/trips/${id}`, { params });
    if (!ignore) {
      const [val] = data.data.trips;
      if (val) commit('SET_TRIPS_ATOMIC', { key: 'trip', val });
    }
    return data;
  },
  async doGetTrip({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.get(`/trips/${id}`, { params });
    if (!ignore) {
      const [val] = data.data.trips;
      if (val) commit('SET_TRIPS_ATOMIC', { key: 'trip', val });
    }
    return data;
  },
  async doGetTrips({ commit }, payload) {
    const { ignore, ...params } = payload;
    const { data } = await HTTP.get('/trips/', { params });
    if (!ignore) {
      const { trips } = data.data;
      if (trips) commit('SET_TRIPS_ATOMIC', { key: 'trips', val: trips });
    }
    return data;
  },
  async doRemoveBookingFromTrip({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.delete(`/trips/${id}/bookings`, { params });
    if (!ignore) {
      const { trips } = data.data;
      if (trips) {
        const [trip] = trips;
        commit('SET_TRIPS_ATOMIC', { key: 'trip', val: trip });
      }
    }
    return data;
  },
  async doSearchTrips({ commit }, payload) {
    const { ignore, ...params } = payload;
    const { data } = await SEARCH.post('/sion_trips/_search', params);
    if (!ignore) {
      const trips = data.hits?.hits.map((hit) => hit._source) || [];
      const aggs = data.aggregations;
      commit('SET_TRIPS_ATOMIC', { key: 'trips', val: trips });
      commit('SET_TRIPS_ATOMIC', { key: 'aggs', val: aggs });
    }
    return data;
  },
  async doUpdateTraveler({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.patch(`/travelers/${id}`, params);
    if (!ignore) {
      const [val] = data.data.travelers;
      if (val) commit('SET_TRIPS_ATOMIC', { key: 'traveler', val });
    }
    return data;
  },
  async doUpdateTrip({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.patch(`/trips/${id}`, params);
    if (!ignore) {
      const [val] = data.data.trips;
      if (val) commit('SET_TRIPS_ATOMIC', { key: 'trip', val });
    }
    return data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
