import Vue from 'vue';
import { dayjs } from '@/plugins/dayjs';
import { dateFormatUTC, dateFormatYMD } from '@/utils/helpers/dates';
import { pickBy, cloneDeep } from '@/utils/helpers/methods';
import { HTTP, SEARCH } from '@/utils/http';
import BatchHelper from '@/utils/helpers/batch';

const defaultAggs = {
  matched_amount: {
    value: 0,
  },
  matched_entries: {
    value: 0,
  },
  unmatched_amount: {
    value: 0,
  },
  unmatched_entries: {
    value: 0,
  },
  total_amount: {
    value: 0,
  },
  total_entries: {
    value: 0,
  },
};

const defaultBatch = {
  reviewed: {
    close: false,
    multiple: false,
    zero: false,
  },
  _embedded: {
    supplier: {},
    updated_by: {},
  },
};

const state = {
  aggs: cloneDeep(defaultAggs),
  batch: cloneDeep(defaultBatch),
  batches: [],
  dateFields: [
    { key: 'created_at', label: 'Created' },
    { key: 'date', label: 'Payment Date' },
  ],
  editing: cloneDeep(defaultBatch),
  pagination: {
    page: 0,
    per: 25,
    total: 0,
  },
  refresh: false,
  reload: false,
  queryFields: [
    { key: '_embedded.import.file_name', label: 'File Name' },
    { key: 'sender_name', label: 'Sender' },
  ],
  search: {
    mode: 'suggestions',
    proximity: {
      distance: 25,
      enabled: false,
    },
    query: null,
  },
  sort: { created_at: 'desc' },
};

const mutations = {
  DECREMENT_BATCH_BOOKING_ID(state, id) {
    if (state.batch.stats.bookings[id] && state.batch.stats.bookings[id] > 0) {
      state.batch.stats.bookings[id] -= 1;
    }
  },
  INCREMENT_BATCH_BOOKING_ID(state, id) {
    if (!state.batch.stats.bookings[id]) {
      state.batch.stats.bookings[id] = 0;
    }
    state.batch.stats.bookings[id] += 1;
  },
  SET_BATCHES_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
  SET_BATCHES_ATOMIC_AT_INDEX(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state.batches[payload.index];
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
  RESET_BATCHES(state, payload) {
    switch (payload) {
      case 'batch':
        state.batch = cloneDeep(defaultBatch);
        break;
      case 'editing':
        state.editing = cloneDeep(defaultBatch);
        break;
      default:
    }
  },
};

const getters = {
  batch: (state) => state.batch,
  batchBookingIds(state) {
    // used?
    const { batch } = state;
    if (batch.stats) {
      const filtered = pickBy(batch.stats.bookings, (num) => num > 0);
      return Object.keys(filtered);
    }
    return [];
  },
  batchDescription(state, getter, rootState, rootGetters) {
    const company = rootGetters['companies/company'];
    return {
      auto: {
        label: 'Automatically match payments',
        help: 'Match payments immediately after uploading an EFT',
      },
      client: {
        label: 'Require client last name',
        help: 'Only auto-match if the client\'s last name is the same on both the booking and the payment.',
      },
      duplicates: {
        label: 'Match multiple payments with the same booking',
        help: 'Do not auto-match any payments for a booking if there is more than one good match.',
      },
      geo: {
        label: 'Limit matches by supplier location',
        help: 'Do not auto-match with a booking where the supplier is more than 25 miles from the expected destination.',
      },
      threshold: {
        label: 'How confident should Sion be to auto-match a payment?',
        help: null,
      },
      zero: {
        label: `Match ${company.currency.sign}0 payments`,
        help: 'Auto-match blank payments even though they cannot be reconciled in Sion.',
      },
    };
  },
  batchEditing: (state) => state.editing,
  batchSearch: (state) => state.search,
  batches: (state) => state.batches,
  batchesAggregations: (state) => state.aggs,
  batchesDateFields: (state) => state.dateFields,
  batchesEsQuery(state, getter, rootState, rootGetters) {
    // circle back to this huge block and rethink on solution
    const company = rootGetters['companies/company'];
    const companyTimeZoneOffset = company.time_zone.offset;
    const { batchesSort } = getter;
    const filters = rootGetters['commissions/commissionsFilters'];
    const from = state.pagination.per * (state.pagination.page - 1);

    let dateFormat = dateFormatYMD;
    let toDate = null;
    let fromDate = null;
    if (filters.fields.date === 'created_at') {
      dateFormat = dateFormatUTC;
      fromDate = dayjs(filters.dates.from, dateFormat)
        .utcOffset(companyTimeZoneOffset)
        .startOf('day')
        .format(dateFormat);
      toDate = dayjs(filters.dates.to, dateFormat)
        .utcOffset(companyTimeZoneOffset)
        .endOf('day')
        .format(dateFormat);
    } else {
      fromDate = dayjs(filters.dates.from, dateFormat).format(dateFormat);
      toDate = dayjs(filters.dates.to, dateFormat).format(dateFormat);
    }

    const sort = [];
    let filterKey = null;
    if (batchesSort) {
      [filterKey] = Object.keys(batchesSort);
      let field = filterKey.match(/name|last_first/i) ? `${filterKey}.raw` : filterKey;
      const obj = {};
      if (field === 'number') {
        field = 'number.case_insensitive'
      }
      obj[field] = { order: batchesSort[filterKey] };
      sort.push(obj);
      if (field !== 'number.case_insensitive') {
        sort.push({ 'number.case_insensitive': { order: 'desc' } });
      }
    }

    const payload = {
      aggs: {
        matched_amount: {
          sum: {
            field: 'amounts.matched',
          },
        },
        matched_entries: {
          sum: {
            field: 'stats.entries.matched',
          },
        },
        unmatched_amount: {
          sum: {
            field: 'amounts.unmatched',
          },
        },
        unmatched_entries: {
          sum: {
            field: 'stats.entries.unmatched',
          },
        },
        total_amount: {
          sum: {
            field: 'amounts.total',
          },
        },
        total_entries: {
          sum: {
            field: 'stats.entries.total',
          },
        },
      },
      from,
      size: state.pagination.per,
      sort,
      query: {
        bool: {
          must: [rootGetters['quickSearchFilter/getCompaniesFilter']([company.id])],
        },
      },
    };
    if (filters.batches.length > 0) {
      const ids = filters.batches.map((batchItem) => batchItem.id);
      payload.query.bool.must.push({
        terms: { id: ids },
      });
    }
    if (filters.dates.year !== 'all') {
      if (fromDate && toDate) {
        const dateQuery = { range: {} };
        dateQuery.range[filters.fields.date] = { gte: fromDate, lte: toDate };
        payload.query.bool.must.push(dateQuery);
      }
    }
    if (filters.queryStrings && filters.queryStrings.length > 0) {
      const queryStringsQuery = {
        bool: {
          should: [],
        },
      };
      filters.queryStrings.forEach((query) => {
        if (query) {
          queryStringsQuery.bool.should.push({
            multi_match: {
              query: query.toUpperCase(),
              fields: filters.fields.query,
              type: 'cross_fields',
              operator: 'and',
            },
          });
        }
      });
      payload.query.bool.must.push(queryStringsQuery);
    }
    if (filters.statuses.batches.length > 0) {
      const statusQuery = {
        bool: {
          should: [],
        },
      };
      if (filters.statuses.batches.includes('open')) {
        statusQuery.bool.should.push({ match: { is_reconciled: false } });
      }
      if (filters.statuses.batches.includes('reconciled')) {
        statusQuery.bool.should.push({ match: { is_reconciled: true } });
      }
      payload.query.bool.must.push(statusQuery);
    }
    if (filters.types && filters.types.length > 0) {
      const terms = { payment_type: filters.types };
      payload.query.bool.must.push({ terms });
    }
    return payload;
  },
  batchesPagination: (state) => state.pagination,
  batchesFilters(state, getters, rootState, rootGetters) {
    const filters = rootGetters['commissions/commissionsFilters'];
    return {
      ...filters,
      sort: getters.batchesSort
    }
  },
  batchesRefresh: (state) => state.refresh,
  batchesReload: (state) => state.reload,
  batchesQueryFields: (state) => state.queryFields,
  batchesSort: (state) => state.sort,
};

const actions = {
  async doDeleteBatch({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.delete(`/batches/${id}`, { params });
    const [batch] = data.data.batches;
    if (!ignore) {
      commit('SET_BATCHES_ATOMIC', { key: 'batch', val: new BatchHelper(batch).format() });
    }
    return data;
  },
  async doCreateBatch({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.post(`/companies/${id}/batches`, params);
    const [batch] = data.data.batches;
    if (!ignore) {
      commit('SET_BATCHES_ATOMIC', { key: 'batch', val: new BatchHelper(batch).format() });
    }
    return data;
  },
  async doGetBatch({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.get(`/batches/${id}`, { params });
    const [batch] = data.data.batches;
    if (!ignore) {
      commit('SET_BATCHES_ATOMIC', { key: 'batch', val: new BatchHelper(batch).format() });
    }
    return data;
  },
  async doGetBatches({ commit }, payload) {
    const { ignore, ...params } = payload;
    const { data } = await HTTP.get('/batches', { params });
    const [batch] = data.data.batches;
    if (!ignore) {
      commit('SET_BATCHES_ATOMIC', { key: 'batches', val: new BatchHelper(batch).format() });
    }
    return data;
  },
  async doSearchBatches({ commit }, payload) {
    const { data } = await SEARCH.post('/sion_batches/_search', payload);
    const batches = data.hits.hits.map((hit) => hit._source);
    commit('SET_BATCHES_ATOMIC', { key: 'batches', val: batches });
    commit('SET_BATCHES_ATOMIC', { key: 'aggs', val: data.aggregations });
    return data;
  },
  async doUpdateBatch({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.patch(`/batches/${id}`, params);
    const [batch] = data.data.batches;
    if (!ignore) {
      commit('SET_BATCHES_ATOMIC', { key: 'batch', val: new BatchHelper(batch).format() });
    }
    return data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
