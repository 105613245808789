import { apiClient } from '../apiClient';

const DOMAIN = 'bookings';

export default {
  create: (payload) => apiClient.post(`/${DOMAIN}`, payload),
  get: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}`, { params }),
  update: ({ id, ...payload }) => apiClient.patch(`/${DOMAIN}/${id}`, payload),
  delete: ({ id }) => apiClient.delete(`/${DOMAIN}/${id}`),
  getAll: (params) => apiClient.get(`/${DOMAIN}`, { params }),
  search: (payload) => apiClient.post(`/sion_${DOMAIN}/_search`, payload),

  getActions: ({ id }) => apiClient.get(`/${DOMAIN}/${id}/actions`),
  updateActions: ({ id, ...payload }) => apiClient.patch(`actions/${id}`, payload),

  createReminder: ({ id, ...payload }) => apiClient.post(`/${DOMAIN}/${id}/reminders`, payload),

  getPnr: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}/pnr`, { params }),

  getInvoices: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}/invoices`, { params }),

  createAttachments: ({ id, ...payload }) => apiClient.post(`/${DOMAIN}/${id}/attachments`, payload),
  getAttachments: ({ id }) => apiClient.get(`/${DOMAIN}/${id}/attachments`),

  getCategories: () => apiClient.get('/booking_categories'),
};
