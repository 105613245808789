import Vue from 'vue';
import { HTTP } from '@/utils/http';

const state = {
  contact: {},
  contacts: [],
};

const mutations = {
  SET_SUPPLIER_CONTACTS_ATOMIC(state, payload) {
    // used ?
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
};

const getters = {
  supplierContact: (state) => state.contact, // used?
  supplierContacts: (state) => state.contacts, // used?
};

const actions = {
  async doCreateSupplierContact({ commit }, payload) {
    // used?
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.post(`/suppliers/${id}/contacts`, params);
    if (!ignore) {
      const [val] = data.data.contacts;
      commit('SET_SUPPLIER_CONTACTS_ATOMIC', { key: 'contact', val });
    }
    return data;
  },
  async doUpdateSupplierContact({ commit }, payload) {
    // used?
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.patch(`/supplier_contacts/${id}`, params);
    if (!ignore) {
      const [val] = data.data.supplier_contacts;
      commit('SET_SUPPLIER_CONTACTS_ATOMIC', { key: 'contact', val });
    }
    return data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
