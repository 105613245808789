import { apiClient } from '../apiClient';

const DOMAIN = 'contacts';

export default {
  create: ({ id, ...payload }) => apiClient.post(`/suppliers/${id}/${DOMAIN}`, payload),
  get: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}`, { params }),
  update: ({ id, ...payload }) => apiClient.patch(`/${DOMAIN}/${id}`, payload),
  delete: ({ id, ...payload }) => apiClient.delete(`/${DOMAIN}/${id}`, payload),
};
