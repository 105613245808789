import { apiClient } from '../apiClient';

const DOMAIN = 'suppliers';

export default {
  create: (payload) => apiClient.post(`/${DOMAIN}`, payload),
  get: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}`, { params }),
  update: ({ id, ...payload }) => apiClient.put(`/${DOMAIN}/${id}`, payload),
  patch: ({ id, zoom, ...payload }) => apiClient.patch(`/${DOMAIN}/${id}`, payload, { params: { zoom } }),
  duplicate: ({ id, ...payload }) => apiClient.post(`/${DOMAIN}/${id}`, payload),
  delete: ({ id, ...payload }) => apiClient.delete(`/${DOMAIN}/${id}`, payload),
  getAll: (params) => apiClient.get(`/${DOMAIN}`, { params }),
  search: (payload) => apiClient.post(`/sion_${DOMAIN}/_search`, payload),

  createAddress: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}/address`, { params }),

  createBillingProfile: ({ id, ...payload }) => apiClient.post(`/${DOMAIN}/${id}/billing_profiles`, payload),
  getBillingProfile: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}/billing_profiles`, { params }),

  createAttachment: ({ id, ...payload }) => apiClient.post(`/${DOMAIN}/${id}/attachments`, payload),
  getAttachments: ({ id }) => apiClient.get(`/${DOMAIN}/${id}/attachments`),

  getPreferredPrograms: (payload) => apiClient.post('/sion_preferred_programs/_search', payload),
  getSuggestions: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}/suggestions`, { params }),
  getSuggestion: ({ id, ...params }) => apiClient.get(`/supplier_suggestions/${id}`, { params }),
  updateSuggestion: ({ id, ...params }) => apiClient.patch(`/supplier_suggestions/${id}`, { ...params }),
  createSuggestion: ({id, ...params}) => apiClient.post(`${DOMAIN}/${id}/suggestions`, { ...params } ),
};
