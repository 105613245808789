import { apiClient } from '../apiClient';

const DOMAIN = 'customers';

export default {
  create: ({ id, ...payload }) => apiClient.post(`/companies/${id}/customer`, payload),
  get: ({ id, ...params }) => apiClient.get(`/companies/${id}/customer`, { params }),
  update: ({ id, ...payload }) => apiClient.patch(`/${DOMAIN}/${id}`, payload),
  
  getStripe: ({ id }) => apiClient.get(`/${DOMAIN}/${id}/_stripe/customer`),
  getStripeInvoices: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}/_stripe/invoices`, { params }),
  getStripeUpcomingInvoice: ({ id }) => apiClient.get(`/${DOMAIN}/${id}/_stripe/upcoming`),
  getStripeSubscriptions: ({ id }) => apiClient.get(`/${DOMAIN}/${id}/_stripe/subscriptions`),
};
