const state = {
  restrictions: [],
};

const mutations = {
  SET_DIETARY_RESTRICTIONS(state, payload) {
    state.restrictions = payload;
  },
};

const getters = {
  dietaryRestrictions: (state) => state.restrictions,
};

const actions = {
  async doGetDietaryRestrictions({ commit }) {
    const { data } = await this._vm.$api.health.getDietRestrictions();
    commit('SET_DIETARY_RESTRICTIONS', data.dietary_restrictions);
    return data.dietary_restrictions;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
