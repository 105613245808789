import Vue from 'vue';
import { dayjs } from '@/plugins/dayjs';
import { finalFxScript } from '@/utils/helpers/booking';
import { dateFormatUTC, dateFormatYMD } from '@/utils/helpers/dates';
import { compact, sortBy, cloneDeep } from '@/utils/helpers/methods';
import { currencyConversionMetric, formatBooking, queries } from '@/utils/helpers/booking';
import { preciseCardinality } from '@/services/elastic';
import attributes from '@/data/attributes.json';
import { getLocationQuery } from '@/utils/helpers/es/suppliers';

const queryFields = [
  { key: 'back_office_number', label: 'Back Office Number' },
  { key: '_embedded.supplier.group_codes.case_insensitive', label: 'Chain Code' },
  { key: '_embedded.client.full_name', label: 'Client Name' },
  { key: 'confirmation_number', label: 'Confirmation Number' },
  { key: '_embedded.segment.customer_id', label: 'Customer ID / DK' },
  { key: '_embedded.segment.product_code', label: 'Product Code' },
  { key: '_embedded.segment.rate_code', label: 'Rate Code' },
  { key: '_embedded.segment.rate_type', label: 'Rate Type' },
  { key: '_embedded.segment.room_type', label: 'Room Type' },
  { key: 'number.case_insensitive', label: 'Sion Reference Number' },
  { key: '_embedded.supplier.name.autocomplete', label: 'Supplier' },
];

const defaultAggs = {
  canceled: {
    doc_count: 0,
    total: {
      value: 0,
    },
  },
  count: {
    value: 0,
  },
  owed: {
    doc_count: 0,
    total: {
      value: 0,
    },
  },
  paid: {
    doc_count: 0,
    total: {
      value: 0,
    },
  },
  past_due: {
    doc_count: 0,
    total: {
      value: 0,
    },
  },
  temp_actions: {
    doc_count: 0,
  },
  total: {
    value: 0,
  },
  upcoming: {
    doc_count: 0,
    total: {
      value: 0,
    },
  },
};

const defaultBooking = {
  access_level: {
    read: false,
    write: false,
    pay: false,
  },
  agent_commission_est: null,
  agent_commission_rate: null,
  agent_id: null,
  check_in: null,
  check_out: null,
  commission_est: 0,
  commission_rate: 10,
  commission_split: 0,
  company_id: null,
  consortia_member_number_id: null,
  fee_type: 'booking',
  has_fees: false,
  iata_id: null,
  ignore_room_rate: false,
  invoice_type: 'supplier',
  is_agent_commissionable: false,
  is_commissionable: true,
  number_of_rooms: 1,
  number_of_rooms_multiply: false,
  payment_due: null,
  payment_term: null,
  revenue: 0,
  revenue_currency: {},
  room_rate: 0,
  _embedded: {
    attachments: [],
    category: {},
    client: {},
    fees: [],
    iata: {},
    supplier: {},
    tags: {
      booking: [],
    },
  },
};

const defaultFilters = {
  backOffice: {
    status: 'all',
    updated: null,
    range: {
      min: null,
      max: null,
    },
  },
  branches: [],
  clients: [],
  categories: [],
  companies: [],
  dates: {
    from: dayjs().startOf('year').toDate(),
    month: 1,
    range: 'year',
    to: dayjs().endOf('year').toDate(),
    quarter: 1,
    year: new Date().getFullYear(),
  },
  employees: [],
  fields: {
    date: 'check_out',
    query: queryFields.map((queryField) => queryField.key),
  },
  flagged: false,
  follow_up_statuses: ['none', 'sent', 'delivered', 'opened', 'bounced'],
  iatas: [],
  imports: [],
  invoice_id: null,
  invoices: ['supplier', 'client_net', 'client_fee', 'non_commissionable'],
  locations: [],
  destinations: [],// Todo(master-data): remove destinations, and remove references
  not_flagged: false,
  not_starred: false,
  statuses: {
    bookings: ['upcoming', 'owed', 'past_due', 'paid'],
    trips: ['upcoming', 'current', 'past'],
  },
  pnrs: [],
  percentPaid: {
    min: 0,
    max: 100,
  },
  queryStrings: [],
  suppliers: [],
  supplier_groups: [],
  starred: false,
  sort: { created_at: 'desc' },
  sources: [
    'none',
    'amadeus',
    'sabre',
    'travelport'
  ],
  split: [
    'agent',
    'house',
  ],
  tags: [],
  trips: [],
};

const defaultReporting = {
  view: 'revenue',
  gross: true,
  viewAs: 'agency',
};

const defaultSettings = {
  mode: 'BOOKINGS',
  modes: ['BOOKINGS', 'TRIPS'],
  trip: false,
};

const state = {
  actions: [],
  aggs: cloneDeep(defaultAggs),
  booking: cloneDeep(defaultBooking),
  bookings: [],
  commissions: false,
  dateFields: [
    { key: 'canceled_at', label: 'Canceled' },
    { key: 'check_in', label: 'Check-In' },
    { key: 'check_out', label: 'Check-Out' },
    { key: 'created_at', label: 'Created' },
    { key: '_embedded.follow_up.created_at', label: 'Followed-Up' },
    { key: 'payment_date', label: 'Payment Date' },
    { key: 'payment_due', label: 'Payment Due' },
    { key: 'updated_at', label: 'Updated' },
  ],
  deletedTags: [],
  editing: cloneDeep(defaultBooking),
  filters: cloneDeep(defaultFilters),
  pagination: {
    page: 0,
    per: 25,
    total: 0,
  },
  refresh: false,
  reporting: cloneDeep(defaultReporting),
  settings: cloneDeep(defaultSettings),
  selected: [],
  sync: false,
  query: {},
  queryFields: cloneDeep(queryFields),
};

const mutations = {
  ADD_BOOKING_TO_BOOKINGS(state, booking) {
    // not used?
    state.bookings.push(booking);
  },
  REMOVE_BOOKING_FROM_BOOKINGS(state, index) {
    // not used?
    if (state.bookings[index] !== undefined) {
      state.bookings.splice(index, 1);
    }
  },
  RESET_BOOKINGS(state, payload) {
    switch (payload) {
      case 'aggs':
        state.aggs = cloneDeep(defaultAggs);
        break;
      case 'booking':
        state.booking = cloneDeep(defaultBooking);
        break;
      case 'bookings':
        state.bookings = [];
        break;
      case 'editing':
        state.editing = cloneDeep(defaultBooking);
        break;
      case 'filters':
        state.filters = cloneDeep(defaultFilters);
        break;
      case 'reporting':
        state.reporting = cloneDeep(defaultReporting);
        break;
      case 'settings':
        state.settings = cloneDeep(defaultSettings);
        break;
      case 'sort':
        state.filters.sort = cloneDeep(defaultFilters.sort)
      default:
    }
  },
  SET_BOOKING_FOR_BOOKINGS(state, booking) {
    // used?
    const index = state.bookings.findIndex((bookingItem) => bookingItem.id === booking.id);
    if (index >= 0) {
      Vue.set(state.bookings, index, booking);
    }
  },
  SET_BOOKINGS_ATOMIC(state, field) {
    const nodes = field.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, field.val);
      } else {
        obj = obj[node];
      }
    });
  },
  TOGGLE_BOOKING_DESTINATION(state, payload) {
    const { key, val } = payload;
    const { supplier } = state[key]._embedded;
    if (supplier && supplier.name) {
      const address = supplier.address || supplier._embedded.address;
      if (address) {
        const { destinations } = address;
        const index = destinations.findIndex(
          (destinationItem) => destinationItem.source_id === val.source_id
        );
        if (index >= 0) {
          address.destinations.splice(index, 1);
        } else {
          address.destinations.push(val);
        }
      }
    }
  },
  TOGGLE_BOOKING_TAG(state, payload) {
    const { key, val } = payload;
    const { id } = val;
    const index = state[key]._embedded.tags.booking.findIndex(
      (bookingItem) => bookingItem.id === id
    );
    const deletedIndex = state.deletedTags.findIndex((deletedTagItem) => deletedTagItem.id === id);
    if (index < 0) {
      state[key]._embedded.tags.booking.push(val);
      if (deletedIndex >= 0) {
        state.deletedTags.splice(deletedIndex, 1);
      }
    } else {
      state[key]._embedded.tags.booking.splice(index, 1);
      if (deletedIndex < 0) {
        state.deletedTags.push(val);
      }
    }
  },
  TOGGLE_BOOKINGS_FILTER(state, payload) {
    const { val, key, identifier = 'id' } = payload;
    if (!val) return;

    if (payload.key === 'followUp') {
      state.filters.followUp = null;
      return;
    }

    const filter = state.filters[key];
    const index =
      typeof val === 'string'
        ? filter.indexOf(val)
        : filter.findIndex((filterItem) => filterItem[identifier] === val[identifier]);

    if (index < 0) filter.push(val);
    else filter.splice(index, 1);
  },
  SET_ACTION_FOR_BOOKING_ACTIONS(state, action) {
    const index = state.actions.findIndex(({ id }) => action.id === id);
    if (index >= 0) {
      Vue.set(state.actions, index, action);
    }
  },
  SET_BOOKINGS_FILTERS(state, payload) {
    state.filters = payload;
  }
};

const getters = {
  booking: (state) => state.booking,
  bookings: (state) => state.bookings,
  bookingActions: (state) => state.actions,
  bookingDeletedTags: (state) => state.deletedTags,
  bookingsSelected: (state) => state.selected,
  bookingFilteredActions(state) {
    // used?
    let bookingUpdate = null;
    const bookingUpdates = state.actions.filter(
      (a) => a.object_type === 'booking' && a.type === 'update'
    );
    if (bookingUpdates.length > 0) {
      [bookingUpdate] = sortBy(bookingUpdates, (bookingUpdateItem) => bookingUpdateItem.time, true);
    }
    const otherActions = state.actions.filter(
      (a) => !(a.object_type === 'booking' && a.type === 'update')
    );
    if (bookingUpdate) {
      otherActions.push(bookingUpdate);
    }
    return sortBy(otherActions, (otherActionItem) => otherActionItem.time, true);
  },
  bookingSettings: (state) => state.settings,
  bookingSync: (state) => state.sync,
  bookingAggregations: (state) => state.aggs,
  bookingsCommissions: (state) => state.commissions, // used?
  bookingDateFields(state, getter, rootState, rootGetters) {
    // used
    const integrations = rootGetters['integrations/integrations'];
    const fields = cloneDeep(state.dateFields);
    if (integrations) {
      integrations.forEach((integration) => {
        const { service } = integration;
        if (service) {
          const { destination } = service;
          if (destination) {
            const { key, name } = destination;
            fields.push({ key: `integration_sync.${key}`, label: `${name} Sync` });
          }
        }
      });
    }
    return fields;
  },
  bookingsFilters: (state) => state.filters,
  bookingsPagination: (state) => state.pagination,
  bookingsRefresh: (state) => state.refresh,
  bookingsEsQuery(state, getter, rootState, rootGetters) {
    const from = state.pagination.per * (state.pagination.page - 1);
    const backOffices = rootGetters['integrations/backOfficeIntegrations'];
    const company = rootGetters['companies/company'];
    const contractors = rootGetters['companies/companyContractorsNoAgent'];
    const companyTimeZoneOffset = company.time_zone.offset;
    const employee = rootGetters['employees/currentEmployee'];
    const rates = rootGetters['currencies/currencyRates'];
    const { reporting } = state;

    let dateFormat = dateFormatYMD;
    let toDate = null;
    let fromDate = null;
    if (state.filters.fields.date === 'created_at') {
      dateFormat = dateFormatUTC;
      fromDate = dayjs(state.filters.dates.from, dateFormat)
        .utcOffset(companyTimeZoneOffset)
        .format(dateFormat);
      toDate = dayjs(state.filters.dates.to, dateFormat)
        .utcOffset(companyTimeZoneOffset)
        .format(dateFormat);
    } else {
      fromDate = dayjs(state.filters.dates.from, dateFormat).format(dateFormat);
      toDate = dayjs(state.filters.dates.to, dateFormat).format(dateFormat);
    }

    const targetCurrency = company.currency.symbol;
    const scriptedParams = {
      target_currency: targetCurrency,
      rates,
    };

    const sort = [];
    let filterKey = null;
    if (state.filters) {
      [filterKey] = Object.keys(state.filters.sort);
      const field = filterKey.match(/name|last_first/i) ? `${filterKey}.raw` : filterKey;
      if (field.match(/revenue/i)) {
        sort.push({
          _script: {
            type: 'number',
            script: {
              lang: 'painless',
              source:
                'def sym = doc["revenue_currency.symbol"].value; doc["revenue"].value * params.rates[sym][params.target_currency]',
              params: scriptedParams,
            },
            order: state.filters.sort[filterKey],
          },
        });
      } else {
        const obj = {};
        obj[field] = { order: state.filters.sort[filterKey] };
        sort.push(obj);
      }
      sort.push({ sort_order: { order: 'asc' } });
    }

    const payload = {
      _source: ['*'],
      aggs: {},
      size: state.pagination.per,
      from: from >= 0 ? from : 0,
      script_fields: {
        commission_converted: {
          script: {
            lang: 'painless',
            source: `def sym = doc["revenue_currency.symbol"].value;\n
              double supplier_cost = doc["supplier_cost"].value;\n
              double live = doc["commission_est"].value;\n
              double live_ex_rate = params.rates[sym][params.target_currency];\n
              double final_gross = doc["total_paid"].value;\n
              double final_net = final_gross - supplier_cost;\n
              double final_ex_rate = ${finalFxScript('commission', targetCurrency)};\n
              final_gross > 0 ? final_net * final_ex_rate : live * live_ex_rate`,
              params: scriptedParams,
            },
          },
        },
        sort,
        query: {
          constant_score: {
            filter: {
              bool: {
                must: [],
                must_not: [],
                should: [],
              },
            },
          },
        },
      };
      payload.aggs = {
        count: preciseCardinality('id'),
        canceled: {
          filter: queries.canceled,
          aggs: {
            total: currencyConversionMetric(reporting, company, rates),
          },
        },
        owed: {
          filter: queries.owed,
          aggs: {
            total: currencyConversionMetric(reporting, company, rates),
          },
        },
        paid: {
          filter: queries.paid,
          aggs: {
            total: currencyConversionMetric(reporting, company, rates),
          },
        },
        past_due: {
          filter: queries.pastDue,
          aggs: {
            total: currencyConversionMetric(reporting, company, rates),
          },
        },
        temp_actions: {
          filter: queries.tempActions,
        },
        total: currencyConversionMetric(reporting, company, rates),
        commissions: currencyConversionMetric({ ...reporting, view: 'commission' }, company, rates),
        upcoming: {
          filter: queries.upcoming,
          aggs: {
            total: currencyConversionMetric(reporting, company, rates),
          },
        },
      };
      let dateQuery = null;
      const dateField = state.filters.fields.date;
      if (state.filters.dates.year === 'all') {
        dateQuery = { exists: { field: dateField } };
      } else if ((fromDate && toDate) && !state.filters.invoice_id) {
        const range = {
          gte: fromDate,
          lte: toDate,
        };
        if (dateField === 'payment_date') {
          dateQuery = {
            bool: {
              should: [
                {
                  bool: {
                    must: [
                      { match: { is_commissionable: true } },
                      { range: { payment_date: range } },
                    ],
                  },
                },
                {
                  bool: {
                    must: [
                      { match: { is_commissionable: false } },
                      { range: { check_out: range } },
                      { range: { payment_due: { lt: 'now/d' } } },
                    ],
                  },
                },
              ],
            },
          };
        } else {
          dateQuery = { range: {} };
          dateQuery.range[dateField] = range;
        }
      }
      if (dateQuery) {
        payload.query.constant_score.filter.bool.must.push(dateQuery);
      }
      const embeddedTerms = [
        { key: 'company', val: state.filters.companies },
        { key: 'iata', val: state.filters.iatas },
      ];
      embeddedTerms.forEach((term) => {
        if (term.val && compact(term.val).length > 0) {
          const terms = {};
          terms[`_embedded.${term.key}.id`] = term.val;
          payload.query.constant_score.filter.bool.must.push({ terms });
        }
      });
      if (backOffices && backOffices.length > 0) {
        const [backOffice] = backOffices;
        const backOfficeStatus = state.filters.backOffice.status;
        if (backOfficeStatus !== 'all') {
          const backOfficeStatusQuery = { exists: { field: `integration_sync.${backOffice.service.destination.key}` } };
          if (backOfficeStatus === 'synced') {
            payload.query.constant_score.filter.bool.must.push(backOfficeStatusQuery);
          } else if (backOfficeStatus === 'not_synced') {
            payload.query.constant_score.filter.bool.must_not.push(backOfficeStatusQuery);
          }
        }
        const backOfficeUpdated = state.filters.backOffice.updated;
        if (backOfficeUpdated) {
          const backOfficeUpdatedQuery = {
            range: {
              updated_at: {
                gt: dayjs(backOfficeUpdated, dateFormat).format(dateFormat),
              },
            },
          };
          payload.query.constant_score.filter.bool.must.push(backOfficeUpdatedQuery);
        }
        const backOfficeRange = state.filters.backOffice.range;
        const backOfficeMin = backOfficeRange.min;
        const backOfficeMax = backOfficeRange.max;
        if (backOfficeMin || backOfficeMax) {
          const backOfficeRangeQuery = {
            range: {
              back_office_number: {},
            },
          };
          if (backOfficeMin) {
            backOfficeRangeQuery.range.back_office_number.gte = backOfficeMin;
          }
          if (backOfficeMax) {
            backOfficeRangeQuery.range.back_office_number.lte = backOfficeMax;
          }
          payload.query.constant_score.filter.bool.must.push(backOfficeRangeQuery);
        }
      }
      if (state.filters.branches.length > 0) {
        const branchQuery = {
          bool: {
            should: [
              { match: { source: 'none' } },
              {
                bool: {
                  must: [
                    { terms: { '_embedded.segment.source_branch_id': state.filters.branches } },
                  ],
                  must_not: [
                    { match: { source: 'none' } },
                  ],
                },
              },
            ],
          },
        };
        payload.query.constant_score.filter.bool.must.push(branchQuery);
      }
      if (state.filters.clients.length > 0) {
        const ids = state.filters.clients.map(({ id }) => id);
        const terms = { '_embedded.client.id': ids };
        payload.query.constant_score.filter.bool.must.push({ terms });
      }
      const { locations } = state.filters;
      if (locations.length > 0) {
        const locationQuery = getLocationQuery(locations, '_embedded.supplier.');
        payload.query.constant_score.filter.bool.must.push({ bool: { should: locationQuery } });
      }
      if (state.filters.imports.length > 0) {
        const ids = state.filters.imports.map(({ id }) => id);
        const terms = { '_embedded.import.id': ids };
        payload.query.constant_score.filter.bool.must.push({ terms });
      }
      if (state.filters.employees.length > 0) {
        const contractorIds = contractors.map(({ id }) => id);
        const altContractorIds = state.filters.companies.filter((id) => contractorIds.includes(id));
        const agentIds = employee.access.company.bookings.read ? state.filters.employees : [employee.id];
        const agentCommissionable = [];
        if (state.filters.split.includes('agent')) {
          agentCommissionable.push(true);
        }
        if (state.filters.split.includes('house')) {
          agentCommissionable.push(false);
        }
        const employeeIds = {
          must: [
            { terms: { '_embedded.agent.id': agentIds } },
          ],
        };
        if (agentCommissionable.length > 0) {
          employeeIds.must.push({
            terms: { is_agent_commissionable: agentCommissionable },
          });
        }
        const employeeFilter = {
          bool: {
            should: [
              {
                bool: employeeIds,
              },
            ],
          },
        };
        if (altContractorIds.length > 0) {
          employeeFilter.bool.should.push({
            bool: {
              must: [
                { terms: { '_embedded.company.id': altContractorIds } },
              ],
            },
          });
        }
        payload.query.constant_score.filter.bool.must.push(employeeFilter);
      }
      if (state.filters.categories.length > 0) {
        payload.query.constant_score.filter.bool.must.push({
          terms: { '_embedded.category.id': state.filters.categories },
        });
      }

      // Follow Up Dunning Status Filters
      const dunningStatusKeys = [...attributes.statuses.dunning.map((val) => val.key)];
      const followUpDunningStatuses = state.filters.follow_up_statuses.filter((val) => dunningStatusKeys.includes(val));
      if (followUpDunningStatuses.length > 0) {
        const cleanDunningStatuses = followUpDunningStatuses.filter((status) => status !== 'disputed');
        const dunningStatusFilter = {
          bool: {
            should: [
              {
                bool: {
                  must_not: [
                    { exists: { field: '_embedded.invoice_payment_line' } },
                  ],
                },
              },
              {
                bool: {
                  must: [
                    { exists: { field: '_embedded.invoice_payment_line' } },
                    { terms: { '_embedded.invoice_payment_line.dunning_status': cleanDunningStatuses } },
                  ],
                },
              },
            ],
          },
        };
        payload.query.constant_score.filter.bool.must.push(dunningStatusFilter);
      }

      // Follow Up Send Status Filters
      const followUpSendStatusKeys = [...attributes.statuses.follow_up.map((val) => val.key)];
      const followUpSendStatuses = state.filters.follow_up_statuses.filter((val) => followUpSendStatusKeys.includes(val));
      if (followUpSendStatuses.length > 0) {
        const followUpFilter = {
          bool: {
            should: [
              { terms: { '_embedded.follow_up.status': followUpSendStatuses } },
            ],
          },
        };
        // if (followUpSendStatuses.includes('sent')) {
        //   followUpFilter.bool.should.push({
        //     exists: { field: '_embedded.follow_up.id' },
        //   });
        // }
        if (followUpSendStatuses.includes('none')) {
          followUpFilter.bool.should.push({
            bool: {
              must_not: [
                { exists: { field: '_embedded.follow_up.id' } },
              ],
            },
          });
        }
        payload.query.constant_score.filter.bool.must.push(followUpFilter);
      }

      // Follow Up Temp Actions Filter
      const tempActionsOnly = state.filters.follow_up_statuses.includes('temp_actions_only');
      if (tempActionsOnly) {
        payload.query.constant_score.filter.bool.must.push({
          range: {
            temp_actions_count: { gt: 0 },
          },
        });
      }

      // Follow Up Responded To Filters
      const respondedToKeys = [...attributes.statuses.follow_up_responded_to.map((val) => val.key)];
      const followUpRespondedToStatuses = state.filters.follow_up_statuses.filter((val) => respondedToKeys.includes(val));
      if (followUpRespondedToStatuses.length === 2) {
        if (followUpRespondedToStatuses.includes('is_confirmed')) {
          payload.query.constant_score.filter.bool.must.push(
            { match: { is_confirmed: true } },
            { match: { is_responded_to: true } },
          );
        } else if (followUpRespondedToStatuses.includes('is_edited')) {
          payload.query.constant_score.filter.bool.must.push(
            { match: { is_confirmed: false } },
            { match: { is_responded_to: true } },
          );
        }
      } else if (followUpRespondedToStatuses.length > 0) {
        payload.query.constant_score.filter.bool.must.push(
          { match: { is_responded_to: true } },
        );
      }

      // Follow Up Id Filter
      const { followUp } = state.filters;
      if (followUp) {
        const followUpId = followUp.id;
        payload.query.constant_score.filter.bool.must.push(
          { match: { '_embedded.follow_up.id': followUpId } },
        );
      }

      if (state.filters.invoices.length > 0) {
        const nonCommissionable = state.filters.invoices.includes('non_commissionable');
        const invoiceFilter = {
          bool: {
            should: [
              { terms: { invoice_type: state.filters.invoices } },
            ],
            must_not: [],
          },
        };
        if (nonCommissionable) {
          invoiceFilter.bool.should.push({ match: { is_commissionable: false } });
        } else {
          invoiceFilter.bool.must_not.push({ match: { is_commissionable: false } });
        }
        payload.query.constant_score.filter.bool.must.push(invoiceFilter);
      }
      if (state.filters.percentPaid) {
        const { min, max } = state.filters.percentPaid;
        const percentFilter = {
          bool: {
            should: [
              {
                range: {
                  percent_paid: {
                    gte: min,
                    lte: max,
                  },
                },
              },
            ],
          },
        };
        if (min > 0 && max === 100) {
          percentFilter.bool.should.push({
            bool: {
              must: [
                { match: { is_commissionable: false } },
                { range: { payment_due: { lt: 'now/d' } } },
              ],
            },
          });
        }
        // if (min !== 0 || max !== 100) {
        //   percentFilter.bool.must.push({ match: { is_commissionable: true } });
        // }
        payload.query.constant_score.filter.bool.must.push(percentFilter);
      }
      if (state.filters.queryStrings && state.filters.queryStrings.length > 0) {
        const queryStringsQuery = {
          bool: {
            should: [],
          },
        };
        state.filters.queryStrings.forEach((query) => {
          if (query) {
            queryStringsQuery.bool.should.push({
              multi_match: {
                query: query.toUpperCase(),
                fields: state.filters.fields.query,
                type: 'cross_fields',
                operator: 'and',
                analyzer: 'standard'
              },
            });
          }
        });
        payload.query.constant_score.filter.bool.must.push(queryStringsQuery);
      }
      const { suppliers } = state.filters;
      if (suppliers.length > 0) {
        const ids = compact(suppliers.map(({ id }) => id));
        const supplierQuery = {
          bool: {
            should: [
              { terms: { '_embedded.supplier.id': ids } },
            ],
          },
        };
        const googlePlaceSourceIds = compact(suppliers.map((supplier) => {
          const identities = supplier._embedded?.identities || [];
          const googleIdentities = compact(identities.map(({ source, source_id }) => source === 'google' ? source_id : null ));
          return googleIdentities[0];
        }));
        if (googlePlaceSourceIds.length > 0) {
          const path = '_embedded.supplier._embedded.identities';
          const googlePlaceIdentityQuery = {
            bool: {
              must: [
                {
                  match: { source: 'manual' }
                },
                {
                  nested: {
                    path,
                    query: {
                      bool: {
                        must: [
                          { match: { [`${path}.source`]: 'google' } },
                          { terms: { [`${path}.source_id`]: googlePlaceSourceIds } }
                        ]
                      }
                    }
                  }
                }
              ]
            }
          };
          supplierQuery.bool.should.push(googlePlaceIdentityQuery);
        }
        const names = compact(state.filters.suppliers.map(({ name }) => name));
        if (names.length > 0) {
          const nameQuery = {
            bool: {
              must: [
                { match: { source: 'manual' } },
                { terms: { '_embedded.supplier.name.raw': names } }
              ]
            }
          };
          supplierQuery.bool.should.push(nameQuery);
        }
        payload.query.constant_score.filter.bool.must.push(supplierQuery);
      }
      if (state.filters.supplier_groups.length > 0) {
        const groupCodes = state.filters.supplier_groups.reduce((combinedCodes, group) => {
          let codes = combinedCodes;
          if (group.codes) {
            codes = [...codes, ...group.codes];
          } else if (group.code) {
            codes.push(group.code);
          }
          return codes;
        }, []);
        payload.query.constant_score.filter.bool.must.push({
          bool: {
            should: [
              { terms: { '_embedded.supplier.group_codes': groupCodes } }
            ]
          }
        })
      }
      if (state.filters.flagged === true) {
        payload.query.constant_score.filter.bool.must.push({
          match: { is_flagged: true },
        });
      }
      if (state.filters.not_flagged === true) {
        payload.query.constant_score.filter.bool.must.push({
          match: { is_flagged: false },
        });
      }
      if (state.filters.starred === true) {
        payload.query.constant_score.filter.bool.must.push({
          match: { is_starred: true },
        });
      }
      if (state.filters.not_starred === true) {
        payload.query.constant_score.filter.bool.must.push({
          match: { is_starred: false },
        });
      }
      if (state.filters.sources.length > 0) {
        payload.query.constant_score.filter.bool.must.push({
          terms: { source: state.filters.sources },
        });
      }
      if (state.filters.tags.length > 0) {
        state.filters.tags.forEach((tag) => {
          payload.query.constant_score.filter.bool.must.push({
            term: { tags: tag },
          });
        });
      }
      if (state.filters.trips.length > 0) {
        const tripIds = state.filters.trips.map(({ id }) => id);
        payload.query.constant_score.filter.bool.must.push({
          terms: { trip_id: tripIds },
        });
      }

    const bookingStatuses = state.filters.statuses.bookings;
    if (bookingStatuses.length > 0) {
      const statusQuery = {
        bool: {
          should: [],
        },
      };
      bookingStatuses.forEach((status) => {
        let query = null;
        switch (status) {
          case 'canceled':
            query = queries.canceled;
            break;
          case 'owed':
            query = queries.owed;
            break;
          case 'paid':
            query = queries.paid;
            break;
          case 'past_due':
            query = queries.pastDue;
            break;
          case 'upcoming':
            query = queries.upcoming;
            break;
          default:
        }
        if (query !== null) statusQuery.bool.should.push(query);
      });
      payload.query.constant_score.filter.bool.must.push(statusQuery);
    }

    if (state.filters.pnrs.length > 0) {
      payload.query.constant_score.filter.bool.must.push({
        terms: { '_embedded.segment.record_locator.raw': state.filters.pnrs },
      });
    }

    if (state.commissions) {
      payload.query.constant_score.filter.bool.must.push({
        bool: {
          should: [
            {
              bool: {
                must: [{ match: { '_embedded.iata._embedded.company.id': company.id } }],
              },
            },
            {
              bool: {
                must: [
                  { match: { '_embedded.company.id': company.id } },
                  { match: { '_embedded.iata._embedded.company.is_managed': true } },
                ],
              },
            },
          ],
        },
      });
    }
    return payload;
  },
  bookingsReporting: (state) => state.reporting,
  bookingsQueryFields: (state) => state.queryFields,
  bookingEditing: (state) => state.editing,
};

const actions = {
  async doCreateBooking({ commit }, payload) {
    const { data } = await this._vm.$api.bookings.create(payload);
    const [booking] = data.bookings;
    commit('SET_BOOKINGS_ATOMIC', { key: 'editing', val: formatBooking(booking) });
  },
  async doDeleteBooking({ commit }, payload) {
    const { data } = await this._vm.$api.bookings.delete(payload);
    const [booking] = data.bookings;
    commit('SET_BOOKINGS_ATOMIC', { key: 'editing', val: formatBooking(booking) });
    commit('SET_BOOKINGS_ATOMIC', { key: 'refresh', val: true } );
  },
  async doGetBooking({ commit }, payload) {
    const { data } = await this._vm.$api.bookings.get(payload);
    const [booking] = data.bookings;
    commit('SET_BOOKINGS_ATOMIC', { key: 'booking', val: formatBooking(booking) });
  },
  async doGetBookingActions({ commit }, payload) {
    const { data } = await this._vm.$api.bookings.getActions(payload);
    commit('SET_BOOKINGS_ATOMIC', { key: 'actions', val: data.actions });
  },
  async doUpdateBookingActions({ commit }, payload) {
    const { data } = await this._vm.$api.bookings.updateActions(payload);
    commit('SET_ACTION_FOR_BOOKING_ACTIONS', data.actions);
  },
  async doGetBookings({ commit }, payload) {
    // not used?
    const { data } = await this._vm.$api.bookings.getAll(payload);
    commit('SET_BOOKINGS_ATOMIC', { key: 'bookings', val: data.bookings });
  },
  async doUpdateBooking({ commit }, { ignore, ...payload }) {
    const { data } = await this._vm.$api.bookings.update(payload);
    if (ignore) return;
    const [booking] = data.bookings;
    commit('SET_BOOKINGS_ATOMIC', { key: 'editing', val: formatBooking(booking) });
  },
  async doSearchBookings({ commit }, payload) {
    const { data } = await this._vm.$api.bookings.search(payload);

    const bookings = data.hits.hits.map((hit) => {
      const scriptFields = {};
      Object.keys(hit.fields).forEach((key) => {
        const val = hit.fields[key];
        [scriptFields[key]] = val;
        return val;
      });
      return Object.assign(hit._source, scriptFields);
    });

    commit('SET_BOOKINGS_ATOMIC', { key: 'bookings', val: bookings });
    commit('SET_BOOKINGS_ATOMIC', { key: 'aggs', val: data.aggregations });
    commit('SET_BOOKINGS_ATOMIC', { key: 'pagination.total', val: data.hits.total.value });
    commit('SET_BOOKINGS_ATOMIC', { key: 'refresh', val: false });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
