import Vue from 'vue';
import { cloneDeep } from '@/utils/helpers/methods';
import { HTTP, SEARCH } from '@/utils/http';

const defaultFilters = {
  query: null,
  types: ['action', 'comment', 'download', 'import'],
};

const state = {
  checked: false,
  filters: cloneDeep(defaultFilters),
  notification: {},
  notifications: [],
  pagination: {
    page: 1,
    per: 26,
    total: 0,
  },
};

const mutations = {
  ADD_NOTIFICATION_TO_NOTIFICATIONS(state, payload) {
    state.notifications.unshift(payload);
  },
  APPEND_NOTIFICATIONS_TO_NOTIFICATIONS(state, payload) {
    state.notifications = state.notifications.concat(payload);
  },
  REMOVE_NOTIFICATION_FROM_NOTIFICATIONS(state, payload) {
    const index = state.notifications.findIndex((notification) => notification.id === payload.id);
    if (index >= 0) {
      state.notifications.splice(index, 1);
    }
  },
  RESET_NOTIFICATIONS(state, payload) {
    switch (payload) {
      case 'filters':
        state.filters = cloneDeep(defaultFilters);
        break;
      case 'notifications':
        state.notifications = [];
        break;
      default:
    }
  },
  SET_NOTIFICATION_AT_INDEX(state, payload) {
    Vue.set(state.notifications, payload.index, payload.val);
  },
  SET_NOTIFICATIONS_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
};

const getters = {
  notification: (state) => state.notification, // used ?
  notifications: (state) => state.notifications,
  notificationsChecked: (state) => state.checked,
  notificationsEsQuery(state, _getter, _rootState, rootGetters) {
    const { filters, pagination } = state;
    const user = rootGetters['users/userSelf'];
    const from = pagination.per * (pagination.page - 1);

    const payload = {
      query: {
        bool: {
          must: [{ match: { user_id: user.id } }],
        },
      },
      sort: [{ created_at: 'desc' }], 
      size: pagination.per,
      from: from >= 0 ? from : 0,
    };
    if (filters.types) {
      payload.query.bool.must.push({ terms: { type: filters.types } });
    }
    if (filters.query) {
      payload.query.bool.must.push({
        multi_match: {
          query: filters.query,
          fields: ['resource.agent.user.full_name', 'resource.body','resource.file_name'],
          fuzziness: 1,
        },
      });
    }
    return payload;
  },
  notificationsFilters: (state) => state.filters,
  notificationsPagination: (state) => state.pagination,
  notificationsUnread({ notifications }) {
    // used ?
    const unread = notifications.filter((notificationItem) => !notificationItem?.read_at);
    return unread;
  },
};

const actions = {
  async doGetNotifications({ commit }, payload) {
    const { append, ...params } = payload;
    const { data } = await SEARCH.post('/sion_notifications/_search', params);
    const notifications = data.hits.hits.map((hit) => hit._source);
    if (append) {
      commit('APPEND_NOTIFICATIONS_TO_NOTIFICATIONS', notifications);
    } else {
      commit('SET_NOTIFICATIONS_ATOMIC', { key: 'pagination.total', val: data.hits.total.value });
      commit('SET_NOTIFICATIONS_ATOMIC', { key: 'notifications', val: notifications });
    }
    return data;
  },
  async doGetUserNotifications({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.get(`/users/${id}/notifications`, { params });
    if (!ignore) {
      commit('SET_NOTIFICATIONS_ATOMIC', { key: 'notifications', val: data.data.notifications });
    }
    return data;
  },
  async doUpdateNotification({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.patch(`/notifications/${id}`, params);
    if (!ignore) {
      const [notification] = data.data.notifications;
      commit('SET_NOTIFICATIONS_ATOMIC', { key: 'notification', val: notification });
    }
    return data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
