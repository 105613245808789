import Vue from 'vue';
import { HTTP } from '@/utils/http';

const state = {
  programs: [],
};

const mutations = {
  SET_REWARD_PROGRAMS_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
};

const getters = {
  rewardPrograms: (state) => state.programs,
};

const actions = {
  async doGetRewardPrograms({ commit }, payload) {
    const { ignore, ...params } = payload;
    const { data } = await HTTP.get('/reward_programs', { params });
    if (!ignore) {
      commit('SET_REWARD_PROGRAMS_ATOMIC', { key: 'programs', val: data.data.reward_programs });
    }
    return data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
