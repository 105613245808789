import { HTTP } from '@/utils/http';

const state = {
  plans: [],
};

const mutations = {
  SET_PLANS(state, payload) {
    state.plans = payload;
  },
};

const getters = {
  plans: (state) => state.plans,
};

const actions = {
  async doGetPlans({ commit }, params) {
    const { data } = await HTTP.get('/plans', { params });
    commit('SET_PLANS', data.data.plans);
    return data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
