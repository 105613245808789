import Vue from 'vue';
import smoothscroll from 'smoothscroll-polyfill';
import VueSocketIOExt from 'vue-socket.io-extended';
import VeeValidate from 'vee-validate';
import VueCroppa from 'vue-croppa';
import VueNumeric from 'vue-numeric';
import '@fortawesome/fontawesome-pro/css/all.css';
import App from '@/App.vue';
import router from '@/router';
import socket from '@/plugins/socket';
import store from '@/store';
import '@/utils/filters';
import '@/plugins/buefy';
import logRocket from '@/plugins/logrocket';
import sentry from '@/plugins/sentry';
import intercom from '@/plugins/intercom';
import mixpanel from '@/plugins/mixpanel';
import googleAnalytics from '@/plugins/google-analytics';
import environmentGlobalVars from '@/utils/helpers/environment';
import api from './api/index.js';

Vue.use(environmentGlobalVars);
Vue.use(VeeValidate);
Vue.use(VueCroppa);
Vue.use(VueNumeric);
Vue.use(VueSocketIOExt, socket);
Vue.use(logRocket);
Vue.use(sentry);
Vue.use(intercom);
Vue.use(mixpanel);
Vue.use(googleAnalytics);
Vue.use(api);

Vue.config.productionTip = false;

// scrollTo polyfill for Safari (http://iamdustan.com/smoothscroll/)
smoothscroll.polyfill();

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
