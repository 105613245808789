import { apiClient } from '../apiClient';

const DOMAIN = 'downloads';

export default {
  create: (payload) => apiClient.post(`/${DOMAIN}`, payload),
  get: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}`, { params }),
  update: ({ id, ...payload }) => apiClient.patch(`/${DOMAIN}/${id}`, payload),
  delete: ({ id }) => apiClient.delete(`/${DOMAIN}/${id}`),

  createPreset: (payload) => apiClient.post('/download_presets', payload),
  getPresets: (params) => apiClient.get('/download_presets', { params }),
  updatePreset: ({ id, ...params }) => apiClient.get(`/download_presets/${id}`, { params }),

  getColumns: (params) => apiClient.get(`/${DOMAIN}/_column_definitions`, { params }),
};
