import Vue from 'vue';
import { cloneDeep } from '@/utils/helpers/methods';
import {
  productionReportingFields,
  typesAndSubTypes,
  sortMethod,
  productionAggs,
} from '@/utils/helpers/es/suppliers';
import { cleanProductionData } from '@/utils/helpers/es/production_reporting';

const defaultSupplierGroup = {
  id: null,
  code: '',
  is_brand: false,
  is_chain: false,
  name: null,
  _embedded: {
    child_groups: [],
    parent_groups: [],
  },
}

const defaultFilters = {
  sort: { 'total_revenue': 'desc' },
}

const defaultAggs = {
  total_count: {
    value: 0
  },
  production_reports: {
    current_period: {
      by_quarter: {
        buckets: [],
      }
    },
    previous_period: {
      by_quarter: {
        buckets: [],
      }
    },
    total_booking_count: { value: 0 },
    total_commission: { value: 0 },
    total_revenue: { value: 0 }
  }
};

const defaultQueryFields = [
  { key: 'name.^3', label: 'Name' },
  { key: 'name.autocomplete_no_punctuation^3', label: 'Name' },
  { key: 'id', label: 'Group ID' },
  { key: 'codes', label: 'Chain Code' },
];

const state = {
  aggs: cloneDeep(defaultAggs),
  supplier_group: cloneDeep(defaultSupplierGroup),
  supplier_groups: [],
  filters: cloneDeep(defaultFilters),
  refresh: false,
  queryFields: defaultQueryFields.map(({ key }) => key),
  pagination: {
    page: 1,
    per: 25,
    total: 0,
  },
  loading: {
    search: false
  },
}


const mutations = {
  SET_SUPPLIER_GROUPS_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
  RESET_SUPPLIER_GROUPS(state, payload) {
    switch (payload) {
      case 'editing':
        state.editing = cloneDeep(defaultSupplierGroup);
        break;
      case 'supplier':
        state.supplier = cloneDeep(defaultSupplierGroup);
        break;
      case 'filters':
        state.filters = cloneDeep(defaultFilters);
        break;
      default:
    }
  },
}

const getters = {
  supplierGroupsSearching: (state) => state.loading.search,
  supplierGroup: (state) => state.supplier_group,
  supplierGroups: (state) => state.supplier_groups,
  supplierGroupsAggregations: (state) => state.aggs,
  supplierGroupsTotal: (state) => state.aggs.total_count?.value || 0,
  supplierGroupsPagination: (state) => state.pagination,
  supplierGroupsRefresh: (state) => state.refresh,
  supplierGroupsFilters: (state) => state.filters,
  supplierGroupsQueryFields: (state) => state.queryFields,
  supplierGroupsProductionData: (state, getters, rootState, rootGetters) => cleanProductionData(getters.supplierGroupsAggregations, rootGetters['productionReporting/selectedReportingOption']),
  supplierGroupsEsQuery: (state, getter, _rootState, rootGetters) => {
    const currentReportingDates = rootGetters['productionReporting/currentReportingDates'];
    const previousReportingDates = rootGetters['productionReporting/previousReportingDates'];
    const suppliersFilters = rootGetters['suppliers/suppliersFilters'];

    const companies = suppliersFilters.companies;

    const from = state.pagination.per * (state.pagination.page - 1);

    const query = {
      bool: {
        must: [
          {
            bool: {
              should: [
                {
                  bool: { must_not: [{ exists: { field: '_embedded.company.id' } }] }
                },
                {
                  terms: { '_embedded.company.id': companies }
                }
              ]
            }
          },
        ]
      },
    }

    if (suppliersFilters.supplier_groups.length > 0) {
      const ids = suppliersFilters.supplier_groups.map(({ id }) => id );
      const groupsQuery = {
        bool: {
          must: [
            { terms: { 'id':  ids } }
          ]
        }
      }
      query.bool.must.push(groupsQuery);
  }

    if (suppliersFilters.programs.length > 0) {
      const programsQuery = {
        nested: {
          path: '_embedded.preferred_programs',
          query: {
            bool: {
              must: [
                { terms: { '_embedded.preferred_programs.id':  suppliersFilters.programs } }
              ]
            }
          }
        }
      }
      query.bool.must.push(programsQuery);
    }

    // Tags
    const { tags } = suppliersFilters;
    if (tags?.length > 0) {
      const tagsQuery = {
        bool: {
          must: [
            { terms: { '_embedded.tags':  tags } }
          ]
        }
      }
      query.bool.must.push(tagsQuery);
    }

    // Types & SubTypes
    if (suppliersFilters.supplier_types.length > 0) {
      const typesQuery = typesAndSubTypes(suppliersFilters.supplier_types);
      query.bool.must.push(typesQuery);
    }

    if (suppliersFilters.queryStrings && suppliersFilters.queryStrings.length > 0) {
      const queryStringsQuery = {
        bool: {
          should: [],
        },
      };
      suppliersFilters.queryStrings.forEach((queryString) => {
        if (queryString) {
          queryStringsQuery.bool.should.push({
            multi_match: {
              query: queryString.toUpperCase(),
              fields: suppliersFilters.fields.query,
              type: 'bool_prefix',
              operator: 'and',
              fuzziness: 1
            },
          });
        }
      });
      query.bool.must.push(queryStringsQuery);
    }

    const supplierGroupProductionReportingFields = (fieldName = '') => {
      return productionReportingFields(
        fieldName,
        companies,
        suppliersFilters.iatas,
        currentReportingDates
      )
    }

    const aggs = {
      'total_count': {
        'cardinality': {
          'field': '_id'
        }
      },
      'production_reports': {
        'nested': {
          'path': '_embedded.production_reports'
        },
        'aggs': {
          'current_period': productionAggs(companies, suppliersFilters.iatas, currentReportingDates),
        }
      }
    };

    if (previousReportingDates.startDate && previousReportingDates.endDate) {
      aggs.production_reports.aggs['previous_period'] = productionAggs(companies, suppliersFilters.iatas, previousReportingDates);
    }

    return {
      _source: { excludes: '_embedded.production_reports' },
      aggs,
      size: state.pagination.per,
      from: from >= 0 ? from : 0,
      sort: sortMethod(getter.supplierGroupsFilters.sort, companies, suppliersFilters.iatas, currentReportingDates, true),
      query: {
        constant_score: {
          filter: query,
        },
      },
      script_fields: {
        total_commission: supplierGroupProductionReportingFields('commission'),
        total_revenue: supplierGroupProductionReportingFields('revenue'),
        total_booking_count: supplierGroupProductionReportingFields('booking_count')
      },
    }
  },
  supplierGroupEsQuery: (state, getter, rootState, rootGetters) => (supplierGroupId) =>  {
    // Todo: abstract shared functionality with suppliersEsQuery?
    const suppliersFilters = rootGetters['suppliers/suppliersFilters'];
    const companies = suppliersFilters.companies || [];
    const iatas = suppliersFilters.iatas || [];
    const currentReportingDates = rootGetters['productionReporting/currentReportingDates'];
    const previousReportingDates = rootGetters['productionReporting/previousReportingDates'];

    const query = {
      bool: {
        must: [
          {
            bool: {
              should: [
                {
                  bool: { must_not: [{ exists: { field: '_embedded.company.id' } }] }
                },
                {
                  terms: { '_embedded.company.id': companies }
                }
              ]
            }
          },
          {
            bool: {
              must: [{ term: { 'id': supplierGroupId } }]
            }
          }
        ]
      },
    }
    const supplierGroupProductionReportingFields = (fieldName = '') => {
      return productionReportingFields(
        fieldName,
        companies,
        iatas,
        currentReportingDates
      )
    }

    const aggs = {
      'production_reports': {
        'nested': {
          'path': '_embedded.production_reports'
        },
        'aggs': {
          'current_period': productionAggs(companies, iatas, currentReportingDates),
        }
      }
    };

    if (previousReportingDates.startDate && previousReportingDates.endDate) {
      aggs.production_reports.aggs['previous_period'] = productionAggs(companies, iatas, previousReportingDates);
    }

    return  {
      _source: { excludes: '_embedded.production_reports' },
      aggs,
      query: {
        constant_score: {
          filter: query,
        },
      },
      script_fields: {
        total_commission: supplierGroupProductionReportingFields('commission'),
        total_revenue: supplierGroupProductionReportingFields('revenue'),
        total_booking_count: supplierGroupProductionReportingFields('booking_count')
      }
    };
  },
}

const actions = {
  async doGetSupplierGroup({ commit }, payload) {
    const { ignore, ...params } = payload;
    const { data } = await this._vm.$api.supplier_groups.getSupplierGroup(params);
    if (!ignore) {
      commit('SET_SUPPLIER_GROUPS_ATOMIC', { key: 'supplier_group', val: data.supplier_groups[0] });
    }
    return data;
  },
  async doGetSupplierGroups({ commit, getters }, payload) {
    const { data, pagination } = await this._vm.$api.supplier_groups.getSupplierGroups({ ...payload, ...getters.supplierGroupsPagination });
    commit('SET_SUPPLIER_GROUPS_ATOMIC', { key: 'supplier_groups', val: data.supplier_groups });
    commit('SET_SUPPLIER_GROUPS_ATOMIC', { key: 'pagination.total', val: pagination.page_count });
    commit('SET_SUPPLIER_GROUPS_ATOMIC', { key: 'pagination.page', val: pagination.page });
  },
  setDefaultSupplierGroup({ commit }) {
    commit('SET_SUPPLIER_GROUPS_ATOMIC', { key: 'supplier_group', val: cloneDeep(defaultSupplierGroup) });
  },
  async doSearchSupplierGroups({ commit }, payload) {
    commit('SET_SUPPLIER_GROUPS_ATOMIC', { key: 'loading.search', val: true });
    const { ignore, ...params } = payload;
    const { data } = await this._vm.$api.supplier_groups.search(params);

    const supplierGroups = data.hits.hits.map((hit) => {
      const scriptFields = {};
      Object.keys(hit.fields).forEach((key) => {
        const val = hit.fields[key];
        [scriptFields[key]] = val;
        return val;
      })
      return Object.assign(hit._source, scriptFields);
    });

    if (!ignore) {
      commit('SET_SUPPLIER_GROUPS_ATOMIC', { key: 'supplier_groups', val: supplierGroups });
      commit('SET_SUPPLIER_GROUPS_ATOMIC', { key: 'aggs', val: data.aggregations });
      commit('SET_SUPPLIER_GROUPS_ATOMIC', { key: 'pagination.total', val: data.hits.total.value });
    }
    commit('SET_SUPPLIER_GROUPS_ATOMIC', { key: 'loading.search', val: false });

    return { supplierGroups, aggs: data.aggregations };
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
