import { HTTP } from '@/utils/http';

const rules = {
  agency_name: {
    getMessage: () => 'Leave blank if the contractor doesn\'t have a business name.',
    async validate(value, args) {
      const hostAgencyName = args.host_agency ? args.host_agency.toLowerCase() : '';
      const agencyName = value.toLowerCase();
      const valid = agencyName !== hostAgencyName;
      return { valid };
    }
  },
  iata: {
    getMessage: (field) => `The ${field} field must contain exactly eight digits.`,
    validate(value) {
      if (!value) return false;
      return value.toString().match(/^\d{8}$/) !== null;
    },
  },
  password: {
    getMessage: (field) =>
      `The ${field} field must contain at least eight characters, one capital letter, and one number.`,
    validate(value) {
      if (!value) return false;
      if (value.length < 8) return false;
      if (value.match(/[A-Z]/) === null) return false;
      if (value.match(/[0-9]/) === null) return false;
      return true;
    },
  },
  source_id: {
    getMessage: (field, params, data) => data.message,
    validate(value, args) {
      let valid = true;
      let message = null;
      if (!value) {
        valid = false;
        message = 'This field is required';
      }
      if (value.match(/\W/g)) {
        valid = false;
        message = 'Only letters and numbers are allowed';
      }
      let charLimit = 4;
      let label = 'Sabre PCC';
      if (args.source === 'amadeus') {
        charLimit = 9;
        label = 'Amadeus Office ID';
      } else if (args.source === 'travelport') {
        label = 'Travelport PCC';
      }
      if (value.length < charLimit || value.length > charLimit) {
        valid = false;
        message = `Must be a valid ${charLimit} character ${label}`;
      }
      return {
        valid,
        data: {
          message,
        },
      };
    },
  },
  unique: {
    getMessage: (field, params, data) => data.message,
    async validate(value, args) {
      let valid = false;
      let message;
      if (value) {
        let key;
        let field;
        switch (args.type) {
          case 'user': {
            key = 'users';
            field = 'email';
            message = 'This email address is already associated with a Sion account.';
            break;
          }
          case 'source_branch': {
            key = 'source_branches';
            field = 'source_id';
            message = `${value.toUpperCase()} is already a branch on this PCC.`;
            break;
          }
          default:
        }
        if (args.whitelisted) {
          valid = true;
        } else if (key) {
          const { scope } = args;
          const params = { ...scope };
          params[field] = value;
          const { data } = await HTTP.get(`/${key}_public/`, { params });
          valid = data.record_count === 0;
          if (data.record_count > 0 && args.ignore) {
            const [obj] = data.data[key];
            valid = args.ignore.includes(obj.id);
          }
        }
      }
      return {
        valid,
        data: {
          message,
        },
      };
    },
  },
};

export default (Validator) => {
  Object.keys(rules).forEach((rule) => Validator.extend(rule, rules[rule]));
};
