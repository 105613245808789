import { dayjs } from '@/plugins/dayjs';
import { dateFormatYMD } from '@/utils/helpers/dates';

class Batch {
  constructor(obj) {
    this.obj = obj;
  }

  format() {
    const batch = this.obj;
    if (batch && batch.date) {
      batch.date = dayjs(batch.date, dateFormatYMD).toDate();
    }
    return batch;
  }
}

export default Batch;
