<template lang="pug">
#no-iata-modal
  .modal-card
    header.modal-card-head
    section.modal-card-body
      .content.has-text-centered
        sion-logo(:width="160")
        h2.has-text-weight-semibold Now it&rsquo;s {{ agencyName }}&rsquo;s turn.
        p Your Sion account is set up . . . but a little empty. {{ agencyName }} is working on importing your bookings and will let you know when you can log in and take Sion for a spin.
        p See you soon.
    footer.modal-card-foot
</template>

<script>
import { mapGetters } from 'vuex';
import SionLogo from '@/components/logos/SionLogo.vue';

export default {
  name: 'SuspendedModal',
  components: {
    SionLogo,
  },
  computed: {
    ...mapGetters('companies', ['company']),
    agency() {
      return this.company._embedded.host_agencies[0];
    },
    agencyName() {
      if (this.agency) {
        return this.agency.name;
      }
      return 'your host agency';
    },
  },
};
</script>
