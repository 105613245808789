import Vue from 'vue';
import { HTTP } from '@/utils/http';

const state = {
  period: {},
  zones: [],
};

const mutations = {
  SET_TIME_ZONES_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
};

const getters = {
  timeZonePeriod: (state) => state.period, // used?
  timeZones: (state) => state.zones,
};

const actions = {
  async doGetTimeZonePeriod({ commit }, payload) {
    // used?
    const { ignore, ...params } = payload;
    const { data } = await HTTP.get('/time_zones/current_period', { params });
    if (!ignore) {
      const val = data.data.current_period;
      commit('SET_TIME_ZONES_ATOMIC', { key: 'period', val });
    }
    return data;
  },
  async doGetTimeZones({ commit }, payload) {
    const { ignore, ...params } = payload;
    const { data } = await HTTP.get('/time_zones', { params });
    if (!ignore) commit('SET_TIME_ZONES_ATOMIC', { key: 'zones', val: data.data.time_zones });
    return data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
