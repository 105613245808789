<template>
  <form
    id="password-reset-modal"
    autocomplete="false"
    class="modal-card password-reset-modal"
    @submit.prevent="submit"
  >
    <header class="modal-card-head">
      <h2 class="modal-card-title">Reset your password</h2>
    </header>
    <section class="password-reset-modal__body">
      <section>
        <b-field
          label="New Password"
          :type="errors.has('password') ? 'is-danger' : ''"
          :message="errors.first('password')"
          class="mb-4"
          custom-class="password-reset-modal__input-label"
        >
          <b-input
            ref="password"
            v-validate="'required|password'"
            data-vv-validate-on="blur"
            type="password"
            :value="user.password"
            autocomplete="new-password"
            name="password"
            data-vv-delay="1000"
            password-reveal
            placeholder="********"
            icon="lock-02"
            :icon-pack="$defaultIconPack"
            @input="set($event, 'password')"
          />
        </b-field>
        <b-field
          label="Confirm Password"
          :type="errors.has('password_confirm') ? 'is-danger' : ''"
          :message="errors.first('password_confirm')"
          custom-class="password-reset-modal__input-label"
        >
          <b-input
            v-validate="'required|confirmed:password'"
            data-vv-validate-on="blur"
            type="password"
            :value="user.password_confirm"
            name="password_confirm"
            data-vv-as="password"
            data-vv-delay="1000"
            password-reveal
            placeholder="********"
            icon="lock-02"
            :icon-pack="$defaultIconPack"
            @input="set($event, 'password_confirm')"
          />
        </b-field>
      </section>
      <section>
        <div>
          <p class="mb-3 is-flex is-align-content-center">
            <b-icon
              icon="shield-tick"
              :pack="$defaultIconPack"
              custom-size="is-size-5 mr-2"
              type="is-cool"
            />
            <span>Requirements</span>
          </p>
          <ul>
            <li v-for="item in requirements" :key="`requirement--${item.type}`">
              <BadgeTag
                class="password-reset-modal__requirement"
                status-dot
                type="is-default"
                :dot-type="item.isValid ? 'is-success' : 'is-default'"
              >
                {{ item.copy }}
              </BadgeTag>
            </li>
          </ul>
        </div>
      </section>
    </section>
    <footer>
      <b-button expanded>Cancel</b-button>
      <b-button
        type="is-primary"
        native-type="submit"
        expanded
        :class="{ 'is-loading': settings.submitting }"
      >
        Save Password
      </b-button>
    </footer>
  </form>
</template>

<script>
import { reportError } from '@/utils/helpers/tracking/tracking-methods';
import alphanumericOptions from '@/data/alphanumeric-options';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import BadgeTag from '@/components/_base/atoms/BadgeTag.vue'

export default {
  name: 'PasswordResetModal',
  components: { BadgeTag },
  data() {
    return {
      capitals: alphanumericOptions.alphabetUppercaseList,
      numbers: alphanumericOptions.numbersOneDigitList,
      settings: {
        submitting: false,
      },
    };
  },
  computed: {
    ...mapGetters('users', ['user']),
    passwordMinCharacters() {
      const { password } = this.user;
      if (!password) return false;
      return password.length >= 8;
    },
    passwordMinCapitalization() {
      const { password } = this.user;
      if (!password) return false;
      return password.match(this.capitals.join('|')) !== null;
    },
    passwordMinNumbers() {
      const { password } = this.user;
      if (!password) return false;
      return password.match(this.numbers.join('|')) !== null;
    },
    requirements() {
      return [
        {
          type: 'length',
          copy: 'At least 8 characters long',
          isValid: this.passwordMinCharacters
        },
        {
          type: 'capitalization',
          copy: 'At least one capital letter',
          isValid: this.passwordMinCapitalization
        },
        {
          type: 'number',
          copy: 'At least one number',
          isValid: this.passwordMinNumbers
        }
      ]
    }
  },
  methods: {
    ...mapMutations('app', ['SET_APP_SNACKBAR']),
    ...mapMutations('users', ['SET_USERS_ATOMIC']),
    ...mapActions('users', ['doResetUserPassword']),
    set(val, key) {
      this.SET_USERS_ATOMIC({ key: `user.${key}`, val });
    },
    async submit() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) return;

      try {
        this.settings.submitting = true;
        const payload = {
          id: this.user.id,
          password: this.user.password,
        };
        await this.doResetUserPassword(payload);
        this.SET_USERS_ATOMIC({ key: 'self.require_password_reset', val: false });
        this.$parent.close();
        this.settings.submitting = false;
        this.SET_APP_SNACKBAR({
          type: 'is-success',
          message: 'Your password was reset.',
        });
      } catch (error) {
        reportError(error);
        this.SET_APP_SNACKBAR({
          type: 'is-danger',
          message: error.response.data.data.error_message,
        });
      }
    }
  },
};
</script>

<style lang="scss">
.modal form.modal-card.password-reset-modal {
  overflow: hidden !important;
  border-radius: $sion-modal-radius !important;
}
.password-reset-modal {
  background-color: $primary-bg-color;
  header.modal-card-head {
    padding: $spacing-4 $spacing-7;
    border-bottom: 1px solid $border;
    .modal-card-title {
      line-height: pxToRem(32);
      font-size: pxToRem(24);
      color: $text-strong;
    }
  }
  &__body {
    min-height: pxToRem(248);
    width: 100%;
    display: flex;
    section {
      width: 50%;
      padding: $spacing-5 $spacing-7;
      &:first-child {
        border-right: 1px solid $border;
      }
    }
    .icon.is-right.has-text-primary.is-clickable {
      color: $grey-lighter !important;
    }
  }
  ul {
    display: grid;
    row-gap: $spacing-3;
  }
  &__requirement {
    font-size: pxToRem(14) !important;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
    .status-dot {
      height: pxToRem(8);
      width: pxToRem(8);
    }
  }
  .password-reset-modal__input-label {
    color: $text-strong;
    font-weight: 400;
    font-size: pxToRem(14);
  }
  footer {
    height: pxToRem(56);
    display: flex;
    .button {
      height: 100%;
      border-radius: unset;
      box-shadow: none !important;
    }
  }
}
</style>
