import { apiClient } from '../apiClient';

const DOMAIN = 'statements';

export default {
  create: (payload) => apiClient.post(`/${DOMAIN}`, payload),
  get: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}`, { params }),
  update: ({ id, ...payload }) => apiClient.patch(`/${DOMAIN}/${id}`, payload),
  search: (payload) => apiClient.post(`/sion_${DOMAIN}/_search`, payload),
  getComments: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}/comments`, { params }),

  createAdjustment: ({ id, ...payload }) => apiClient.post(`/${DOMAIN}/${id}/adjustments`, payload),
  getAdjustment: ({ id, adjustment_id, ...params }) => {
    apiClient.get(`/${DOMAIN}/${id}/adjustments/${adjustment_id}`, { params })
  },
  updateAdjustment: ({ id, adjustment_id, ...payload }) => {
    apiClient.patch(`/${DOMAIN}/${id}/adjustments/${adjustment_id}`, payload)
  },
  deleteAdjustment: ({ id, adjustment_id, ...payload }) => {
    apiClient.delete(`/${DOMAIN}/${id}/adjustments/${adjustment_id}`, payload)
  },
};
