import { HTTP } from '@/utils/http';

const state = {
  currency: {},
  currencies: []
};

const mutations = {
  SET_CURRENCIES(state, currencies) {
    state.currencies = currencies;
  }
};

const getters = {
  currencies: (state) => state.currencies,
  currencyRates: (state) => {
    const rates = {};
    state.currencies.forEach(currency => {
      const row = {};
      currency._embedded.exchange_rates.forEach(rate => {
        row[rate.symbol] = rate.rate;
      });
      row[currency.symbol] = 1.0;
      rates[currency.symbol] = row;
    });
    return rates;
  },
  currencyBySymbol: (state) => (currencySymbol) => {
    const currency = state.currencies.find(({ symbol }) => symbol === currencySymbol);
    return currency || {};
  }
};

const actions = {
  async doGetCurrencies({ commit }, params) {
    const { data } = await HTTP.get('/currencies', { params });
    commit('SET_CURRENCIES', data.data.currencies);
    return data;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
