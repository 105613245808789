import Vue from 'vue';
import { HTTP } from '@/utils/http';

const state = {
  preset: {},
  presets: [],
  settings: {
    edited: false,
    new: true,
    save: false,
  },
};

const mutations = {
  SET_IMPORT_PRESET_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
};

const getters = {
  importPreset: (state) => state.preset,
  importPresetSettings: (state) => state.settings,
  importPresets: (state) => state.presets,
};

const actions = {
  async doCreateImportPreset({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.post(`/companies/${id}/import_presets`, params);
    if (!ignore)
      commit('SET_IMPORT_PRESET_ATOMIC', { key: 'preset', val: data.data.import_presets[0] });
    return data;
  },
  async doGetImportPresets({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.get(`/companies/${id}/import_presets`, { params });
    if (!ignore)
      commit('SET_IMPORT_PRESET_ATOMIC', { key: 'presets', val: data.data.import_presets });
    return data;
  },
  async doUpdateImportPreset({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.patch(`/import_presets/${id}`, params);
    if (!ignore)
      commit('SET_IMPORT_PRESET_ATOMIC', { key: 'preset', val: data.data.import_presets[0] });
    return data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
