import { apiClient } from '../apiClient';

const DOMAIN = 'invoices';

export default {
  create: (payload) => apiClient.post(`/${DOMAIN}`, payload),
  delete: ({ id, ...payload }) => apiClient.delete(`/${DOMAIN}/${id}`, payload),
  get: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}`, { params }),
  update: ({ id, ...payload }) => apiClient.patch(`/${DOMAIN}/${id}`, payload),

  createFollowUp: ({ id, ...payload }) => apiClient.post(`/${DOMAIN}/${id}/follow_ups`, payload),
  getFollowUps: ({ id, ...payload }) => apiClient.post(`/${DOMAIN}/${id}/follow_ups`, payload),

  getDownload: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}/download`, { params }),

  createTemplate: (payload) => apiClient.post('/invoice_templates', payload),
  getTemplate: ({ id, ...payload }) => apiClient.post(`/companies/${id}/invoice_templates`, payload),
  updateTemplate: ({ id, ...payload }) => apiClient.post(`/invoice_templates/${id}`, payload),

  createSchedule: (payload) => apiClient.post('/invoice_schedules', payload),
  updateSchedule: ({ id, ...payload }) => apiClient.put(`/invoice_schedules/${id}`, payload),
};
