import { apiClient } from '../apiClient';

const DOMAIN = 'comments';

export default {
  create: (payload) => apiClient.post(`/${DOMAIN}`, payload),
  get: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}`, { params }),
  update: ({ id, ...payload }) => apiClient.patch(`/${DOMAIN}/${id}`, payload),
  delete: ({ id, ...payload }) => apiClient.delete(`/${DOMAIN}/${id}`, payload),
  markRead: ({ id, ...payload }) => apiClient.post(`/${DOMAIN}/${id}/reads`, payload),
};
