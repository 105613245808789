import Vue from 'vue';
import {
  ConfigProgrammatic,
  Autocomplete,
  Button,
  Breadcrumb,
  Checkbox,
  Collapse,
  Datepicker,
  Dialog,
  Dropdown,
  Field,
  Image,
  Input,
  Numberinput,
  Pagination,
  Progress,
  Radio,
  Rate,
  Select,
  Skeleton,
  Slider,
  Snackbar,
  Steps,
  Switch,
  Tabs,
  Table,
  Tag,
  Taginput,
  Tooltip,
  Upload,
  Icon,
  Modal,
  Navbar,
  Notification,
} from 'buefy'


Vue.use(Autocomplete);
Vue.use(Button);
Vue.use(Breadcrumb);
Vue.use(Checkbox);
Vue.use(Collapse);
Vue.use(Datepicker);
Vue.use(Dialog);
Vue.use(Dropdown);
Vue.use(Field);
Vue.use(Icon);
Vue.use(Image);
Vue.use(Input);
Vue.use(Modal);
Vue.use(Navbar);
Vue.use(Notification);
Vue.use(Numberinput);
Vue.use(Pagination);
Vue.use(Progress);
Vue.use(Radio);
Vue.use(Rate);
Vue.use(Select);
Vue.use(Skeleton);
Vue.use(Slider);
Vue.use(Snackbar);
Vue.use(Steps);
Vue.use(Switch);
Vue.use(Table);
Vue.use(Tabs);
Vue.use(Tag);
Vue.use(Taginput);
Vue.use(Tooltip);
Vue.use(Upload);

Vue.prototype.$defaultIconPack = 'heroicons';

const buefyOptions = {
  customIconPacks: {
    'heroicons': {
      // Map sizes, so for example if button size is-small what size will the font be
      sizes: {
        'default': 'is-size-6',
        'is-small': 'is-size-6',
        'is-medium': 'is-size-3',
        'is-large': 'is-size-1'
      },
      iconPrefix: 'icon-',
      // Icons they already use, if we want to map them to ours then we can add them below
      internalIcons: {
        'close-circle': 'x-circle',
        'alert-circle': 'alert-circle',
        'angle-down': 'chevron-down',
      }
    },
  }
}

ConfigProgrammatic.setOptions(buefyOptions);
