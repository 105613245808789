import { HTTP } from '@/utils/http';
import { cloneDeep } from '@/utils/helpers/methods';
import Vue from 'vue';

const defaultConfigs = {
  client: {
    is_downloadable: true,
    is_editable: false,
    is_private: false,
    max_days: 30,
    payment_threshold: null,
    payment_types: ['credit_card', 'online'],
  },
  supplier: {
    is_downloadable: true,
    is_editable: true,
    is_private: true,
    max_days: 30,
    payment_threshold: null,
    auto_apply: false,
    auto_apply_event: null,
    auto_apply_keys: [],
    payment_types: ['bank_transfer'],
  },
};

const state = {
  paymentGateway: {
    client: {},
    supplier: {},
  },
  errors: {
    autoApplyKeysRequired: false,
    paymentPreferenceRequired: null,
  },
};

const mutations = {
  SET_INTEGRATION_CONFIGURATION_ATOMIC(integrationConfigState, payload) {
    const nodes = payload.key.split('.');
    let obj = integrationConfigState;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
};

const getters = {
  paymentGatewayIntegrationConfigurations: ({ paymentGateway }) => ({
    client: paymentGateway.client,
    supplier: paymentGateway.supplier,
  }),
  autoApplyKeysRequired: ({ errors }) => errors.autoApplyKeysRequired,
  paymentPreferenceRequired: ({ errors }) => errors.paymentPreferenceRequired,
};

const actions = {
  async doCreateIntegrationConfiguration({ commit }, payload) {
    const { configType, ...params } = payload;
    const { data } = await HTTP.post('/integration_configurations', params);
    const configuration = data.data.integration_configurations[0];
    commit('SET_INTEGRATION_CONFIGURATION_ATOMIC', { key: `paymentGateway.${configType}`, val: configuration });
    return configuration;
  },
  async doUpdateIntegrationConfiguration({ commit }, payload) {
    const { configType, id, ...params } = payload;
    const { data } = await HTTP.patch(`/integration_configurations/${id}`, params);
    const configuration = data.data.integration_configurations[0];
    commit('SET_INTEGRATION_CONFIGURATION_ATOMIC', { key: `paymentGateway.${configType}`, val: configuration });
    return configuration;
  },
  async doDeleteIntegrationConfiguration({ dispatch }, { type, id }) {
    await HTTP.delete(`/integration_configurations/${id}`);
    dispatch('removePaymentGatewayIntegrationConfiguration', type);
  },
  setPaymentGatewayIntegrationConfigurationToDefault({ commit }, key) {
    commit('SET_INTEGRATION_CONFIGURATION_ATOMIC', {
      key: `paymentGateway.${key}`,
      val: cloneDeep(defaultConfigs[key]),
    });
  },
  removePaymentGatewayIntegrationConfiguration({ commit }, key) {
    commit('SET_INTEGRATION_CONFIGURATION_ATOMIC', {
      key: `paymentGateway.${key}`,
      val: {},
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
