import Vue from 'vue';
import { cloneDeep } from '@/utils/helpers/methods';

const defaultContact = {
  type: 'accounting',
};

const queryFields = [];

const defaultFilters = {
  batches: [],
  categories: [],
  clients: [],
  companies: [],
  dates: {
    from: null,
    month: 1,
    range: 'year',
    to: null,
    quarter: 1,
    year: 'all',
  },
  employees: [],
  fields: {
    date: 'last_active',
    query: [],
  },
  iatas: [],
  queryStrings: [],
  sort: {
    created_at: 'desc',
  },
  statuses: ['active', 'inactive'],
  suppliers: [],
  tags: [],
  types: ['corporate', 'leisure'],
  virtuoso: {},
};

const defaultPlaceDetails = {};

const defaultReporting = {
  active: 365,
  dates: 30,
  gross: true,
  new: 30,
  total: 'all',
  view: 'revenue',
  date_field: 'check_in'
};

const state = {
  contact: cloneDeep(defaultContact),
  editing: cloneDeep(defaultContact),
  filters: cloneDeep(defaultFilters),
  placeDetails: cloneDeep(defaultPlaceDetails),
  placeDetailsEditing: cloneDeep(defaultPlaceDetails),
  queryFields: cloneDeep(queryFields),
  refresh: false,
  reporting: cloneDeep(defaultReporting),
  settings: {
    manual: true,//Todo remove manual state
    profile: true,
  },
};

const mutations = {
  RESET_CONTACTS(state, payload) {
    switch (payload) {
      case 'contact':
        state.contact = cloneDeep(defaultContact);
        break;
      case 'editing':
        state.editing = cloneDeep(defaultContact);
        break;
      case 'filters':
        state.filters = cloneDeep(defaultFilters);
        break;
      case 'placeDetails':
        state.placeDetails = cloneDeep(defaultPlaceDetails);
        break;
      case 'placeDetailsEditing':
        state.placeDetailsEditing = cloneDeep(defaultPlaceDetails);
        break;
      case 'reporting':
        state.reporting = cloneDeep(defaultReporting);
        break;
      default:
    }
  },
  SET_CONTACTS_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
};

const getters = {
  contact: (state) => state.contact, // used?
  contactEditing: (state) => state.editing,
  contactPlaceDetails: (state) => state.placeDetails,
  contactPlaceDetailsEditing: (state) => state.placeDetailsEditing,
  contactsRefresh: (state) => state.refresh,
  contactsReporting: (state) => state.reporting,
  contactsSettings: (state) => state.settings,
};

const actions = {
  async doCreateContact({ commit }, payload) {
    const { data } = await this._vm.$api.contacts.create(payload);
    commit('SET_CONTACTS_ATOMIC', { key: 'contact', val: data.contacts[0] });
    commit('SET_CONTACTS_ATOMIC', { key: 'refresh', val: true });
  },
  async doUpdateContact({ commit }, payload) {
    const { data } = await this._vm.$api.contacts.update(payload);
    commit('SET_CONTACTS_ATOMIC', { key: 'contact', val: data.contacts[0] });
    commit('SET_CONTACTS_ATOMIC', { key: 'refresh', val: true });
  },
  async doDeleteContact({ commit }, payload) {
    await this._vm.$api.contacts.delete(payload);
    commit('SET_CONTACTS_ATOMIC', { key: 'contact', val: {} });
    commit('SET_CONTACTS_ATOMIC', { key: 'refresh', val: true });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
