import { apiClient } from '../apiClient';

const DOMAIN = 'companies';

export default {
  create: (payload) => apiClient.post(`/${DOMAIN}`, payload),
  get: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}`, { params }),
  update: ({ id, ...payload }) => apiClient.patch(`/${DOMAIN}/${id}`, payload),

  createAddress: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}/address`, { params }),

  getContractors: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}/contractors`, { params }),

  createSubscription: ({ id, ...payload }) => apiClient.post(`/${DOMAIN}/${id}/subscription`, payload),
  getSubscription: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}/subscription`, { params }),

  createContractorConfig: (payload) => apiClient.post('/contractor_configurations/', payload),
  updateContractorConfig: ({ id, ...params }) => apiClient.patch(`/contractor_configurations/${id}`, params),
};
