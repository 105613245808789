import Vue from 'vue';
import { dayjs } from '@/plugins/dayjs';
import { dateFormatUTC, dateFormatMDY } from '@/utils/helpers/dates';

const capitalizeMap = (str) => {
  if (!str) return null;
  const ignore = ['LLC'];
  const caps = str.toUpperCase();
  if (ignore.includes(caps)) {
    return caps;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

Vue.filter('capitalize', (val) => {
  if (!val) return '';
  const str = val.toString();
  if (str.match(/crm/i)) {
    return 'CRM';
  }
  return str
    .split(/_/)
    .map((s) => capitalizeMap(s))
    .join(' ');
});

Vue.filter('capitalizeEach', (val) => {
  if (!val) return '';
  const str = val.toString();
  return str
    .split(/_|\s/)
    .map((s) => capitalizeMap(s))
    .join(' ');
});

Vue.filter('currency', (val, currency, negative) => {
  let symbol = currency || 'USD';
  if (currency !== undefined && typeof currency === 'object') {
    symbol = currency.sign || currency.symbol;
  }
  const spacer = symbol.length === 1 ? '' : ' ';
  if (!val) return `${symbol}${spacer}0.00`;
  // const roundedVal = val < 0.50 ? val : Math.round(val, 2);
  const isNegative = val < 0;
  const postiveAmount = isNegative ? val * -1 : val;
  const components = postiveAmount.toFixed(2).split('.');
  components[0] = components[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const formatted = components.join('.');
  if ((negative || isNegative) && formatted !== '0.00') {
    if (symbol.length === 1) {
      return `-${symbol}${spacer}${formatted}`;
    }
    return `${symbol}${spacer}-${formatted}`;
  }
  return `${symbol}${spacer}${formatted}`;
});

Vue.filter('date', (val, format = dateFormatMDY, offset) => {
  if (!val) return '';
  let date = val;
  if (dayjs(val).isValid()) {
    date = dayjs(val);
  } else if (!dayjs.isDayjs(val)) {
    const str = val.toString();
    date = dayjs(str, dateFormatUTC);
  }
  if (offset) {
    date = date.utcOffset(offset);
  }
  return date.format(format);
});

Vue.filter('dateOffset', (val) => {
  if (!val) return '';
  const int = parseInt(val, 10);
  let sign = '+';
  let positive = int;
  if (val < 0) {
    positive *= -1;
    sign = '-';
  }
  let label = 'day';
  let number = positive;
  if (positive > 30 && positive <= 365) {
    label = 'month';
    number = Math.round(positive / 30);
  } else if (positive > 365) {
    label = 'year';
    number = Math.round(positive / 365);
  }
  const labelPlural = positive === 1 ? label : `${label}s`;
  return `${sign}${number} ${labelPlural}`;
});

Vue.filter('backOffice', (value) => {
  if (!value) return '';
  const str = value.toString();
  if (str === 'none') return 'these';
  
  return str.split(/_/).map((s) => capitalizeMap(s)).join('');
});

Vue.filter('host', (value) => {
  if (!value) return '';
  try {
    const url = new URL(value);
    return url.host;
  } catch(_e) {
    return '';
  }
});

Vue.filter('hashbang', (value) => {
  if (!value) return '';
  return `#${value}`;
});

Vue.filter('highlight', (value, prefix) => {
  if (!value) return '';
  const pattern = new RegExp(prefix, 'i');
  const highlighted = value.replace(pattern, '<span class="highlight">$&</span>');
  return highlighted;
});

Vue.filter('identifierType', (value) => {
  if (!value) return '';
  if (value === 'internal') {
    return 'Internal';
  }
  return value.toUpperCase();
});

Vue.filter('importCell', (value, column = {}, imp = {}) => {
  if (!value) return '';
  const zero = '0.00';
  if (
    column.formatter === 'currency' &&
    column.type === 'float' &&
    imp.currency_type === 'integer'
  ) {
    const float = parseFloat(value);
    if (float && float > 0) {
      const str = (float / 100.0).toFixed(2);
      const parts = str.split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return parts.join('.');
    }
    return zero;
  }
  return value;
});

Vue.filter('phone', (val) => {
  if (!val) return '';
  return val.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
});

Vue.filter('relativeTime', (val) => {
  if (!val) return '';
  let date = val;
  if (!dayjs.isDayjs(val)) {
    const str = val.toString();
    date = dayjs(str, dateFormatUTC);
  }
  return date.fromNow();
});

Vue.filter('roundedCurrency', (val, symbol) => {
  if (!val) return `${symbol} 0`;
  if (val < 1000) return `${symbol} ${val.toFixed(0)}`;
  const decPlaces = 10 ** 1;
  let number = 0;
  const abbrev = ['k', 'm', 'b', 't'];
  for (let i = abbrev.length - 1; i >= 0; i -= 1) {
    const size = 10 ** ((i + 1) * 3);
    if (size <= val) {
      number = Math.round((val * decPlaces) / size) / decPlaces;
      if (number === 1000 && i < abbrev.length - 1) {
        number = 1;
        i += 1;
      }
      number += abbrev[i];
      break;
    }
  }
  return `${symbol}${number}`;
});

Vue.filter('truncate', (val, stop, clamp) => {
  if (!val) return '';
  return val.slice(0, stop) + (stop < val.length ? clamp || '...' : '');
});

Vue.filter('unixDate', (val, format = dateFormatMDY) => {
  if (!val) return '';
  return dayjs.unix(val).format(format);
});

Vue.filter('uppercase', (value) => {
  if (!value) return '';
  return value.toUpperCase();
});

Vue.filter('paymentType', (value) => {
  if (!value) return '';
  switch (value.toLowerCase()) {
    case 'ihg':
      return value.toUpperCase();
    case 'tacs':
      return value.toUpperCase();
    case 'credit':
      return 'Credit Card';
    default:
  }
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter('percentage', (value, precision) => {
  if (!value) return '0%';
  return `${parseFloat(value).toFixed(precision || 0)}%`;
});

// https://gist.github.com/james2doyle/4aba55c22f084800c199
Vue.filter('prettyBytes', (num) => {
  if (!num) return 0;

  const neg = num < 0;
  const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  if (neg) {
    num = -num;
  }

  const minus = neg ? '-' : '';
  if (num < 1) {
    return `${minus}${num} B`;
  }

  const exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
  const base = 1000 ** exponent;
  num = (num / base).toFixed(2) * 1;
  const unit = units[exponent];

  return `${minus}${num} ${unit}`;
});

Vue.filter('separator', (val) => {
  if (!val) return 0;
  const str = val.toFixed(0);
  const parts = str.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (parts.length > 1) {
    const index = parts.length - 1;
    parts[index] = Math.round(parts[index]);
  }
  return `${parts.join('.')}`;
});

Vue.filter('abbreviateCount', (number) => {
  function formatNumber(num) {
    return num.toPrecision(3).replace(/\.0+$/, '').replace(/(\.\d+?)0+$/, '$1');
  }

  if (number >= 1e6) return formatNumber(number / 1e6) + 'M';
  if (number >= 1e3) return formatNumber(number / 1e3) + 'K';
  return number.toString();
})

Vue.filter('slug', (string) => {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
});

Vue.filter('zeroPadded', (value, limit) => {
  if (!value) return ''.padStart(limit, '0');
  return value.toString().padStart(limit, '0');
});
