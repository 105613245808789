import { cloneDeep } from '@/utils/helpers/methods';
import Vue from 'vue';
import iataData from '@/data/iata-types.json';

const defaultSettings = {
  stage: 1
};

const defaultPaymentInstructions = {
  show_check: false,
  check: {},
  show_wire: false,
  wire: {
    bank: {}
  }
};

const defaultIATA = {
  is_managed: false,
  is_verified: false,
  features: {
    hosted: false,
  },
  type: 'iata',
  _embedded: {
    booking_category: {},
    billing_profile: {
      payment_instructions: cloneDeep(defaultPaymentInstructions)
    }
  }
};

const defaultMessage = '';

const state = {
  iata: cloneDeep(defaultIATA),
  settings: cloneDeep(defaultSettings),
  types: iataData.types,
  message: cloneDeep(defaultMessage)
};

const mutations = {
  RESET_IATAS(state, payload) {
    const { iata } = state;
    switch (payload) {
      case 'iata':
        state.iata = cloneDeep(defaultIATA);
        break;
      case 'settings':
        state.settings = cloneDeep(defaultSettings);
        break;
      case 'paymentInstructions':
        state.iata = {
          ...iata,
          _embedded: {
            ...iata._embedded,
            billing_profile: {
              ...iata._embedded.billing_profile,
              payment_instructions: cloneDeep(defaultPaymentInstructions)
            }
          }
        };
        break;
      case 'message':
        state.message = cloneDeep(defaultMessage);
        break;
      default:
    }
  },
  SET_IATAS_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach(node => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  }
};

const getters = {
  iata: state => state.iata,
  iataBillingProfile: state => state.iata?._embedded?.billing_profile,
  iataSettings: state => state.settings,
  iataTypes: state => state.types,
  iataMessage: state => state.message
};

const actions = {
  async doCreateIata({ commit }, payload) {
    const { data, message } = await this._vm.$api.iatas.create(payload);
    commit('SET_IATAS_ATOMIC', { key: 'iata', val: data.iatas[0] });
    commit('SET_IATAS_ATOMIC', { key: 'message', val: message });
  },
  async doUpdateIata({ commit }, payload) {
    const { data, message } = await this._vm.$api.iatas.update(payload);
    commit('SET_IATAS_ATOMIC', { key: 'iata', val: data.iatas[0] });
    commit('SET_IATAS_ATOMIC', { key: 'message', val: message });
  },
  async doDeleteIata({ commit }, payload) {
    // used ?
    const { data } = await this._vm.$api.iatas.delete(payload);
    commit('SET_IATAS_ATOMIC', { key: 'iata', val: data.iatas[0] });
  },
  async doGetIata({ commit }, payload) {
    payload = { ...payload, zoom: 'booking_category,billing_profile' };
    const { data } = await this._vm.$api.iatas.get(payload);
    commit('SET_IATAS_ATOMIC', { key: 'iata', val: data.iatas[0] });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
