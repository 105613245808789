import { apiClient } from '../apiClient';

const DOMAIN = 'attachments';

export default {
  create: (payload, onUploadProgress) => {
    const headers = { ...apiClient.defaults.headers };
    headers['Content-Type'] = 'multipart/form-data';
    return apiClient.post(`/${DOMAIN}`, payload, { onUploadProgress, headers })
  },
  get: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}`, { params }),
  update: ({ id, ...payload }) => apiClient.patch(`/${DOMAIN}/${id}`, payload),
  delete: ({ id, ...payload }) => apiClient.delete(`/${DOMAIN}/${id}`, payload),
};
