import Vue from 'vue';

const state = {
  address: {},
  company: {},
  existingUser: false,
  iata: {},
  invite: {},
  inviteResponseKey: null,
  mode: null,
  modes: [
    { key: 'agency', label: 'I&rsquo;m part of a Travel Agency' },
    { key: 'contractor', label: 'I&rsquo;m an Independent Contractor' },
  ],
  totalSteps: 4,
  user: {},
};

const mutations = {
  SET_ONBOARDING_FIELD(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
  UNSET_ONBOARDING(state) {
    state.address = {};
    state.company = {};
    state.existingUser = false;
    state.iata = {};
    state.invite = {};
    state.inviteResponseKey = null;
    state.mode = null;
    state.totalSteps = 4;
    state.user = {};
  },
};

const getters = {
  onboardingAddress: (state) => state.address,
  onboardingCompany: (state) => state.company,
  onboardingExistingUser: (state) => state.existingUser,
  onboardingIata: (state) => state.iata,
  onboardingInvite: (state) => state.invite,
  onboardingInviteResponseKey: (state) => state.inviteResponseKey,
  onboardingInviteHostAgency: (state) => state.invite?._embedded?.host_agency || {},
  onboardingIsInviteAnswered: ({ invite }) => !!invite?.id && invite?.ic?.status !== 'pending',
  onboardingMode: (state) => state.mode,
  onboardingModes: (state) => state.modes,
  onboardingUser: (state) => state.user,
  onboardingIsPasswordResetRequired: (state) => state.invite?._embedded?.to_user?.require_password_reset || false,
  onboardingTotalSteps: (state) => state.totalSteps
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
