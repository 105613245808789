import CryptoJS from 'crypto-js';
import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import { createStore } from 'vuex-extensions'
import { camelCase } from '@/utils/helpers/methods';

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
  key: 'sion',
  reducer: (state) => ({
    app: {
      showFiltersRefresh: {
        contacts: state.app.showFiltersRefresh.contacts,
        commissions: state.app.showFiltersRefresh.commissions,
        bookings: state.app.showFiltersRefresh.bookings,
        statements: state.app.showFiltersRefresh.statements,
        client: state.app.showFiltersRefresh.client,
        clients: state.app.showFiltersRefresh.clients,
        supplier: state.app.showFiltersRefresh.supplier,
        suppliers: state.app.showFiltersRefresh.suppliers,
      }
    },
    bookings: {
      settings: { ... state.bookings.settings },
      pagination: { ...state.bookings.pagination }
    },
    commissions: {
      mode: state.commissions.mode
    },
    contacts: {
      mode: state.contacts.mode
    },
    invoiceFollowUps: {
      followUp: state.invoiceFollowUps.followUp,
      cachedFollowUp: state.invoiceFollowUps.cachedFollowUp,
      unalteredInvoice: state.invoiceFollowUps.unalteredInvoice,
      currencyConfirmed: state.invoiceFollowUps.currencyConfirmed,
    },
    onboarding: state.onboarding,
    statements: {
      tab: state.statements.tab,
      pagination: { ...state.statements.pagination }
    },
    tokens: state.tokens,
  }),
  restoreState: (key) => {
    const cipher = sessionStorage.getItem(key);
    if (!cipher) return null;

    const bytes = CryptoJS.AES.decrypt(cipher, process.env.VUE_APP_HASH);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  },
  saveState: (key, state) => {
    const cipher = CryptoJS.AES.encrypt(JSON.stringify(state), process.env.VUE_APP_HASH).toString();
    return sessionStorage.setItem(key, cipher);
  },
  storage: window.sessionStorage,
});

// module registration credit to: https://www.youtube.com/watch?v=7YZ5DwlLSt8&t=1901s
const requireModule = require.context('./modules/', false, /|.js$/);
const modules = {};

requireModule.keys().forEach(fileName => {
  const nameWithoutPath = fileName.replace(/(\.\/|\.js)/g, '')
  const moduleName = camelCase(nameWithoutPath);
  modules[moduleName] = requireModule(fileName).default;
});

export default createStore(Vuex.Store, {
  modules,
  plugins: [vuexPersist.plugin],
  strict: !Vue.prototype.$isProd,
});
