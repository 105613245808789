import Vue from 'vue';
import { dayjs } from '@/plugins/dayjs';
import { cloneDeep } from '@/utils/helpers/methods';

const defaultFilters = {
  amounts: [0, 0],
  batches: [],
  clients: [],
  companies: [],
  dates: {
    from: dayjs().startOf('year').toDate(),
    month: 1,
    range: 'year',
    to: dayjs().endOf('year').toDate(),
    quarter: 1,
    year: new Date().getFullYear(),
  },
  employees: [],
  hosts: [],
  iatas: [],
  invoices: ['supplier', 'client_net', 'client_fee'],
  fields: {
    date: 'date',
    query: [],
  },
  query: null,
  queryStrings: [],
  sort: {
    created_at: 'desc',
  },
  sources: [
    'batch',
    'invoice',
    'manual',
    'unmatched',
  ],
  statement: null,
  statuses: {
    batches: ['open', 'reconciled'],
    payments: ['reconciled', 'duplicate', 'reversed'],
    unmatched: ['unmatched', 'matched'],
  },
  suppliers: [],
  tags: [],
  types: ['onyx', 'ihg', 'tacs', 'paymode', 'wire', 'check', 'credit', 'other'],
  unmatchedPotential: ['all'],
};

const defaultReporting = {
  unmatched: true,
  view: 'agency',
};

const state = {
  filters: cloneDeep(defaultFilters),
  mode: 'payments',
  refresh: false,
  reporting: cloneDeep(defaultReporting),
};

const mutations = {
  RESET_COMMISSIONS(state, payload) {
    switch (payload) {
      case 'filters':
        state.filters = cloneDeep(defaultFilters);
        break;
      case 'reporting':
        state.reporting = cloneDeep(defaultReporting);
        break;
      default:
    }
  },
  SET_COMMISSIONS_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
  TOGGLE_COMMISSIONS_FILTER(state, payload) {
    const filter = state.filters[payload.key];
    const { val } = payload;
    const index =
      typeof val === 'string'
        ? filter.indexOf(val)
        : filter.findIndex((filterItem) => filterItem.id === val.id);
    if (index < 0) {
      filter.push(val);
    } else {
      filter.splice(index, 1);
    }
  },
  SET_COMMISSIONS_FILTERS(state, payload) {
    state.filters = payload;
  }
};

const getters = {
  commissionsFilters: (state) => state.filters,
  commissionsMode: (state) => state.mode,
  commissionsRefresh: (state) => state.refresh,
  commissionsReporting: (state) => state.reporting,
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
