export const filter = (
  companies = [],
  iatas = [],
  // ie: reportingDates = { field: '', startDate: '2024-01-01', endDate: '2024-12-31' }
  reportingDates = {}
) => {
  const { field, startDate, endDate } = reportingDates;
  const esFilter = {
    'bool': {
      'must': [
        {
          'terms': {
            '_embedded.production_reports.company_id': companies
          }
        },
        {
          'match': {
            '_embedded.production_reports.dates.field': field
          }
        },
        {
          'range': {
            '_embedded.production_reports.dates.start': {
              'gte': startDate
            }
          }
        },
        {
          'range': {
            '_embedded.production_reports.dates.end': {
              'lte': endDate
            }
          }
        },
      ]
    }
  }
  if (iatas.length >= 1) {
    esFilter.bool.must.push({
      'terms': {
        '_embedded.production_reports.iata_id': iatas
      }
    })
  }
  return esFilter;
}

export const typesAndSubTypes = (types) => {
  const typesShould = [];
  const typesWithoutSubtypes = [];
  types.forEach(type => {
    if (type.sub_types.length > 0) {
      typesShould.push({
        bool: {
          must: [
            { term: { type: type.key } },
            { terms: { sub_type: type.sub_types } }
          ]
        }
      })
    } else {
      typesWithoutSubtypes.push(type.key);
    }
  })
  if (typesWithoutSubtypes.length > 0) {
    typesShould.push({ terms: { type: typesWithoutSubtypes } });
  }
  return {
    bool: {
      should: typesShould,
    }
  };
}

export const productionAggs = (
  companies = [],
  iatas = [],
  // ie: reportingDates = { field: '', startDate: '2024-01-01', endDate: '2024-12-31' }
  reportingDates = {}
) => {
  const { startDate, endDate } = reportingDates;
  return {
    'filter': filter(companies, iatas, reportingDates),
    'aggs': {
      'total_revenue': {
        'sum': { 'field': '_embedded.production_reports.revenue' }
      },
      'total_commission': {
        'sum': { 'field': '_embedded.production_reports.commission' }
      },
      'total_booking_count': {
        'sum': { 'field': '_embedded.production_reports.booking_count' }
      },
      'by_quarter': {
        'date_histogram': {
          'field': '_embedded.production_reports.dates.start',
          'interval': 'quarter',
          'format': 'yyyy-MM-dd',
          'min_doc_count': 0,
          'extended_bounds': {
            'min': startDate,
            'max': endDate,
          }
        },
        'aggs': {
          'month_a': {
            'date_histogram': {
              'field': '_embedded.production_reports.months.a.dates.start',
              'interval': 'month',
              'format': 'yyyy-MM-dd',
              'min_doc_count': 0
            },
            'aggs': {
              'booking_count': {
                'sum': { 'field': '_embedded.production_reports.months.a.booking_count' }
              },
              'revenue': {
                'sum': { 'field': '_embedded.production_reports.months.a.revenue' }
              },
              'commission': {
                'sum': { 'field': '_embedded.production_reports.months.a.commission' }
              }
            }
          },
          'month_b': {
            'date_histogram': {
              'field': '_embedded.production_reports.months.b.dates.start',
              'interval': 'month',
              'format': 'yyyy-MM-dd',
              'min_doc_count': 0
            },
            'aggs': {
              'booking_count': {
                'sum': { 'field': '_embedded.production_reports.months.b.booking_count' }
              },
              'revenue': {
                'sum': { 'field': '_embedded.production_reports.months.b.revenue' }
              },
              'commission': {
                'sum': { 'field': '_embedded.production_reports.months.b.commission' }
              }
            }
          },
          'month_c': {
            'date_histogram': {
              'field': '_embedded.production_reports.months.c.dates.start',
              'interval': 'month',
              'format': 'yyyy-MM-dd',
              'min_doc_count': 0
            },
            'aggs': {
              'booking_count': {
                'sum': { 'field': '_embedded.production_reports.months.c.booking_count' }
              },
              'revenue': {
                'sum': { 'field': '_embedded.production_reports.months.c.revenue' }
              },
              'commission': {
                'sum': { 'field': '_embedded.production_reports.months.c.commission' }
              }
            }
          }
        }
      }
    }
  }
}

export const sortMethod = (
  sortedBy,
  companies = [],
  iatas = [],
  // ie: reportingDates = { field: '', startDate: '2024-01-01', endDate: '2024-12-31' }
  reportingDates = {},
) => {
  const sort = [];

  const sortKey = Object.keys(sortedBy)[0];
  const sortOrder = sortedBy[sortKey];
  const dynamicSortKeys = {
    'total_commission': 'commission',
    'total_revenue': 'revenue',
    'total_booking_count': 'booking_count',
  };
  if (Object.keys(dynamicSortKeys).includes(sortKey)) {
    const dynamicSortKey = `_embedded.production_reports.${dynamicSortKeys[sortKey]}`
    const dynamicSort = {}
    dynamicSort[dynamicSortKey] = {
      'mode': 'sum',
      'order': sortOrder,
      'nested': {
        'path': '_embedded.production_reports',
        'filter': filter(companies, iatas, reportingDates),
      }
    }
    sort.push(dynamicSort);
    sort.push({ ['name.last_first.raw']: { 'order': 'asc' } });
  } else {
    const field = sortKey.match(/name|last_first/i) ? 'name.last_first.raw' : sortKey;
    const sortObj = {};
    sortObj[field] = { order: sortOrder };
    sort.push(sortObj);
  }
  return sort;
};

export const productionReportingFields = (
  fieldName = '',
  companies = [],
  iatas = [],
  // ie: reportingDates = { field: '', startDate: '2024-01-01', endDate: '2024-12-31' }
  reportingDates = {}
) => {
  const { field, startDate, endDate } = reportingDates;
  return {
    script: {
      lang: 'painless',
      source: `
        double total = 0.0;
        def reports = params._source?._embedded?.production_reports ?: [];
        for (report in reports) {
          boolean companyMatch = params.company_ids.contains(report.company_id);
          boolean iataMatch = params.iata_ids.contains(report.iata_id);
          boolean fieldMatch = params.dates.field == report.dates.field;

          DateTimeFormatter formatter = DateTimeFormatter.ofPattern('yyyy-MM-dd');

          LocalDate startDate = LocalDate.parse(report.dates.start, formatter);
          LocalDate minDate = LocalDate.parse(params.dates.start, formatter);

          LocalDate endDate = LocalDate.parse(report.dates.end, formatter);
          LocalDate maxDate = LocalDate.parse(params.dates.end, formatter);

          boolean dateMatch = startDate.compareTo(minDate) >= 0 && endDate.compareTo(maxDate) <= 0;

          if (companyMatch && iataMatch && fieldMatch && dateMatch) {
            total += report.${fieldName};
          }
        }
        return total;
      `,
      params: {
        company_ids: companies,
        iata_ids: iatas,
        dates: {
          field: field,
          start: startDate,
          end: endDate,
        }
      }
    }
  }
};

export const typesAggs = {
  types: {
    terms: {
      field: 'type',
      size: 1000,
      order: {
        _key: 'asc'
      }
    },
    aggs: {
      sub_types: {
        terms: {
          field: 'sub_type',
          size: 1000,
          order: {
            _key: 'asc'
          }
        }
      }
    }
  }
}
