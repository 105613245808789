import { apiClient } from '../apiClient';

const DOMAIN = 'reward_program';

export default {
  getPrograms: (params) => apiClient.get(`/${DOMAIN}s/`, { params }),

  createProgramNumber: ({ id, ...payload }) => apiClient.post(`/clients/${id}/${DOMAIN}_numbers`, payload),
  getProgramNumber: ({ id, ...params }) => apiClient.get(`/${DOMAIN}_numbers/${id}`, { params }),
  getProgramNumbers: ({ id, ...params }) => apiClient.get(`/clients/${id}/${DOMAIN}_numbers`, { params }),
  updateProgramNumber: ({ id, ...payload }) => apiClient.patch(`/${DOMAIN}_numbers/${id}`, payload),
  deleteProgramNumber: ({ id, ...payload }) => apiClient.delete(`/${DOMAIN}_numbers/${id}`, payload),
};
