const state = {
  identities: {
    // integrationId: [
    //   {
    //     id: undefined
    //     value: '',
    //     fieldId: ''
    //   }
    // ]
  }
}

const mutations = {
  RESET_IDENTITY_FIELDS(state) {
    state.identities = {};
  },
  SET_IDENTITY_FIELD(state, val) {
    const { integrationId, ...payload } = val;
    if (!state.identities?.[integrationId]) {
      state.identities[integrationId] = [];
    }

    const index = state.identities?.[integrationId]?.findIndex(({ fieldId }) => fieldId === val.fieldId);
    if (index >= 0) {
      state.identities[integrationId][index] = payload;
    } else {
      state.identities[integrationId].push(payload);
    }
  }
}

const getters = {
  identitiesEditing: (state) => state.identities
}

const actions = {
}


export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
