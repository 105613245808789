import { apiClient } from '../apiClient';

const DOMAIN = 'subscriptions';

export default {
  get: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}`, { params }),
  update: ({ id, ...payload }) => apiClient.patch(`/${DOMAIN}/${id}`, payload),
  delete: ({ id, ...payload }) => apiClient.delete(`/${DOMAIN}/${id}`, payload),

  getInvoices: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}/invoices`, { params }),
  getStripeInvoices: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}/stripe_invoices`, { params }),
};
