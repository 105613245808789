import Vue from 'vue';
import accountSettingsTabs from '@/data/account-settings-tabs.json';

const state = {
  refresh: {
    iatas: false,
    employees: false,
    agencies: false,
    billing: false,
    sources: false,
  },
  tab: null,
  tabs: accountSettingsTabs.tabs
};

const mutations = {
  SET_ACCOUNT_SETTINGS_FIELD(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
};

const getters = {
  accountSettingsRefresh: ({ refresh }) => refresh,
  accountSettingsTab: ({ tab }) => tab, // not using?
  accountSettingsTabs: ({ tabs }) => tabs,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
