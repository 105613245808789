import Vue from 'vue';
import { cloneDeep } from '@/utils/helpers/methods';
import { HTTP } from '@/utils/http';
import dayjs from 'dayjs';

const defaultFollowUp = {
  bcc_addresses: [],
  cc_addresses: [],
  to_addresses: [],
  is_hosted: false,
  is_downloadable: true,
  is_editable: false,
  is_payable: false,
  is_private: false,
};

const defaultPaymentGatewayConfig = {
  max_days: 30,
};

const state = {
  followUp: cloneDeep(defaultFollowUp),
  followUps: [],
  cachedFollowUp: {},
  unalteredInvoice: {},
  invoicePayment: {},
  paymentGatewayConfig: cloneDeep(defaultPaymentGatewayConfig),
  settings: {
    editing: false,
    refresh: false,
    paymentSaving: false,
    gatewayPaymentSaving: false,
    paymentGatewayUIOpen: false,
  },
  currencyConfirmed: false,
};

const mutations = {
  ADD_INVOICE_FOLLOW_UP_EMAIL(state, payload) {
    state.followUp[payload.key].push(payload.val);
  },
  REMOVE_INVOICE_FOLLOW_UP_EMAIL(state, payload) {
    const index = state.followUp[payload.key].indexOf(payload.val);
    if (index >= 0) {
      state.followUp[payload.key].splice(index, 1);
    }
  },
  RESET_FOLLOW_UP(state) {
    state.followUp = cloneDeep(defaultFollowUp);
  },
  SET_INVOICE_FOLLOW_UP_BOOKINGS_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state.followUp.invoice.bookings[payload.index];
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
  SET_INVOICE_FOLLOW_UPS_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
};

const getters = {
  getAmountDueFromBookings: (state, getters) => (bookings) => bookings.reduce((sumTotal, currentBooking) => {
    const status = currentBooking.dunning_status;
    if (!status || status === 'due') {
      sumTotal += currentBooking[getters.invoiceAmountType];
    }
    return Math.round(sumTotal * 100) / 100;
  }, 0),
  isQuickEditMode: (state) => state.settings.editing,
  isFollowUpStatementAltered: (state, getters) => {
    if (!getters.followUp.is_editable) return false;
    if (getters.isFollowUpExpired) return false;
    else return (getters.invoiceAmountDue !== getters.cachedAmountDue) || getters.isCurrencyChanged;
  },

//  Follow Up  //

  followUp: (state) => state.followUp,
  followUpInvoice: ({ followUp }) => followUp?.invoice || {},
  invoiceAmountType: (state, getters) => {
    switch (getters.followUpInvoice.amount_type) {
      case 'commission_estimate':
        return 'commission_est';
      case 'revenue':
        return 'revenue';
      case 'commission_balance':
      default:
        return 'commission_balance';
    }
  },
  invoiceAmountDue: (_state, getters) => {
    const { payment } = getters.followUp;
    if (payment) {
      return payment.amount;
    }
    return getters.getAmountDueFromBookings(getters.invoiceBookings);
  },
  invoiceBookings: (_state, getters) => {
    if (Object.keys(getters.followUpInvoice).length > 0) {
      return getters.followUpInvoice.bookings;
    } return [];
  },
  invoiceDownload: (state, getters) => getters.followUpInvoice?.download ||  {},
  invoiceSenderLogo: (_state, getters) => {
    const { small } = getters.followUpInvoice?.sender?.logo?.versions;
    return small || null;
  },
  isFollowUpConfirmed: (state) => !!state.followUp.confirmed_at,

//  Cached User State  //

  cachedAmountDue: (_state, getters) => getters.getAmountDueFromBookings(getters.cachedBookings),
  cachedBookings: (_state, getters) => getters.cachedInvoice.bookings || [],
  cachedCurrency: (_state, getters) => getters?.cachedInvoice?.currency || {},
  cachedFollowUp: (state) => state.cachedFollowUp,
  cachedInvoice: ({ cachedFollowUp }) => cachedFollowUp?.invoice || {},

//  Currency  //

  invoiceCurrency: (_state, getters, rootState, rootGetters) => {
    if (!getters.followUpInvoice?.id) return {};
    const { symbol } = getters.followUpInvoice.currency;
    const currencies = rootGetters['currencies/currencies'];
    if (currencies.length) {
      return currencies.find((currency) => currency.symbol === symbol);
    } return {};
  },
  unalteredInvoice: ({ unalteredInvoice }) => {
    if (Object.keys(unalteredInvoice).length > 0) {
      return unalteredInvoice;
    } return null;
  },
  isCurrencyMatchingRecipientCountry: (_state, getters) => {
    const { recipient, currency } = getters.unalteredInvoice;
    if (!recipient) return true;
    return currency.country_codes.includes(recipient.address_components?.country);
  },
  isCurrencyChanged: (_state, getters) => {
    const originalCurrency = getters.unalteredInvoice?.currency?.symbol;
    const cachedCurrency = getters.cachedCurrency?.symbol;
    return originalCurrency !== cachedCurrency;
  },
  isCurrencyConfirmed: (state) => state.currencyConfirmed,

//  Expiration  //

  followUpExpirationDate: (_state, getters) => {
    const maxDays = getters.paymentGatewayConfig.max_days;
    let createdAt = getters.followUp.created_at;
    if (maxDays && createdAt) {
      createdAt = dayjs(createdAt);
      return createdAt.add(maxDays, 'days');
    }
    return null;
  },
  isFollowUpExpired: (_state, getters) => {
    const maxDays = getters.paymentGatewayConfig.max_days;
    let createdAt = getters.followUp.created_at;
    if (maxDays && createdAt) {
      createdAt = dayjs(createdAt);
      const today = dayjs(new Date());
      const diff = today.diff(createdAt, 'days');
      return diff >= maxDays;
    }
    return null;
  },

//  Payments  //

  isPayingOffline: ({ followUp }) => {
    const { payment } = followUp;
    if (!payment) return false;
    return payment.source === 'offline';
  },
  isPaymentSaving: (state) => state.settings.paymentSaving,
  paymentGateway: (state) => (state.paymentGatewayConfig ? state.paymentGatewayConfig.gateway : {}),
  paymentGatewayConfig: (state) => state.paymentGatewayConfig,
  isPaymentGatewayUIOpen: (state) => state.settings.paymentGatewayUIOpen,
  gatewayPayment: ({ followUp }) => followUp?.payment?.gateway_payment || {},
  gatewayPaymentStatus: (_state, getters) => getters.gatewayPayment.status,
  isGatewayPaymentInitiated: (_state, getters) => getters.gatewayPaymentStatus === 'initiated',
  isGatewayPaymentCanceled: (_state, getters) => getters.gatewayPaymentStatus === 'cancelled',
  isGatewayPaymentFailed: (_state, getters) => getters.gatewayPaymentStatus === 'failed',
  isGatewayPaymentComplete: (_state, getters) => ['processed', 'guaranteed', 'delivered'].includes(getters.gatewayPaymentStatus),
  isGatewayPaymentSaving: (state) => state.settings.gatewayPaymentSaving,
};

const actions = {
  async doGetInvoiceFollowUps({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.get(`/invoices/${id}/follow_ups`, { params });
    if (!ignore) {
      const [val] = data.data.follow_ups;
      commit('SET_INVOICE_FOLLOW_UPS_ATOMIC', { key: 'followUps', val });
    }
    return data;
  },
  async doCreateInvoiceFollowUp({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.post(`/invoices/${id}/follow_ups`, params);
    if (!ignore) {
      const [val] = data.data.follow_ups;
      commit('SET_INVOICE_FOLLOW_UPS_ATOMIC', { key: 'followUp', val });
    }
    return data;
  },
  async doUpdateInvoiceFollowUp({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.patch(`/invoice_follow_ups/${id}`, params);
    const [val] = data.data.invoice_follow_ups;
    if (!ignore) {
      commit('SET_INVOICE_FOLLOW_UPS_ATOMIC', { key: 'followUp', val });
    }
    return data;
  },
  async doGetInvoiceFollowUp({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.get(`/invoice_follow_ups/${id}`, { params });
    const [val] = data.data.invoice_follow_ups;
    if (!ignore) {
      commit('SET_INVOICE_FOLLOW_UPS_ATOMIC', { key: 'followUp', val });
    }
    return data;
  },
  async doCreateInvoiceFollowUpPayment({ commit }, { id, ...params }) {
    commit('SET_INVOICE_FOLLOW_UPS_ATOMIC', { key: 'settings.paymentSaving', val: true });
    const { data } = await HTTP.post(`/invoice_follow_ups/${id}/payment`, params);
    const [val] = data.data.payments;
    commit('SET_INVOICE_FOLLOW_UPS_ATOMIC', { key: 'invoicePayment', val });
    commit('SET_INVOICE_FOLLOW_UPS_ATOMIC', { key: 'settings.paymentSaving', val: false });
    return data;
  },
  async doUpdateInvoiceFollowUpPayment({ commit }, payload) {
    commit('SET_INVOICE_FOLLOW_UPS_ATOMIC', { key: 'settings.paymentSaving', val: true });
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.patch(`/invoice_payments/${id}`, params);
    if (!ignore) {
      const [val] = data.data.invoice_payments;
      commit('SET_INVOICE_FOLLOW_UPS_ATOMIC', {
        key: 'invoicePayment',
        val,
      });
    }
    commit('SET_INVOICE_FOLLOW_UPS_ATOMIC', { key: 'settings.paymentSaving', val: false });
    return data;
  },
  async doGetFollowUpPaymentGatewayConfig({ commit }, params) {
    const { id } = params;
    const { data } = await HTTP.get(`/invoice_follow_ups/${id}/gateway_config`, { params });
    const [gatewayConfig] = data.data.gateway_config;
    commit('SET_INVOICE_FOLLOW_UPS_ATOMIC', { key: 'paymentGatewayConfig', val: gatewayConfig });
    return gatewayConfig;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
