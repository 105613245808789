import Vue from 'vue';
import { HTTP } from '@/utils/http';

const state = {
  destination: {},
  destinations: [],
  refresh: false,
};

const mutations = {
  REMOVE_RELATED_DESTINATION(state, payload) {
    Vue.set(state.destination.related, payload.key, null);
  },
  SET_DESTINATIONS_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
};

const getters = {
  destination: (state) => state.destination,
  destinationRefresh: (state) => state.refresh,
  destinations: (state) => state.destinations,
};

const actions = {
  async doGetDestination({ commit }, payload) {
    const { id, ...params } = payload;
    const { data } = await HTTP.get(`/destinations/${id}`, { params });
    const [val] = data.data.destinations;
    commit('SET_DESTINATIONS_ATOMIC', { key: 'destination', val });
    return data;
  },
  async doGetDestinations({ commit }, payload) {
    // used?
    const { ...params } = payload;
    const { data } = await HTTP.get('/destinations', { params });
    const { destinations } = data.data;
    commit('SET_DESTINATIONS_ATOMIC', { key: 'destinations', val: destinations });
    return data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
