import { apiClient } from '../apiClient';

const DOMAIN = 'iatas';

export default {
  create: ({ id, ...payload }) => apiClient.post(`/companies/${id}/${DOMAIN}`, payload),
  get: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}`, { params }),
  update: ({ id, ...payload }) => apiClient.patch(`/${DOMAIN}/${id}`, payload),
  delete: ({ id, ...payload }) => apiClient.delete(`/${DOMAIN}/${id}`, payload),

  createPlan: ({ id, ...payload }) => apiClient.post(`/companies/${id}/iata_plans`, payload),
  getPlan: ({ id, ...params }) => apiClient.get(`/iata_plans/${id}`, { params }),
  getPlans: ({ id, ...params }) => apiClient.get(`/companies/${id}/iata_plans`, { params }),
  updatePlan: ({ id, ...params }) => apiClient.get(`/iata_plans/${id}`, { params }),

  createBillingProfile: ({ id, ...payload }) => apiClient.post(`/${DOMAIN}/${id}/billing_profiles`, payload),

  getPublicNumbers: (params) => apiClient.get('/iata_numbers_public', { params }),
};
