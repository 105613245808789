import { HTTP } from '@/utils/http';

const state = {
  identity: {},
};

const getters = {
  sourceIdentity: (source) => source.identity, // used?
};

const mutations = {
  SET_SOURCE_IDENTITY(state, payload) {
    state.identity = payload;
  },
  SET_SOURCE_IDENTITY_FIELD() {},
};

const actions = {
  async doCreateSourceIdentity({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.post(`/employees/${id}/source_identities`, params);
    if (!ignore) commit('SET_SOURCE_IDENTITY', data.data.source_identities[0]);
    return data;
  },
  async doGetSourceIdentity({ commit }, payload) {
    // used?
    const { id, ...params } = payload;
    const { data } = await HTTP.get(`/source_identities/${id}`, { params });
    commit('SET_SOURCE_IDENTITY', data.data.source_identities[0]);
    return data;
  },
  async doDeleteSourceIdentity({ commit }, payload) {
    // used?
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.delete(`/source_identities/${id}`, params);
    if (!ignore) commit('SET_SOURCE_IDENTITY', data.data.source_identities[0]);
    return data;
  },
  async doUpdateSourceIdentity({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.patch(`/source_identities/${id}`, params);
    if (!ignore) commit('SET_SOURCE_IDENTITY', data.data.source_identities[0]);
    return data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
