<template>
  <div id="terms-modal">
    <div class="modal-card">
      <header class="modal-card-head">
        <SionFullLogo />
        <a
          v-if="isOnboarding"
          class="has-text-grey-light"
          @click.stop="cancel"
        >
          <b-icon icon="x-circle" :pack="$defaultIconPack" />
        </a>
      </header>
      <b-notification v-if="!isOnboarding" :closable="false">Our terms of service have changed. Please agree to the new terms to continue using SION.</b-notification>
      <section class="modal-card-body" @scroll.passive="scrollHandler">
        <Terms />
      </section>
      <footer class="modal-card-foot">
        <p v-if="!settings.submit">
          <span>Scroll down to continue</span>
          <b-icon icon="arrow-down" :pack="$defaultIconPack" />
        </p>
        <b-button
          tag="a"
          type="is-primary"
          data-cy="terms-submit"
          :disabled="!settings.submit"
          :loading="settings.submitting"
          @click="submit"
        >
          I Agree to the Terms of Service
        </b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import { reportError } from '@/utils/helpers/tracking/tracking-methods';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import Terms from '@/components/onboarding/Terms.vue';
import SionFullLogo from '@/components/_base/atoms/SionFullLogo.vue';

export default {
  name: 'TermsModal',
  components: {
    Terms,
    SionFullLogo
  },
  props: {
    mode: {
      type: String,
      default: 'onboarding',
    },
  },
  data() {
    return {
      settings: {
        submit: false,
        submitting: false,
      },
    };
  },
  computed: {
    ...mapGetters('users', ['user']),
    isOnboarding() {
      return this.mode === 'onboarding';
    },
  },
  methods: {
    ...mapMutations('app', ['SET_APP_SNACKBAR']),
    ...mapMutations('onboarding', ['SET_ONBOARDING_FIELD']),
    ...mapMutations('users', ['SET_USERS_ATOMIC']),
    ...mapActions('users', ['doUpdateUser']),
    cancel() {
      this.SET_ONBOARDING_FIELD({ key: 'user.agreed_to_terms', val: false });
      this.$parent.close();
    },
    scrollHandler(event) {
      if (!this.settings.submit) {
        const el = event.target;
        const height = el.offsetHeight;
        const max = el.scrollHeight;
        const pos = el.scrollTop;
        const current = pos + height;
        this.settings.submit = current + 160 >= max;
      }
    },
    async submit() {
      if (this.isOnboarding) {
        this.SET_ONBOARDING_FIELD({ key: 'user.agreed_to_terms', val: true });
        this.$parent.close();
      } else {
        this.settings.submitting = true;
        const payload = {
          id: this.user.id,
          agreed_to_terms: true,
        };
        try {
          await this.doUpdateUser(payload);
          this.SET_USERS_ATOMIC({ key: 'self.agreed_to_terms', val: true });
          this.$parent.close();
          this.SET_APP_SNACKBAR({
            type: 'is-success',
            message: 'Thank you for agreeing to our terms of service',
          });
        } catch (error) {
          reportError(error);
          this.SET_APP_SNACKBAR({
            type: 'is-danger',
            message: 'Error submiting terms of service.',
          });
        } finally {
          this.settings.submitting = false;
        }
      }
    },
  },
};
</script>

<style lang="scss">
#terms-modal {
  .modal-card {
    max-width: none;
    width: 100%;
    .modal-card-head {
      position: relative;
      a {
        position: absolute;
        right: pxToRem(20);
        top: pxToRem(20);
      }
    }
    .notification {
      margin-bottom: 0;
      border-radius: 0;
      & + .modal-card-body {
        padding-top: pxToRem(20);
      }
    }
    .modal-card-body {
      padding-top: 0;
      overflow: auto !important;
    }
    .modal-card-foot {
      p {
        display: flex;
        align-items: center;
        .icon {
          margin: 0 pxToRem(12) 0 pxToRem(5);
        }
      }
    }
  }
}
</style>
