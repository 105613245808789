import { apiClient } from '../apiClient';

const DOMAIN = 'important_dates';

export default {
  create: ({ id, ...payload }) => apiClient.post(`/clients/${id}/${DOMAIN}`, payload),
  get: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}`, { params }),
  update: ({ id, ...payload }) => apiClient.patch(`/${DOMAIN}/${id}`, payload),
  delete: ({ id, ...payload }) => apiClient.delete(`/${DOMAIN}/${id}`, payload),
  getAll: ({ id, ...params }) => apiClient.get(`/clients/${id}/${DOMAIN}`, { params }),
}; 
