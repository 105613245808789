import { apiClient } from './apiClient';

// Dynamically import all APIs from the 'services' folder
const requireModule = require.context('./services', false, /\.js$/);
const apiServices = {};

requireModule.keys().forEach(fileName => {
  const serviceName = fileName.replace(/(\.\/|\.js)/g, '');
  apiServices[serviceName] = requireModule(fileName).default;
});

export const setAuthToken = apiClient.setAuthToken;
export const clearAuthToken = apiClient.clearAuthToken;

export default {
  install(Vue) {
    Vue.prototype.$api = apiServices;
  }
};
