import { apiClient } from '../apiClient';

const DOMAIN = 'integrations';

export default {
  create: (payload) => apiClient.post(`/${DOMAIN}`, payload),
  delete: ({ id, ...payload }) => apiClient.delete(`/${DOMAIN}/${id}`, payload),
  update: ({ id, ...payload }) => apiClient.patch(`/${DOMAIN}/${id}`, payload),
  get: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}`, { params }),
  getAll: ({ id, ...params }) => apiClient.get(`/companies/${id}/${DOMAIN}`, { params }),


  getServices: (params) => apiClient.get('/integration_services', { params }),

  createIdentity: (payload) => apiClient.post('/integration_identities', payload),
  getIdentity: ({ id }) => apiClient.get(`/integration_identities/${id}`),
  updateIdentity: ({ id, ...payload }) => apiClient.patch(`/integration_identities/${id}`, payload),
  deleteIdentity: ({ id, ...payload }) => apiClient.delete(`/integration_identities/${id}`, payload),

  createIdentityFields: (payload) => apiClient.post('/integration_identity_fields', payload),
  updateIdentityFields: ({ id, ...payload }) => apiClient.patch(`/integration_identity_fields/${id}`, payload),
  deleteIdentityFields: ({ id, ...payload }) => apiClient.delete(`/integration_identity_fields/${id}`, payload),

  createBackOfficeBranch: (payload) => apiClient.post('/back_office_branches', payload),
  updateBackOfficeBranch: ({ id, ...payload }) => apiClient.patch(`/back_office_branches/${id}`, payload),
  deleteBackOfficeBranch: ({ id, ...payload }) => apiClient.delete(`/back_office_branches/${id}`, payload),

  createConsortiaMemberNumber: (payload) => apiClient.post('/consortia_member_numbers', payload),
  deleteConsortiaMemberNumber: ({ id, ...payload }) => apiClient.delete(`/consortia_member_numbers/${id}`, payload),
  updateConsortiaMemberNumber: ({ id, ...payload }) => apiClient.patch(`/consortia_member_numbers/${id}`, payload),
};
