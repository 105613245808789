import { cloneDeep } from '@/utils/helpers/methods';

const defaultCustomer = {
  _embedded: {
    stripe_customer: {},
  },
};

const state = {
  customer: cloneDeep(defaultCustomer),
};

const mutations = {
  SET_CUSTOMER(state, payload) {
    state.customer = payload;
  },
};

const getters = {
  customer: (state) => state.customer,
};

const actions = {
  async doCreateCustomer({ commit }, payload) {
    const { data } = await this._vm.$api.customers.create(payload);
    const [customer] = data.customers;
    if (customer) commit('SET_CUSTOMER', customer);
  },
  async doUpdateCustomer({ commit }, payload) {
    const { data } = await this._vm.$api.customers.update(payload);
    const [customer] = data.customers;
    if (customer) commit('SET_CUSTOMER', customer);
  },
  async doGetCustomer({ commit }, payload) {
    const { data } = await this._vm.$api.customers.get(payload);
    const [customer] = data.customers;
    if (customer) commit('SET_CUSTOMER', customer);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
