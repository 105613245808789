const state = {
  attachment: {},
  attachments: [],
  uploadProgress: 0,
  refresh: false,
};

const mutations = {
  SET_ATTACHMENT(state, attachment) {
    state.attachment = attachment;
  },
  SET_ATTACHMENTS(state, attachments) {
    state.attachments = attachments;
  },
  SET_ATTACHMENT_FIELD(state, field) {
    const nodes = field.key.split('.');
    let obj = state.attachment;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        obj[node] = field.val;
      } else {
        obj = obj[node];
      }
    });
  },
  SET_UPLOAD_PROGRESS(state, value) {
    state.uploadProgress = value;
  },
  SET_ATTACHMENTS_REFRESH(state, refresh) {
    state.refresh = refresh;
  },
};

const getters = {
  attachment: (state) => state.attachment,
  attachmentUploadProgress: (state) => state.uploadProgress,
  attachments: (state) => state.attachments,
  attachmentsRefresh: (state) => state.refresh,
};

const actions = {
  async doCreateAttachment( { commit }, payload) {
    const onUploadProgress = ({ loaded, total }) => {
      const uploadProgress = Math.round((loaded * 100) / total);
      commit('SET_UPLOAD_PROGRESS', uploadProgress);
    };
    try {
      await this._vm.$api.attachments.create(payload, onUploadProgress);
      setTimeout(() => {
        commit('SET_UPLOAD_PROGRESS', 0)
      }, 5000);
    } catch (error) {
      commit('SET_UPLOAD_PROGRESS', 0);
      throw error;
    }
  },
  async doGetAttachments({ commit }, { resource, ...payload }) {
    const options = {
      booking: 'bookings',
      client: 'clients',
      supplier: 'suppliers',
      supplier_group: 'supplier_groups',
      payment: 'payments',
      unmatched_payment: 'paymentsUnmatched',
      trip: 'trips'
    };
    const domain = options[resource.toLowerCase()];

    const { data } = await this._vm.$api[domain].getAttachments(payload);
    commit('SET_ATTACHMENTS', data.attachments)
  },
  async doDeleteAttachment({ commit }, payload) {
    await this._vm.$api.attachments.delete(payload);
    commit('SET_ATTACHMENT', {});
  },
  async doUpdateAttachment({ commit }, payload) {
    const { data } = await this._vm.$api.attachments.update(payload);
    commit('SET_ATTACHMENT', data.attachments[0]);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
