import { cloneDeep } from '@/utils/helpers/methods';
import { HTTP, SEARCH } from '@/utils/http';
import { setAuthToken, clearAuthToken } from '@/api/apiClient';

const state = {
  token: null,
};

const mutations = {
  SET_TOKEN(state, token) {
    const httpHeaders = cloneDeep(HTTP.defaults.headers);
    httpHeaders.common.Authorization = token;
    HTTP.defaults.headers = httpHeaders;

    const searchHeaders = cloneDeep(SEARCH.defaults.headers);
    searchHeaders.common.Authorization = token;
    SEARCH.defaults.headers = searchHeaders;

    setAuthToken(token);

    state.token = token;
  },
  UNSET_TOKEN(state) {
    const httpHeaders = cloneDeep(HTTP.defaults.headers);
    delete httpHeaders.common.Authorization;
    HTTP.defaults.headers = httpHeaders;

    const searchHeaders = cloneDeep(SEARCH.defaults.headers);
    delete searchHeaders.common.Authorization;
    HTTP.defaults.headers = searchHeaders;

    clearAuthToken();

    state.token = null;
  },
};

const getters = {
  token: (state) => state.token,
};

const actions = {
  async doCreateToken({ commit }, payload) {
    const { data } = await HTTP.post('/tokens', payload);
    commit('SET_TOKEN', data.token);
    return data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
