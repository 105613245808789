import { dayjs } from '@/plugins/dayjs';
import Vue from 'vue';
import { dateFormatYMD } from '@/utils/helpers/dates';


export const formatBooking = (booking) => {
  const dateFields = ['created_at', 'payment_due'];
  dateFields.forEach((field) => {
    const val = booking[field];
    if (val) {
      booking[field] = dayjs(val).toDate();
    }
  });
  return booking;
};

export const getCommissionEstimate = (opts = {}) => {
  const { booking, currency, currencies } = opts;
  const bookingCurrencySymbol = booking.revenue_currency.symbol;
  let commission = booking.commission_est;
  if (bookingCurrencySymbol === currency) {
    return commission;
  }
  const bookingCurrency = currencies.find(
    (currencyItem) => currencyItem.symbol === bookingCurrencySymbol
  );
  if (!bookingCurrency) return 0;
  if (bookingCurrencySymbol !== currency) {
    const exchangeRate = bookingCurrency._embedded.exchange_rates.find(
      (exchangeRateItem) => exchangeRateItem.symbol === currency
    );
    commission *= exchangeRate.rate;
  }
  return commission;
};

export const getCommissionBalance = (opts) => {
  const { booking, company, currency, currencies } = opts;
  let paid = booking.total_paid;
  const { symbol } = company.currency;
  if (symbol !== currency) {
    const companyCurrency = currencies.find((currencyItem) => currencyItem.symbol === symbol);
    if (companyCurrency) {
      const exchangeRate = companyCurrency._embedded.exchange_rates.find(
        (exchangeRateItem) => exchangeRateItem.symbol === currency
      );
      if (exchangeRate) {
        paid *= exchangeRate.rate;
      }
    }
  }
  const difference = getCommissionEstimate(opts) - paid;
  if (difference < 0) {
    return 0;
  }
  return difference;
};

export const getRevenue = (opts) => {
  const { booking, currency, currencies } = opts;
  let { revenue } = booking;
  const { symbol } = booking.revenue_currency;
  if (symbol !== currency) {
    const bookingCurrency = currencies.find((currencyItem) => currencyItem.symbol === symbol);
    if (bookingCurrency) {
      const exchangeRate = bookingCurrency._embedded.exchange_rates.find(
        (exchangeRateItem) => exchangeRateItem.symbol === currency
      );
      if (exchangeRate) {
        revenue *= exchangeRate.rate;
      }
    }
  }
  return revenue;
};

export const checkCommissionSplits = async (id) => {
  const splits = { agent: 0, contractor: 0 };
  const bookingsResponse = await Vue.prototype.$api.bookings.get({ id });
  const { bookings } = bookingsResponse.data;
  if (bookings) {
    const [booking] = bookings;
    splits.contractor = booking.commission_split;
    splits.agent = booking.agent_commission_rate;
  }
  return splits;
};

export const roundCommissionSplit = (val) => {
  const twoDecimals = parseFloat(val).toFixed(2);
  const zeroPattern = /\.?0+$/;
  return twoDecimals.replace(zeroPattern, '');
};

export const currencyConversionMetric = (reporting, company, rates) => {
  const targetCurrency = company.currency.symbol || 'USD';
  const mapScript =
    reporting.view === 'commission'
      ? commissionMapScript(reporting, targetCurrency)
      : revenueMapScript(reporting, targetCurrency);
  return {
    scripted_metric: {
      params: {
        company: {
          id: company.id,
        },
        target_currency: targetCurrency,
        rates,
      },
      init_script: 'state.bookings = []',
      map_script: mapScript,
      combine_script: 'double sum = 0; for (t in state.bookings) { sum += t } return sum',
      reduce_script: 'double sum = 0; for (a in states) { sum += (a ?: 0) } return sum',
    },
  };
};

const commissionMapScript = (reporting, targetCurrency) => {
  const { gross, viewAs } = reporting;
  const contractorCommissionRateScript = `if (agency != companyId) {
    boolean isContractor = agency != params.company.id;
    double agencySplit = doc.commission_split.value;
    agencyCommissionRate = isContractor ? 100.0 - agencySplit : agencySplit;
  }`;
  const agentCommissionRateScript = `if (isAgentCompany) {
    double agentSplit = isAgentCommissionable ? doc.agent_commission_rate.value : 0;
    agentCommissionRate = ${viewAs === 'agent' ? 'agentSplit' : '100 - agentSplit'};
  }`;
  const agencyGrossScript = `double liveDifference = liveSupplierNet - liveContractorNet;
  liveAgentNet = liveAgentNet - liveDifference;
  double finalDifference = finalSupplierNet - finalContractorNet;
  finalAgentNet = finalAgentNet - finalDifference`;
  const script = `def sym = doc['revenue_currency.symbol'].value;
  def agency = doc['_embedded.iata._embedded.company.id'].value;
  def companyId = doc['_embedded.company.id'].value;
  boolean isAgentCompany = companyId == params.company.id;
  boolean isAgentCommissionable = doc.is_agent_commissionable.value;
  double agencyCommissionRate = 100.0;
  ${gross ? '' : contractorCommissionRateScript}
  double agentCommissionRate = 100.0;
  ${gross ? '' : agentCommissionRateScript}
  double supplierCost = doc.supplier_cost.value;
  double finalSupplierNet = doc.total_paid.value - supplierCost;
  double liveSupplierNet = doc.commission_est.value;
  double finalContractorNet = finalSupplierNet * (agencyCommissionRate / 100.0);
  double liveContractorNet = liveSupplierNet * (agencyCommissionRate / 100.0);
  double finalAgentNet = finalContractorNet * (agentCommissionRate / 100.0);
  double liveAgentNet = liveContractorNet * (agentCommissionRate / 100.0);
  def agentCommissionBasis = doc['_embedded.agent.commission_split_basis'].size() > 0 ? doc['_embedded.agent.commission_split_basis'].value : 'net';
  if (isAgentCompany && isAgentCommissionable) {
    if (agentCommissionBasis == 'gross') {
      finalAgentNet = finalSupplierNet * (agentCommissionRate / 100.0);
      liveAgentNet = liveSupplierNet * (agentCommissionRate / 100.0);
      ${viewAs === 'agency' ? agencyGrossScript : ''}
    } else if (agentCommissionBasis == 'net') {
      finalAgentNet = finalContractorNet * (agentCommissionRate / 100.0);
      liveAgentNet = liveContractorNet * (agentCommissionRate / 100.0);
    }
  }
  double liveExRate = params.rates[sym][params.target_currency];
  double finalExRate = ${finalFxScript('commission', targetCurrency)};
  state.bookings.add(finalAgentNet > 0 ? finalAgentNet * finalExRate : liveAgentNet * liveExRate)`;
  return script;
};

export const finalFxScript = (type, currency) => {
  const currencyKey = `'final_exchange_rates.${type}.${currency}'`;
  // return `doc[${currencyKey}].size() > 0 ? doc[${currencyKey}].value : 1.0`;
  return `doc.containsKey(${currencyKey}) && doc[${currencyKey}].size() > 0 ? doc[${currencyKey}].value : 1.0`;
};

const revenueMapScript = (reporting, targetCurrency) => {
  const { gross } = reporting;
  const script = `def sym = doc["revenue_currency.symbol"].value;\n
  boolean isClientNet = doc.invoice_type.value == "client_net";
  double supplierCost = doc["supplier_cost"].value;\n
  double finalGross = doc.revenue_converted.value;\n
  double finalNet = ${gross ? 'finalGross' : 'isClientNet ? supplierCost : finalGross'};
  double liveGross = doc.revenue.value;\n
  double liveNet = ${gross ? 'liveGross' : 'isClientNet ? supplierCost : liveGross'};
  double liveExRate = params.rates[sym][params.target_currency];\n
  double finalExRate = ${finalFxScript('commission', targetCurrency)};\n
  state.bookings.add(finalNet > 0 ? finalNet * finalExRate : liveNet * liveExRate)`;
  return script;
};

export const getBookingPaymentStatus = (booking) => {
  const today = dayjs();
  const checkOut = dayjs(booking.check_out, dateFormatYMD);
  const paymentDue = dayjs(booking.payment_due, dateFormatYMD);
  if (booking.is_canceled) {
    return 'canceled';
  }
  if (booking.total_paid > 0 || (!booking.is_commissionable && today.isAfter(paymentDue, 'day'))) {
    return 'paid';
  }
  if (booking.total_paid <= 0 && today.isAfter(paymentDue, 'day')) {
    return 'past_due';
  }
  if (booking.total_paid <= 0 && today.isAfter(checkOut, 'day')) {
    return 'owed';
  }
  return 'upcoming';
};

export const bookingSearchQuery = ({
 batch,
 company,
 payable,
 query,
}) => {
  const payload = {
    query: {
      bool: {
        must: [
          {
            bool: {
              should: [
                { match: { '_embedded.iata._embedded.company.id': company.id } },
                { match: { '_embedded.company.id': company.id } },
              ],
            },
          },
          {
            multi_match: {
              query,
              fields: [
                '_embedded.client.full_name',
                '_embedded.supplier.name',
                'confirmation_number',
                'number.case_insensitive',
              ],
              type: 'cross_fields',
              operator: 'and',
            },
          },
        ],
      },
    },
  };
  if (batch) {
    const ids = batch.supplier_ids;
    if (ids && ids.length > 0) {
      const supplierQuery = { terms: { '_embedded.supplier.id': ids } };
      payload.query.bool.must.push(supplierQuery);
    }
  }
  if (payable) {
    const iatasPayable = company._embedded.iata_plans.filter(
      (iataPlan) => iataPlan.is_payable === true
    );
    const iataIds = iatasPayable.map(({ iata }) => iata.id);
    payload.query.bool.must.push({ terms: { '_embedded.iata.id': iataIds } });
    // const managedHosts = _.filter(company._embedded.host_agencies, (agency) => agency.is_managed);
    // const managedHostIds = managedHosts.length === 0 ? [] : _.map(managedHosts, 'id');
    // const agencyIds = [company.id, ...managedHostIds];
    // payload.query.bool.must.push({ terms: { '_embedded.iata._embedded.company.id': agencyIds } });
  }
  return payload;
};

const canceledQuery = {
  bool: {
    must: [{ match: { is_canceled: true } }],
  },
};

const owedQuery = {
  bool: {
    must: [
      { match: { is_canceled: false } },
      { range: { total_paid: { lte: 0 } } },
      { range: { check_out: { lt: 'now/d' } } },
      { range: { payment_due: { gte: 'now/d' } } },
    ],
  },
};

const paidQuery = {
  bool: {
    must: [
      { match: { is_canceled: false } },
      {
        bool: {
          should: [
            { range: { total_paid: { gt: 0 } } },
            {
              bool: {
                must: [
                  { match: { is_commissionable: false } },
                  { range: { payment_due: { lt: 'now/d' } } },
                ],
              },
            },
          ],
        },
      },
    ],
  },
};

const pastDueQuery = {
  bool: {
    must: [
      { match: { is_canceled: false } },
      { match: { is_commissionable: true } },
      { range: { total_paid: { lte: 0 } } },
      { range: { payment_due: { lt: 'now/d' } } },
    ],
  },
};

const upcomingQuery = {
  bool: {
    must: [
      { match: { is_canceled: false } },
      { range: { total_paid: { lte: 0 } } },
      { range: { check_out: { gte: 'now/d' } } },
      { range: { payment_due: { gte: 'now/d' } } },
    ],
  },
};

const tempActions = {
  bool: {
    must: [
      { range: { temp_actions_count: { gt: 0 } } },
    ],
  },
};

export const queries = {
  canceled: canceledQuery,
  owed: owedQuery,
  paid: paidQuery,
  pastDue: pastDueQuery,
  upcoming: upcomingQuery,
  tempActions,
};
