import Vue from 'vue';

const state = {
  comments: [],
  notify: false,
  read: {},
  pagination: {
    page: 0,
    page_count: 0,
    per_page: 26,
  },
};

const mutations = {
  ADD_COMMENT_TO_COMMENTS(state, payload) {
    state.comments.unshift(payload);
  },
  APPEND_COMMENTS(state, payload) {
    state.comments = state.comments.concat(payload);
  },
  SET_COMMENTS_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
};

const getters = {
  comments: (state) => state.comments,
  commentsNotify: (state) => state.notify,
  commentsUnread: (state) => state.comments.filter(({ is_read }) => is_read === false ),
  commentsPagination: (state) => state.pagination
};

const actions = {
  async doGetComments({ commit }, { resource, ...payload }) {
    const isFirstPage = payload.page === 1;
    const  { data, pagination } = await this._vm.$api[resource].getComments(payload);
    if (isFirstPage) commit('APPEND_COMMENTS', data.comments);
    else commit('SET_COMMENTS_ATOMIC', { key: 'comments', val: data.comments });
    commit('SET_COMMENTS_ATOMIC', { key: 'pagination', val: pagination });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
