import Vue from 'vue';
import { cloneDeep } from '@/utils/helpers/methods';
import { HTTP } from '@/utils/http';

const defaultNumber = {
  _embedded: {
    program: {},
  },
};

const state = {
  number: cloneDeep(defaultNumber),
  editing: cloneDeep(defaultNumber),
  numbers: [],
};

const mutations = {
  ADD_REWARD_PROGRAM_NUMBER(state, payload) {
    state.numbers.unshift(payload);
  },
  RESET_REWARD_PROGRAM_NUMBERS(state, payload) {
    switch (payload) {
      case 'number':
        state.number = cloneDeep(defaultNumber);
        break;
      case 'editing':
        state.editing = cloneDeep(defaultNumber);
        break;
      default:
    }
  },
  REMOVE_REWARD_PROGRAM_NUMBER_AT(state, payload) {
    state.numbers.splice(payload.index, 1);
  },
  SET_REWARD_PROGRAM_NUMBER_AT(state, payload) {
    state.numbers.splice(payload.index, 1, payload.val);
  },
  SET_REWARD_PROGRAM_NUMBERS_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
};

const getters = {
  rewardProgramNumber: (state) => state.number,
  rewardProgramEditing: (state) => state.editing,
  rewardProgramNumbers: (state) => state.numbers,
};

const actions = {
  async doCreateRewardProgramNumber({ commit }, payload) {
    const { id, ...params } = payload;
    const { data } = await HTTP.post(`/clients/${id}/reward_program_numbers`, params);
    const [val] = data.data.reward_program_numbers;
    commit('ADD_REWARD_PROGRAM_NUMBER', val);
  },
  async doUpdateRewardProgramNumber({ commit, getters }, payload) {
    const { id, ...params } = payload;
    const { data } = await HTTP.patch(`/reward_program_numbers/${id}`, params);
    const [val] = data.data.reward_program_numbers;

    const index = getters.rewardProgramNumbers.findIndex((number) => number.id === val.id);
    if (index >= 0) {
      commit('SET_REWARD_PROGRAM_NUMBER_AT', { index, val });
    }
  },
  async doDeleteRewardProgramNumber({ commit, getters }, payload) {
    const { id, ...params } = payload;
    const { data } = await HTTP.delete(`/reward_program_numbers/${id}`, params);
    const [val] = data.data.reward_program_numbers;

    const index = getters.rewardProgramNumbers.findIndex((number) => number.id === val.id);
    if (index >= 0 && val) {
      commit('REMOVE_REWARD_PROGRAM_NUMBER_AT', { index });
    }
  },
  async doGetRewardProgramNumber({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.get(`/reward_program_numbers/${id}`, { params });
    if (!ignore) {
      const [val] = data.data.reward_program_numbers;
      commit('SET_REWARD_PROGRAM_NUMBERS_ATOMIC', {
        key: 'number',
        val,
      });
    }
    return data;
  },
  async doGetRewardProgramNumbers({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.get(`/clients/${id}/reward_program_numbers`, { params });
    if (!ignore) {
      commit('SET_REWARD_PROGRAM_NUMBERS_ATOMIC', {
        key: 'numbers',
        val: data.data.reward_program_numbers,
      });
    }
    return data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
