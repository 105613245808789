import { cloneDeep } from '@/utils/helpers/methods';
import Vue from 'vue';
import { HTTP } from '@/utils/http';

const defaultIntegration = {
  booking_category_ids: [],
  branches: [],
  client_field: 'client',
  credentials: {},
  fees: {
    non_fx_dom_bank_flat: 0,
    non_fx_dom_cc_percent: 0,
    non_fx_int_bank_flat: 0,
    non_fx_int_cc_percent: 0,
  },
  generate_number: false,
  reconcile_event: process.env.VUE_APP_ENV === 'production' ? 'delivered' : 'guaranteed',
  environment: process.env.VUE_APP_ENV === 'production' ? 'prod' : 'demo',
  iata_ids: [],
  is_active: true,
  member_numbers: [],
  portal_codes: [],
  scopes: ['bookings', 'clients'],
  shared_secret: null,
};

const state = {
  branch: {},
  checked: false,
  editing: cloneDeep(defaultIntegration),
  identity: {},
  integration: cloneDeep(defaultIntegration),
  integrations: [],
  memberNumber: {}, // possibly changing this to member as this is an object not a num
  refresh: false,
  services: [],
};

const mutations = {
  ADD_INTEGRATION_BACK_OFFICE_BRANCH(state, payload) {
    const { index, val } = payload;
    if (!state.editing.branches) state.editing.branches = [];
    else if (index) state.editing.branches.splice(index, 0, val);
    else state.editing.branches.push(val);
  },
  ADD_INTEGRATION_CONSORTIA_MEMBER_NUMBER(state, payload) {
    const { index, val } = payload;
    if (!state.editing.member_numbers) state.editing.member_numbers = [];
    else if (index) state.editing.member_numbers.splice(index, 0, val);
    else state.editing.member_numbers.push(val);
  },
  REMOVE_INTEGRATION_BACK_OFFICE_BRANCH(state, { index }) {
    state.editing.branches.splice(index, 1);
  },
  REMOVE_INTEGRATION_CONSORTIA_MEMBER_NUMBER(state, { index }) {
    state.editing.member_numbers.splice(index, 1);
  },
  RESET_INTEGRATIONS(state, payload) {
    switch (payload) {
      case 'editing':
        state.editing = cloneDeep(defaultIntegration);
        break;
      case 'integrations':
        state.integrations = [];
        break;
      default:
    }
  },
  SET_INTEGRATION_BACK_OFFICE_BRANCH(state, payload) {
    const { key, index, val } = payload;
    const nodes = key.split('.');
    let obj = state.editing.branches[index];
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, val);
      } else {
        obj = obj[node];
      }
    });
  },
  SET_INTEGRATION_CONSORTIA_MEMBER_NUMBER(state, payload) {
    const { key, index, val } = payload;
    const nodes = key.split('.');
    let obj = state.editing.member_numbers[index];
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, val);
      } else {
        obj = obj[node];
      }
    });
  },
  ADD_INTEGRATION_PAYMENT_GATEWAY_PORTAL_CODE(state, payload) {
    const { index, val } = payload;
    if (!state.editing.portal_codes) {
      state.editing.portal_codes = [];
    }
    if (index) {
      state.editing.portal_codes.splice(index, 0, val);
    } else {
      state.editing.portal_codes.push(val);
    }
  },
  SET_INTEGRATION_PAYMENT_GATEWAY_PORTAL_CODE(state, payload) {
    const { key, currencySymbol, val } = payload;
    const nodes = key.split('.');
    let obj = state.editing.portal_codes.find(({ currency_symbol }) => currency_symbol === currencySymbol);
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, val);
      } else {
        obj = obj[node];
      }
    });
  },
  SET_INTEGRATIONS_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
};

const getters = {
  backOfficeIntegrations({ integrations }) {
    const type = 'back_office';
    return integrations.filter(({ service }) => {
      const { destination } = service;
      if (!destination) return false;
      return destination.type === type;
    });
  },
  consortiaIntegrations({ integrations }) {
    const type = 'consortia';
    return integrations.filter(({ service }) => {
      const { destination } = service;
      if (!destination) return false;
      return destination.type === type;
    });
  },
  backOfficeServices({ services }) {
    const type = 'back_office';
    return services.filter(({ destination }) => destination.type === type);
  },
  consortiaServices({ services }) {
    const type = 'consortia';
    return services.filter(({ destination }) => destination.type === type);
  },
  crmServices({ services }) {
    const type = 'crm';
    return services.filter(({ destination }) => destination.type === type);
  },
  integration: ({ integration }) => integration, // used?
  integrationEditing: ({ editing }) => editing,
  integrationServices: ({ services }) => services,
  integrations: ({ integrations }) => integrations,
  integrationsRefresh: ({ refresh }) => refresh,
  integrationsChecked: ({ checked }) => checked,
  paymentGatewayIntegrations({ integrations }) {
    const type = 'payment_gateway';
    return integrations.filter(({ service }) => {
      const { destination } = service;
      if (destination) return destination.type === type;
      return false;
    });
  },
  paymentGatewayServices({ services }) {
    const type = 'payment_gateway';
    return services.filter(({ destination }) => destination.type === type);
  },
};

const actions = {
  async doCreateIntegration({ commit }, payload) {
    const { ignore, ...params } = payload;
    const { data } = await HTTP.post('/integrations', params);
    if (!ignore) {
      commit('SET_INTEGRATIONS_ATOMIC', { key: 'integration', val: data.data.integrations[0] });
    }
    return data;
  },
  async doCreateIntegrationBackOfficeBranch({ commit }, payload) {
    const { data } = await HTTP.post('/back_office_branches', payload);
    commit('SET_INTEGRATIONS_ATOMIC', { key: 'branch', val: data.data.back_office_branches[0] });
    return data;
  },
  async doCreateIntegrationConsortiaMemberNumber({ commit }, payload) {
    const { data } = await HTTP.post('/consortia_member_numbers', payload);
    commit('SET_INTEGRATIONS_ATOMIC', {
      key: 'branch',
      val: data.data.consortia_member_numbers[0],
    });
    return data;
  },
  async doCreateIntegrationIdentity({ commit }, payload) {
    const { data } = await HTTP.post('/integration_identities', payload);
    commit('SET_INTEGRATIONS_ATOMIC', {
      key: 'identity',
      val: data.data.integration_identities[0],
    });
    return data;
  },
  async doDeleteIntegration({ commit }, payload) {
    // used?
    const { id, ...params } = payload;
    const { data } = await HTTP.delete(`/integrations/${id}`, params);
    commit('SET_INTEGRATIONS_ATOMIC', { key: 'integration', val: data.data.integrations[0] });
    return data;
  },
  async doDeleteIntegrationBackOfficeBranch({ commit }, payload) {
    const { id, ...params } = payload;
    const { data } = await HTTP.delete(`/back_office_branches/${id}`, params);
    commit('SET_INTEGRATIONS_ATOMIC', { key: 'branch', val: data.data.back_office_branches[0] });
    return data;
  },
  async doDeleteIntegrationConsortiaMemberNumber({ commit }, payload) {
    const { id, ...params } = payload;
    const { data } = await HTTP.delete(`/consortia_member_numbers/${id}`, params);
    commit('SET_INTEGRATIONS_ATOMIC', {
      key: 'branch',
      val: data.data.consortia_member_numbers[0],
    });
    return data;
  },
  async doGetIntegrationServices({ commit }) {
    const { data } = await HTTP.get('/integration_services');
    commit('SET_INTEGRATIONS_ATOMIC', { key: 'services', val: data.data.integration_services });
    return data;
  },
  async doGetIntegration({ commit }, payload) {
    const { id, ...params } = payload;
    const { data } = await HTTP.get(`/integrations/${id}`, { params });
    commit('SET_INTEGRATIONS_ATOMIC', { key: 'integration', val: data.data.integrations[0] });
    return data;
  },
  async doGetIntegrations({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.get(`/companies/${id}/integrations`, { params });
    if (!ignore) {
      commit('SET_INTEGRATIONS_ATOMIC', { key: 'checked', val: true });
      commit('SET_INTEGRATIONS_ATOMIC', { key: 'integrations', val: data.data.integrations });
    }
    return data;
  },
  async doUpdateIntegration({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.patch(`/integrations/${id}`, params);
    if (!ignore) {
      commit('SET_INTEGRATIONS_ATOMIC', { key: 'integration', val: data.data.integrations[0] });
    }
    return data;
  },
  async doUpdateIntegrationBackOfficeBranch({ commit }, payload) {
    const { id, ...params } = payload;
    const { data } = await HTTP.patch(`/back_office_branches/${id}`, params);
    commit('SET_INTEGRATIONS_ATOMIC', { key: 'branch', val: data.data.back_office_branches[0] });
    return data;
  },
  async doUpdateIntegrationConsortiaMemberNumber({ commit }, payload) {
    const { id, ...params } = payload;
    const { data } = await HTTP.patch(`/consortia_member_numbers/${id}`, params);
    commit('SET_INTEGRATIONS_ATOMIC', {
      key: 'memberNumber',
      val: data.data.consortia_member_numbers[0],
    });
    return data;
  },
  async doUpdateIntegrationIdentity({ commit }, payload) {
    const { id, ...params } = payload;
    const { data } = await HTTP.patch(`/integration_identities/${id}`, params);
    commit('SET_INTEGRATIONS_ATOMIC', {
      key: 'identity',
      val: data.data.integration_identities[0],
    });
    return data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
