import Vue from 'vue';
import { cloneDeep } from '@/utils/helpers/methods';
import dayjs from 'dayjs';

const getDateByQuarter = (quarter, year) => {
  switch (quarter) {
    case 1:
      return {
        startDate: `${year}-01-01`,
        endDate: `${year}-03-31`,
      }
    case 2:
      return {
        startDate: `${year}-04-01`,
        endDate: `${year}-06-30`,
      }
    case 3:
      return {
        startDate: `${year}-07-01`,
        endDate: `${year}-09-30`,
      }
    case 4:
      return {
        startDate: `${year}-10-01`,
        endDate: `${year}-12-31`,
      }
  }
}

const defaultReportingDates = {
  field: 'created_at',
  startDate: null,
  endDate: null,
}

const defaultPreviousReportingDates = {
  field: 'created_at',
  startDate: null,
  endDate: null,
}

const defaultReportingCategories = [
  {
    label: 'All time',
    value: 'ALL'
  },
  {
    label: 'Yearly',
    value: 'YEARLY'
  },
  {
    label: 'Quarterly',
    value: 'QUARTERLY'
  }
]

const state = {
  current_period_dates: cloneDeep(defaultReportingDates),
  previous_period_dates: cloneDeep(defaultPreviousReportingDates),
  reporting_categories: cloneDeep(defaultReportingCategories),
  all_time_option: {},
  year_options: [],
  quarter_options: [],
  selected_reporting_option: {
    category: 'ALL',
    label: 'All time',
    startDate: '2023-01-01', // fallback value
    endDate: dayjs().format('YYYY-MM-DD'), // fallback value
  },
}

const mutations = {
  SET_PRODUCTION_REPORTING_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
}

const getters = {
  selectedReportingOption: (state) => state.selected_reporting_option,
  selectedReportingLabel: (state, getters) => getters.selectedReportingOption.label,
  currentReportingDates: (state) => state.current_period_dates,
  previousReportingDates: (state) => state.previous_period_dates,
  reportingCategories: (state) => state.reporting_categories,
  reportingDateOptions: (state) => {
    return [
      state.all_time_option,
      ...state.quarter_options,
      ...state.year_options,
    ]
  },
}

const actions = {
  doSetDateOptions({ commit }, payload) {
    const { startDate, endDate } = payload;
    const yearOptions = [];
    const quarterOptions = [];

    const allTimeOption = {
      category: 'ALL',
      label: 'All time',
      startDate: dayjs(startDate).format('YYYY-MM-DD'),
      endDate: dayjs(endDate).format('YYYY-MM-DD'),
    }

    const startYear = parseInt(dayjs(startDate).format('YYYY'));
    const endYear = parseInt(dayjs(endDate).format('YYYY'));
    const currentYear = parseInt(dayjs().format('YYYY'));
    const currentQuarter = parseInt(dayjs().quarter());

    // Add Last 4 Complete Quarters when the current year
    if (endYear === currentYear && currentQuarter !== 1) {
      const { endDate: completeQuartersEnd } = getDateByQuarter(currentQuarter - 1, currentYear);
      const { startDate: completeQuartersStart } = getDateByQuarter(dayjs(completeQuartersEnd).subtract(3, 'quarter').quarter(), dayjs(endDate).subtract(4, 'quarter').format('YYYY'));
      yearOptions.push({
        category: 'YEARLY',
        sub_category: 'LAST_FOUR_QUARTERS',
        label: 'Last 4 complete quarters',
        endDate: completeQuartersEnd,
        startDate: completeQuartersStart,
      });
    }

    for (let year = endYear; year >= startYear; year--) {
      // Add Year Option
      yearOptions.push({
        category: 'YEARLY',
        sub_category: 'CALENDAR_YEAR',
        label: year,
        year: year,
        isCurrentYear: year === currentYear,
      });

      // Add Year's Quarter Options
      const endQuarter = year === endYear ? dayjs(endDate).subtract(1, 'quarter').quarter() : 4;
      const startQuarter = year === startYear ? dayjs(startDate).quarter() : 1;
      for (let quarter = endQuarter; quarter >= startQuarter; quarter--) {
        quarterOptions.push({
          category: 'QUARTERLY',
          label: `Q${quarter} ${year}`,
          year: year,
          quarter: quarter,
        });
      }
    }

    commit('SET_PRODUCTION_REPORTING_ATOMIC', { key: 'all_time_option', val: allTimeOption });
    commit('SET_PRODUCTION_REPORTING_ATOMIC', { key: 'year_options', val: yearOptions });
    commit('SET_PRODUCTION_REPORTING_ATOMIC', { key: 'quarter_options', val: quarterOptions });
  },
  doSetReportingDatePeriods({ commit }, payload) {
    const { dateField, dates } = payload;
    let startDate = '';
    let endDate = '';
    let previousStartDate = '';
    let previousEndDate = '';
    if (dates.category === 'QUARTERLY') {
      const previousQuarter = dates.quarter > 1 ? dates.quarter - 1 : 4;
      const previousYear = dates.quarter > 1 ? dates.year : dates.year - 1;
      const { ...currentDates } = getDateByQuarter(dates.quarter, dates.year);
      const { ...previousDates } = getDateByQuarter(previousQuarter, previousYear);
      startDate = currentDates.startDate;
      endDate = currentDates.endDate;
      previousStartDate = previousDates.startDate;
      previousEndDate = previousDates.endDate;
    } else if (dates.category === 'YEARLY') {
      const previousYear = dates.year - 1;
      const dateFormat = 'YYYY-MM-DD'
      switch(dates.sub_category) {
        case 'CALENDAR_YEAR':
        default:
          if (dates.isCurrentYear) {
            const endQuarter = dayjs().subtract(1, 'quarter').quarter();
            const lastCompleteQuarter = getDateByQuarter(endQuarter, dates.year);
            startDate = `${dates.year}-01-01`;
            endDate = lastCompleteQuarter.endDate;
            previousStartDate = `${previousYear}-01-01`;
            previousEndDate = dayjs(endDate).subtract(1, 'year').format(dateFormat);
          } else {
            startDate = `${dates.year}-01-01`;
            endDate = `${dates.year}-12-31`;
            previousStartDate = `${previousYear}-01-01`;
            previousEndDate = `${previousYear}-12-31`;
          }
          break;
        case 'LAST_FOUR_QUARTERS':
          startDate = dates.startDate;
          endDate = dates.endDate;
          previousStartDate = dayjs(dates.startDate).subtract(1, 'year').format(dateFormat);
          previousEndDate = dayjs(dates.endDate).subtract(1, 'year').format(dateFormat);
          break;
      }
    } else if (dates.category === 'ALL') {
      const startDateYear = dayjs(dates.startDate).year();
      const startDateQuarter = dayjs(dates.startDate).quarter();
      const endDateYear = dayjs(dates.endDate).year();
      const endDateQuarter = dayjs(dates.endDate).quarter();
      startDate = getDateByQuarter(startDateQuarter, startDateYear).startDate;
      endDate = getDateByQuarter(endDateQuarter, endDateYear).endDate;
    }
    commit('SET_PRODUCTION_REPORTING_ATOMIC', {
      key: 'current_period_dates',
      val: { field: dateField, startDate, endDate },
    });
    commit('SET_PRODUCTION_REPORTING_ATOMIC', {
      key: 'previous_period_dates',
      val: { field: dateField, startDate: previousStartDate, endDate: previousEndDate },
    });
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
