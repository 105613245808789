import Vue from 'vue';
import { cloneDeep } from '@/utils/helpers/methods';

const defaultPreferredProgram = {
  id: null,
  name: null
}

const state = {
  program: cloneDeep(defaultPreferredProgram),
  programs: [],
  refresh: false
}

const mutations = {
  SET_PREFERRED_PROGRAMS_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  }
}

const getters = {
  preferredPrograms: (state) => state.programs
}

const actions = {
  async doGetPreferredPrograms({ commit }, payload) {
    const { data } = await this._vm.$api.preferredPrograms.getAll(payload);
    commit('SET_PREFERRED_PROGRAMS_ATOMIC', { key: 'programs', val: data.preferred_programs });
    return data;
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}