import { apiClient } from '../apiClient';

const DOMAIN = 'invites';

export default {
  create: (payload) => apiClient.post(`/${DOMAIN}`, payload),
  get: ({ id, ...params }) => apiClient.get(`/${DOMAIN}/${id}`, { params }),
  update: ({ id, ...payload }) => apiClient.patch(`/${DOMAIN}/${id}`, payload),

  getPublic:  ({ id, ...params }) => apiClient.get(`/${DOMAIN}_public/${id}`, { params }),
  getContractors: ({ id, ...params }) => apiClient.get(`/companies/${id}/contractor_${DOMAIN}`, { params }),
  getHostAgencies: ({ id, ...params }) => apiClient.get(`/companies/${id}/host_agency_${DOMAIN}`, { params }),
};
