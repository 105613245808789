/**
 * Check if value is a number
 * @param {String, Number,Array, Object} testValue primitve or object to test
 * @returns {Boolean} a boolean
 */
export const isNumber = (value) => typeof value === 'number';

/**
 * Remove Falsy (false, null, 0, "",''.``, undefined, and NaN) values from array - creates a shallow copy of original
 * @param {Array} testValue primitve or object to test
 * @returns {Array} an array
 */
export const compact = (targetArray) => targetArray.filter(Boolean);

/**
 * Add delay to action/method after user types
 * @param {Function} callback the function to be run
 * @param {Number} waitTime miliseconds to wait before running
 * @returns the function to run with timeout time
 */
export const debounce = (callback, waitTime) => {
  let timeoutId = null;
  return (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback(...args);
    }, waitTime);
  };
};

/**
 * Create an array of length equal to the range value with sequential values starting from 0 up to the range value
 * @param {Number} start the start number of the range
 * @param {Number} end the end number of the range
 * @param {Number} increment the number to increase each value by in the range
 * @returns {Array} an array with the values of the range created
 */
export const range = (start, end, increment = 1) => {
  const newArrayLength = Math.abs((end - start) / increment);
  return Array(newArrayLength)
    .fill()
    .map((x, i) => start + i);
};

/**
 * Check to see if a value is empty
 * @param {String, Number,Array, Object} testValue primitve or object to test
 * @returns a boolean
 */
export function isEmpty(testValue) {
  return (
    testValue == null || // From standard.js: Always use === - but obj == null is allowed to check null
    testValue === undefined || // undefined
    (typeof testValue === 'object' && Object.keys(testValue).length === 0) || // empty object
    (typeof testValue === 'string' && testValue.trim().length === 0) // empty string
  );
}

/**
 * Takes an array of objects and merges into one object
 * this functions does not do exactly the same as merge from lodash.
   In lodash's version when one object has a property named "a" with an array with an object and the other has the the "a"
   it will combine instead of replace. So if thihs is a requirement at any point we need to address it.
 * @param {Object[]} objectsArray an array with objects
 * @returns {Object}
 */
export const merge = (objectsArray) => {
  let mergedObject = {};
  objectsArray.forEach((objectToMerge) => {
    mergedObject = {
      ...mergedObject,
      ...objectToMerge,
    };
  });
  return mergedObject;
};

/**
 * Sums values in an array
 * @param {Array} targetArray array to iterate
 * @param {Function} iterationItemFunction function used in iteration
 * @returns sum of array items dependant on iterationItemFunction
 */
export const sumBy = (targetArray, iterationItemFunction) =>
  targetArray.reduce((accumulated, item) => accumulated + iterationItemFunction(item), 0);

/**
 * Sort an Array of objects by a property contained in the first level of properties all objects in that array
 * If you need to traverse through the object you pass it in the parse Function
 * @param {Array} targetArray array to iterate
 * @param {Function} parseFunction function used in iteration
 * @param {Boolean} desc determine if it will descending or no (ascending)
 * @returns {Array} a sorted array
 */
export const sortBy = (targetArray, parseFunction = (x) => x, desc = false) => {
  const sortOrder = desc ? -1 : 1;
  return targetArray.sort((a, b) => {
    // sort comparison function
    let result = 0;
    if (parseFunction(a) < parseFunction(b)) {
      result = -1;
    }
    if (parseFunction(a) > parseFunction(b)) {
      result = 1;
    }
    return result * sortOrder;
  });
};

/**
 * @param {Object,Array} obj array/object to clone
 * @returns {Object,Array} a deep cloned array/object
 */
export function cloneDeep(obj) {
  if (typeof obj !== 'object' || obj === null) {
    return obj; // Return the value if it is not an object
  }

  let clonedObject;

  // Handle cloning of specific types
  if (obj instanceof Date) {
    clonedObject = new Date(obj.getTime());
  } else if (obj instanceof Function) {
    clonedObject = function () {
      return obj.apply(this, arguments);
    };
  } else {
    clonedObject = Array.isArray(obj) ? [] : {};

    // Recursively clone nested objects
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        clonedObject[key] = cloneDeep(obj[key]);
      }
    }
  }

  return clonedObject;
}

/**
 * Check to see if a value is null or undefined
 * @param {String, Number,Array, Object} testValue primitve or object to test
 * @returns a boolean
 */
export function isNil(testValue) {
  return (
    testValue == null || // From standard.js: Always use === - but obj == null is allowed to check null
    testValue === undefined // undefined
  );
}

/**
 * Creates an object with the properties that have values that dont match the test
 * @param {Object} targetObject primitve or object to test
 * @param {Function} parseFunction a function to compare the value in the properties in the object
 * @returns {Object} an object
 */
export function omitBy(targetObject, testFunction) {
  targetObject = { ...targetObject };
  Object.entries(targetObject).forEach(
    ([key, value]) => testFunction(value) && delete targetObject[key]
  );
  return targetObject;
}

/**
 * Creates an object with the properties that have values that dont match the properties contained in the array passed
 * @param {Object} targetObject primitve or object to test
 * @param {Array} omitArray an array with properties to be ommited from object
 * @returns {Object} an object
 */
export function omit(targetObject, omitArray) {
  targetObject = { ...targetObject };
  omitArray.forEach((property) => delete targetObject[property]);
  return targetObject;
}

/**
 * Returns an object that matches the min value of the property passed in the parse function
 * @param {Array} targetArray array to sort
 * @param {Function} parseFunction a function to determine the value that will be compared
 * @returns {Object} an object
 */
export function minBy(targetArray, parseFunction) {
  const min = Math.min(...targetArray.map(parseFunction));
  return targetArray.find((item) => parseFunction(item) === min);
}

/**
 * Check to see if value passed is object
 * @param {Any} targetValue object to check
 * @returns {Boolean} a boolean
 */
export const isObject = (targetValue) => targetValue instanceof Object;

/**
 * Check to see if value  passed is Array
 * @param {Any} targetValue array to clone
 * @returns {Boolean} a boolean
 */
export const isArray = (targetValue) => Array.isArray(targetValue);

/**
 * Removed the duplicate values from an array that gives the same result in the iteratee
 * @param {Array} targetArray array to change
 * @param {Function, String} iteratee function or string value of property
 * @returns {Array} array
 */
export const uniqBy = (targetArray, iteratee) => {
  if (typeof iteratee === 'string') {
    const prop = iteratee;
    iteratee = (item) => item[prop];
  }

  return targetArray.filter(
    (x, index, self) => index === self.findIndex((y) => iteratee(x) === iteratee(y))
  );
};

/**
 * Creates a duplicate free copy of the array
 * @param {Array} targetArray array to change
 * @returns {Array} array
 */
export const uniq = (targetArray) => [...new Set(targetArray)];

/**
 * Creates an object composed of keys generated from the results of running each element of array thru iteratee
 * @param {Array} targetArray array to change
 * @param {Function} parseFunction function that retrieves the value we want to use
 * @returns {Object} objects with properties of the different options returned by the parseFunction
 */
export const groupBy = (targetArray, parseFunction) => {
  const newArr = []; // array to return, keeps track of order
  const wrapObj = {}; // temporary object used for grouping

  targetArray.forEach((item) => {
    // gets property name to group by and converts it to a string for grouping purposes
    let propName = parseFunction(item);
    if (propName) {
      propName = propName.toString();

      // checks if group exists already and creates a new group if not, pushing it into the array to return ('newArr')
      if (!wrapObj[propName]) {
        wrapObj[propName] = [];
        newArr.push(wrapObj[propName]);
      }

      // adds item to the group
      wrapObj[propName].push(item);
    }
  });

  return wrapObj;
};

/**
 * Creates an object composed of the object properties parseFunction returns truthy for
 * @param {Object} targetObject array to change
 * @param {Function} testFunction function or string value of property
 * @returns {Object} object with properties that passed the testFunction
 */
export const pickBy = (targetObject, testFunction) => {
  const filterObject = (obj, fn) => {
    const entries = Object.entries(obj).filter(fn);
    return Object.fromEntries(entries);
  };
  return filterObject(targetObject, ([key, val]) => testFunction(val, key));
};

/**
 * Check to see if teo arrays are equal
 * @param {Array} arrayOne array to compare to other
 * @param {Array} arrayTwo array to compare to other
 * @returns a boolean
 */
export function isEqualArray(arrayOne, arrayTwo) {
  // if the other array is a falsy value, return
  if (!arrayOne || !arrayTwo) return false;
  // compare lengths - can save a lot of time
  if (arrayOne.length !== arrayTwo.length) return false;
  // if the argument is the same array, we can be sure the contents are same as well
  if (arrayOne === arrayTwo) return true;

  for (let i = 0, l = arrayOne.length; i <= l; i += 1) {
    // Check if we have nested arrays
    if (arrayOne[i] instanceof Array && arrayTwo[i] instanceof Array) {
      // recurse into the nested arrays
      if (!arrayOne[i].equals(arrayTwo[i])) return false;
    } else if (arrayOne[i] !== arrayTwo[i]) {
      // Warning - two different object instances will never be equal: {x:20} != {x:20}
      return false;
    }
  }
  return true;
}

/**
 *
 * @param {*} str any incoming string
 * @returns a new string formatted in camelCase
 */
export function camelCase(str) {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

/**
 *
 * @param {*} str any incoming string
 * @returns a new string formatted as capitalized
 */
export function capitalizeString(str) {
  if (!str) return null;

  const arr = str.split(' ');
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(' ');
  return str2
};

/**
 *
 * @param {Array} targetArray array with objects
 * @param {String} targetProperty string of property contained in objects of targetArray
 * @returns an array with just the values of the property in each object in targetArray
 */
export function propertyArray(targetArray, targetProperty) {
  if(!targetArray) return;
  return targetArray.map((arrayItem) => {
    return arrayItem[targetProperty]
  })
}

export function calcPercentDiff(x, y, fixed = 0) {
  const percent = ((x - y) / y) * 100;
  if (!isNaN(percent)) {
    return Number(percent.toFixed(fixed));
  } else{
    return null;
  }
}
