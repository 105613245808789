import Vue from 'vue';
import { cloneDeep } from '@/utils/helpers/methods';
import { HTTP } from '@/utils/http';

const defaultInvoice = {
  amount_type: 'commission_balance',
  booking_count: 0,
  date: new Date(),
  _embedded: {
    sender: {},
    recipient: {},
  },
};

const state = {
  invoice: cloneDeep(defaultInvoice),
  editing: {},
  invoiceSchedule: {},
  invoices: [],
  refresh: false,
};

const mutations = {
  ADD_INVOICE_TO_INVOICES(state, payload) {
    state.invoices.push(payload);
  },
  REMOVE_INVOICE_FROM_INVOICES(state, payload) {
    const index = state.invoices.findIndex((invoice) => invoice.id === payload.id);
    if (index >= 0) {
      state.invoices.splice(index, 1);
    }
  },
  RESET_INVOICES(state, payload) {
    switch (payload) {
      case 'editing':
        state.editing = cloneDeep(defaultInvoice);
        break;
      case 'invoice':
        state.invoice = cloneDeep(defaultInvoice);
        break;
      default:
    }
  },
  SET_INVOICES_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
};

const getters = {
  invoice: (state) => state.invoice,
  invoiceDefault({ invoices }) {
    const filtered = invoices.filter(({ booking_count }) => booking_count === 1);
    if (!filtered.length) return null;
    return filtered[0];
  },
  invoiceEditing: (state) => state.editing,
  invoiceSchedule: (state) => state.invoiceSchedule, // used?
  invoices: (state) => state.invoices,
  invoicesAuto: ({ invoices }) => invoices.filter((invoice) => !invoice.is_manual),
  invoicesManual: ({ invoices }) => invoices.filter((invoice) => invoice.is_manual),
  invoicesRefresh: (state) => state.refresh,
};

const actions = {
  async doCreateInvoice({ commit }, payload) {
    const { ignore, ...params } = payload;
    const { data } = await HTTP.post('/invoices', params);
    if (!ignore) {
      const [val] = data.data.invoices;
      commit('SET_INVOICES_ATOMIC', { key: 'invoice', val });
    }
    return data;
  },
  async doUpdateInvoice({ commit }, payload) {
    const { id, ...params } = payload;
    const { data } = await HTTP.patch(`/invoices/${id}`, params);
    const [val] = data.data.invoices;
    commit('SET_INVOICES_ATOMIC', { key: 'invoice', val });
    return data;
  },
  async doCreateInvoiceSchedule({ commit }, payload) {
    const { ...params } = payload;
    const { data } = await HTTP.post('/invoice_schedules', params);
    const [val] = data.data.invoice_schedules;
    commit('SET_INVOICES_ATOMIC', { key: 'invoiceSchedule', val });
    return data;
  },
  async doUpdateInvoiceSchedule({ commit }, payload) {
    const { id, ...params } = payload;
    const { data } = await HTTP.put(`/invoice_schedules/${id}`, params);
    const [val] = data.data.invoice_schedules;
    commit('SET_INVOICES_ATOMIC', { key: 'invoiceSchedule', val });
    return data;
  },
  async doDeleteInvoice({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.delete(`/invoices/${id}`, params);
    if (!ignore) {
      const [val] = data.data.invoices;
      commit('SET_INVOICES_ATOMIC', { key: 'invoice', val });
    }
    return data;
  },
  async doGetInvoice({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.get(`/invoices/${id}`, { params });
    if (!ignore) {
      const [val] = data.data.invoices;
      commit('SET_INVOICES_ATOMIC', { key: 'invoice', val });
    }
    return data;
  },
  /**
   *
   * @param {*} resource is either bookings or trips
   */
  async doGetInvoices({ commit }, { resource, ...payload }) {
    const { data } = await this._vm.$api[resource].getInvoices(payload)
    commit('SET_INVOICES_ATOMIC', { key: 'invoices', val: data.invoices });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
